import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { usePostHog } from 'posthog-js/react'

import { SectionNewArrivalsBestsellersData } from 'data-access/sanity/fragments/sections/sectionNewArrivalsBestsellers.fragment'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@ui/components/ui/tabs'
import { cn } from '@ui/lib/utils'

import MisterContainer from 'src/components/shared/MisterContainer'
import MisterSiteLink, { makeHref } from 'src/components/shared/site-link/MisterSiteLink'
import { useTranslations } from 'src/contexts/Globals.context'
import ProductCard from 'src/features/product-card/ProductCard'
import { captureFeatureInteraction, captureFeatureView, useExperimentFeatureFlag } from 'src/hooks/posthog/useExperimentFeatureFlag'
import useInView from 'src/hooks/utils/useInView'

interface SectionNewArrivalsBestsellersProps {
  data: SectionNewArrivalsBestsellersData
}

type Product = SectionNewArrivalsBestsellersData['bestsellers'][number] | SectionNewArrivalsBestsellersData['newArrivals'][number]

const flag = 'homepage-newArrivals-bestsellers'

const ProductsList: FunctionComponent<{ products: Product[] }> = ({ products }) => {
  const postHog = usePostHog()

  const handleClick = () => {
    captureFeatureInteraction(flag, postHog)
  }

  return (
    <ul className='flex snap-x snap-mandatory gap-x-2 overflow-x-scroll px-6 scrollbar-hide lg:gap-x-3'>
      {products.map((product, i: number) => {
        return (
          <ProductCard
            liParams={{ onClick: handleClick }}
            index={i}
            key={`products-list-product-${product._id}`}
            product={product}
            quickAddToCart={<ProductCard.QuickAddToCart />}
            wishList={<ProductCard.WishListButton />}
            image={<ProductCard.HoverImage imageSizes='30rem' />}
            badge={<ProductCard.Badge />}
            info={<ProductCard.Info />}
            action={<ProductCard.Link />}
            className='snap-center snap-always max-lg:min-w-[18rem] lg:flex-1'
          />
        )
      })}
    </ul>
  )
}

const SectionNewArrivalsBestsellers: FunctionComponent<SectionNewArrivalsBestsellersProps> = ({ data }) => {
  const { locale } = useRouter()
  const translate = useTranslations()
  const { position } = data
  const postHog = usePostHog()

  const testVariant = useExperimentFeatureFlag(flag, undefined, false)
  const finalTestVariant = typeof testVariant === 'string' ? testVariant : 'control'
  const [hideByAbTest, setHideByAbTest] = useState(finalTestVariant === 'control')
  const componentRef = useRef<HTMLDivElement>(null)
  const { inView } = useInView(componentRef, 0)

  const [tabsConfig, setTabsConfig] = useState({
    defaultTab: 'bestsellers',
    activeTab: {
      name: 'bestsellers',
      url: makeHref(
        {
          _type: 'link',
          type: 'internal',
          page: { slug: { current: 'bestsellers' }, _type: 'collection' },
        },
        locale,
      ),
    },
    order: ['bestsellers', 'newArrivals'],
  })

  const getTabUrl = (tabName: string) => {
    const slugCurrent = tabName === 'bestsellers' ? 'bestsellers' : 'new'
    return makeHref(
      {
        _type: 'link',
        type: 'internal',
        page: { slug: { current: slugCurrent }, _type: 'collection' },
      },
      locale,
    )
  }

  const handleTabChange = (value: string) => {
    setTabsConfig((prev) => ({
      ...prev,
      activeTab: {
        name: value,
        url: getTabUrl(value),
      },
    }))
  }

  useEffect(() => {
    if (typeof finalTestVariant === 'string') {
      // Set visibility based on variant and position
      if (finalTestVariant === 'control') {
        setHideByAbTest(true)
      } else if (position === 'beforeShopByCollection') {
        setHideByAbTest(['variant-2'].includes(finalTestVariant))
      } else if (position === 'afterShopByCollection') {
        setHideByAbTest(['variant-1'].includes(finalTestVariant))
      } else {
        setHideByAbTest(false)
      }

      const defaultTab = 'bestsellers'

      setTabsConfig({
        defaultTab,
        activeTab: {
          name: defaultTab,
          url: getTabUrl(defaultTab),
        },
        order: ['bestsellers', 'newArrivals'],
      })
    }
  }, [finalTestVariant, position, locale])

  // Capture feature view
  useEffect(() => {
    if (inView) {
      captureFeatureView(flag, postHog)
    }
  }, [flag, postHog, inView])

  return (
    <div ref={componentRef} className='w-full'>
      {!hideByAbTest && (
        <MisterContainer type='fluid' dataLabel='section-slider' data-id={data._id} padding={false} className='mb:mt-20 mt-10'>
          <Tabs defaultValue={tabsConfig.defaultTab} value={tabsConfig.activeTab.name} onValueChange={handleTabChange} className='w-full'>
            <TabsList className={cn('w-full px-6')}>
              <div
                className={cn(
                  'relative flex w-full items-center',
                  'before:absolute before:bottom-0 before:left-1/2 before:h-0.5 before:w-full before:-translate-x-1/2 before:bg-brand-blue-light before:content-[""]',
                  'after:absolute after:bottom-0 after:left-1/2 after:h-0.5 after:w-0 after:-translate-x-1/2 after:bg-brand-blue after:duration-200 after:content-[""]',
                )}
              >
                {tabsConfig.order.map((tab) => (
                  <TabsTrigger key={tab} variant='alternative' className='w-fit' value={tab}>
                    {translate(`${tab}Title`)}
                  </TabsTrigger>
                ))}
                <MisterSiteLink href={tabsConfig.activeTab.url} className='ml-auto hidden w-fit text-body-xl-bold underline md:inline-flex'>
                  {translate('viewAll', 'View All')}
                </MisterSiteLink>
              </div>
            </TabsList>

            <TabsContent value='bestsellers' className='mt-6'>
              <ProductsList products={data?.bestsellers} />
            </TabsContent>

            <TabsContent value='newArrivals' className='mt-6'>
              <ProductsList products={data?.newArrivals} />
            </TabsContent>
          </Tabs>
          <div className='mt-10 flex w-full px-6 md:hidden'>
            <MisterSiteLink href={tabsConfig.activeTab.url} className='ml-auto w-fit text-body-md-bold underline'>
              {translate('viewAll', 'View All')}
            </MisterSiteLink>
          </div>
        </MisterContainer>
      )}
    </div>
  )
}

export default SectionNewArrivalsBestsellers
