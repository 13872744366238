import { DEFAULT_LOCALE, localesWithFallbackForCoalesceQuery } from 'data-access/domain/constants'

import { TrustpilotBusinessReview } from '@/types/businessReview'

import { get } from './request.util'

// @see https://developers.trustpilot.com/business-units-api#search-for-business-units
export enum TrustpilotBusinessUnitIds {
  EN = '5cffcdb27ae89e00012bc89c',
  NL = '5f56546411ac3b0001929e7a',
  FR = '5f50a6bd254c5300017b496b',
  DE = '5f57719a82f33e0001cf1ea2',
  CH = '5f57719a82f33e0001cf1ea2',
  ES = '5f5771c66de9c90001859276',
  GB = '5f50a6b0254c5300017b4969',
  DK = '5f577211c15b2700017c48f9',
  IT = '647f1dd24e2be10eb55e966f',
}

export const trustpilotStorePageUrlMap: Record<string, string> = {
  en: 'https://www.trustpilot.com/review/www.mrmarvis.com',
  nl: 'https://nl.trustpilot.com/review/mrmarvis.nl',
  es: 'https://es.trustpilot.com/review/mrmarvis.es',
  fr: 'https://fr.trustpilot.com/review/mrmarvis.fr',
  de: 'https://de.trustpilot.com/review/mrmarvis.de',
  ch: 'https://ch.trustpilot.com/review/mrmarvis.de',
  gb: 'https://uk.trustpilot.com/review/mrmarvis.co.uk',
  dk: 'https://dk.trustpilot.com/review/mrmarvis.dk',
  it: 'https://it.trustpilot.com/review/mrmarvis.com/it',
}

export const trustpilotUrlMap: Record<string, string> = {
  en: 'https://www.trustpilot.com',
  nl: 'https://nl.trustpilot.com',
  es: 'https://es.trustpilot.com',
  fr: 'https://fr.trustpilot.com',
  de: 'https://de.trustpilot.com',
  ch: 'https://ch.trustpilot.com',
  gb: 'https://uk.trustpilot.com',
  dk: 'https://dk.trustpilot.com',
  it: 'https://it.trustpilot.com',
}

export const localeToReviewLanguagesMap: Record<string, string[]> = {
  en: ['en'],
  nl: ['nl'],
  fr: ['fr'],
  de: ['de'],
  ch: ['ch', 'de'],
}

export const getReviewsInLanguages = (locale = DEFAULT_LOCALE, fallbackLocale = 'en') => {
  return localeToReviewLanguagesMap?.[locale] || localesWithFallbackForCoalesceQuery?.[locale] || [fallbackLocale]
}

export const getTrustpilotUrl = (locale = DEFAULT_LOCALE) => {
  return trustpilotStorePageUrlMap?.[locale.substring(0, 2)] || trustpilotStorePageUrlMap.en
}

export const getTrustpilotReviewUrl = (reviewId: string, locale = DEFAULT_LOCALE): Readonly<string> => {
  return `${trustpilotUrlMap?.[locale.substring(0, 2)] || trustpilotUrlMap.en}/reviews/${reviewId}`
}

export const getTrustpilotBusinessUnitId = (locale = DEFAULT_LOCALE): Readonly<string> => {
  return TrustpilotBusinessUnitIds?.[locale.toUpperCase() as keyof typeof TrustpilotBusinessUnitIds] || TrustpilotBusinessUnitIds.EN
}

export const getBusinessReviews = async (locale = DEFAULT_LOCALE, page = 1, perPage = 30, maxCountPostFilter = 20): Promise<TrustpilotBusinessReview[]> => {
  const positiveReviewStars = [4, 5]
  const businessUnitId = getTrustpilotBusinessUnitId(locale)
  let result: TrustpilotBusinessReview[] = []

  // Fetch from Trustpilot
  // @see https://documentation-apidocumentation.trustpilot.com/business-units-api#get-a-business-unit's-reviews
  try {
    const response = await get<{ reviews: TrustpilotBusinessReview[] }>(`https://api.trustpilot.com/v1/business-units/${businessUnitId}/reviews?page=${page}&perPage=${perPage}`, {
      apikey: process.env.TRUSTPILOT_API_KEY || '',
    })

    result = (response?.reviews || [])
      // Filter out unverified reviews and long reviews (they won't look nice on the homepage)
      .filter((review) => review.isVerified && review.text.length < 150)
      .slice(0, maxCountPostFilter)

    // Return the first 5 positive reviews
    const positiveReviews = result.filter((review) => positiveReviewStars.includes(review.stars)).slice(0, 5)

    return [
      ...positiveReviews,
      // The rest
      ...(result.filter((review) => !positiveReviews.includes(review)) || []),
    ]
  } catch (err) {
    console.error(err)
  }

  return []
}
