export const updateRecentSearches = ({
  searchTerm,
  action,
  recentlySearchedTerms,
  index,
  setRecentlySearchedTerms,
}: {
  searchTerm: string
  setRecentlySearchedTerms: (terms: string[]) => void
  action: string
  recentlySearchedTerms: string[]
  index?: number
}) => {
  const strippedSearchTerm = searchTerm.trim()
  switch (action) {
    case 'search':
      if (!recentlySearchedTerms.length) {
        setRecentlySearchedTerms([strippedSearchTerm])
        return // Exit the function after initializing the array
      }

      // Check if the search term already exists in the array
      if (recentlySearchedTerms.includes(strippedSearchTerm)) {
        return // Exit the function if the term already exists
      }

      const termsToPush = [...recentlySearchedTerms] // Make a copy of the array to avoid mutating state directly

      // If the length is already 5, remove the oldest term (at the end)
      if (termsToPush.length >= 5) {
        termsToPush.pop()
      }

      // Add the new search term to the front of the array
      termsToPush.unshift(strippedSearchTerm)

      // Update the state with the new array
      setRecentlySearchedTerms(termsToPush)
      break

    case 'remove':
      if (index !== undefined && index >= 0 && index < recentlySearchedTerms.length) {
        const termsToRemove = [...recentlySearchedTerms]
        termsToRemove.splice(index, 1)
        setRecentlySearchedTerms(termsToRemove)
      }
      break

    case 'update':
      if (index !== undefined && index >= 0 && index < recentlySearchedTerms.length) {
        const termsToUpdate = [...recentlySearchedTerms]
        termsToUpdate[index] = strippedSearchTerm
        setRecentlySearchedTerms(termsToUpdate)
      }
      break
    case 'clear':
      setRecentlySearchedTerms([])

      break

    default:
      break
  }
}
