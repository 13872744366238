import { FunctionComponent } from 'react'
import { useHits, useTrendingItems } from 'react-instantsearch'
import { useCookie } from 'react-use'

import { AlgoliaProductHit } from 'data-access/algolia/types'
import { cn } from '@ui/lib/utils'

import { useUI } from 'src/contexts/UI.context'
import useCart from 'src/hooks/shop/useCart'
import MisterSearchProductCard from '../productCard/MisterSearchProductCard'

type Props = {
  title: string
  className?: string
}

const MisterSearchTrendingItems: FunctionComponent<Props> = ({ title, className }) => {
  const { currencyCode } = useCart()
  const { hideSearchUI } = useUI()
  const { sendEvent } = useHits<AlgoliaProductHit>()
  const [algoliaLastQueryId] = useCookie('algoliaLastQueryId')
  const { items: trendingItems } = useTrendingItems<AlgoliaProductHit>({
    limit: 8,
    queryParameters: {
      filters: `isAvailable:true`,
    },
  })

  if (!trendingItems || trendingItems.length === 0) {
    return null
  }

  return (
    <div className={cn('', className)}>
      <h3 className='mb-6 text-body-xl-bold'>{title}</h3>

      <ul className='grid w-full grid-cols-2 gap-2.5 lg:grid-cols-4 lg:gap-3'>
        {trendingItems.map((item) => (
          <MisterSearchProductCard
            onClick={() => {
              hideSearchUI()
            }}
            onAddToCart={() => {
              sendEvent('conversion', item, 'Trending Add to Cart', {
                eventSubtype: 'addToCart',
                currency: currencyCode,
                objectData: [
                  {
                    quantity: 1,
                    price: item?.price || 0,
                    ...(algoliaLastQueryId && { queryID: algoliaLastQueryId }),
                  },
                ],
              })
            }}
            key={`trending-result-${item.objectID}`}
            hit={item}
          />
        ))}
      </ul>
    </div>
  )
}

export default MisterSearchTrendingItems
