import { getMainMenuAndMenuCards, GetMinimalNavigationResponse, SecondaryMenuItem } from 'data-access'
import { DEFAULT_LOCALE, SupportedLocale } from 'data-access/domain/constants'
import { Link } from 'data-access/sanity/fragments/components/link.fragment'
import { MainMenuItem } from 'data-access/sanity/fragments/components/mainMenuItem.fragment'
import { MenuCard } from 'data-access/sanity/fragments/components/menuCard.fragment'
import { Slug } from 'data-access/sanity/types'

export interface Navigation {
  mainMenuItems: (MainMenuItem | Link)[]
  topMenuCards: MenuCard[]
  wishlistSlug: {
    slug: Slug
  }
  secondaryMenuItems: (SecondaryMenuItem | Link | (SecondaryMenuItem & Link))[]
}

interface NavigationApiResponse {
  mainMenuItems: MainMenuItem[]
  topMenuCards: MenuCard[]
}

/**
 * Fetches the full navigation data, including the main menu items and top menu cards.
 * If draft mode is enabled, it fetches the data via an API route to make sure the serverside Sanity Token can be used.
 * @param initialData - The minimal navigation data.
 * @param locale - The locale to fetch the navigation data for.
 * @param draftMode - Whether draft mode is enabled.
 * @returns The full navigation data.
 */
export const getFullNavigation = async (initialData: GetMinimalNavigationResponse, locale: SupportedLocale = DEFAULT_LOCALE, draftMode = false): Promise<Navigation> => {
  try {
    let mainMenuItems: MainMenuItem[]
    let topMenuCards: MenuCard[]

    if (draftMode) {
      const response = await fetch(`/api/sanity/navigation?locale=${locale}`)

      if (!response.ok) {
        throw new Error(`Navigation API request failed: ${response.statusText}`)
      }

      const data = (await response.json()) as NavigationApiResponse
      mainMenuItems = data.mainMenuItems
      topMenuCards = data.topMenuCards
    } else {
      const result = await getMainMenuAndMenuCards(locale, draftMode)
      mainMenuItems = result.mainMenuItems
      topMenuCards = result.topMenuCards
    }

    const mainMenuItemsWithFullData = {
      ...initialData,
      mainMenuItems: initialData.mainMenuItems?.map((item) => (item._type === 'link' ? item : mainMenuItems.find((fullItem) => item._key === fullItem._key))).filter(Boolean) as (
        | MainMenuItem
        | Link
      )[],
      topMenuCards,
    }

    return mainMenuItemsWithFullData
  } catch (error) {
    console.error('Failed to load navigation data:', error)

    throw error
  }
}
