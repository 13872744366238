import { FunctionComponent } from 'react'

import { cn } from 'ui/lib/utils'
import { Button } from '@ui/components/ui/button'

import MisterIcon from '../MisterIcon'

export type MisterChevronButtonProps = {
  orientation: 'left' | 'right'
  onClick?: (e?: any) => void | Promise<void> | null
  className?: string
}

/**
 * Redesigned component
 */
const MisterChevronButtonV2: FunctionComponent<MisterChevronButtonProps> = ({ orientation, onClick, className }) => {
  return (
    <Button
      type='button'
      aria-label={`chevron-${orientation}`}
      size='icon'
      variant='secondary'
      hover='scaleEffect'
      onClick={onClick}
      className={cn('group flex bg-opacity-50 transition-all duration-200 ease-out hover:bg-opacity-100', className)}
    >
      <MisterIcon type={`chevron-${orientation}`} className='size-6' />
    </Button>
  )
}

export default MisterChevronButtonV2
