import { LucideProps } from 'lucide-react'
import dynamicIconImports from 'lucide-react/dynamicIconImports'
import dynamic from 'next/dynamic'

interface IconProps extends LucideProps {
  name: string
}

const MisterLucideIcon = ({ name, ...props }: IconProps) => {
  const LucideIcon = dynamic(dynamicIconImports[name.toLowerCase() as keyof typeof dynamicIconImports], { ssr: true })

  return <LucideIcon {...props} />
}

export default MisterLucideIcon
