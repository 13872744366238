import { FunctionComponent, useState } from 'react'
import { useRouter } from 'next/router'

import { DeliveryTime, SectionShippingInformationData, ShipmentArea, ShippingCosts } from 'data-access/sanity/fragments/sections/sectionShippingInformation.fragment'
import { cn } from '@ui/lib/utils'
import { formatPrice } from 'utilities/string/format/price'

import MisterInputSearchBar from 'src/components/shared/input/MisterInputSearchBar'
import RichText from 'src/components/shared/rich-text/rich-text'
import { useTranslations } from 'src/contexts/Globals.context'

interface SectionShippingInformationProps {
  data: SectionShippingInformationData
}

const SectionShippingInformation: FunctionComponent<SectionShippingInformationProps> = ({ data }) => {
  const { locale } = useRouter()
  const translate = useTranslations()
  const [searchInput, setSearchInput] = useState<string>('')
  const sortedShipments = data.shipmentAreas?.sort((a, b) => {
    const aHasCountry = a.countries.some((country) => country.countryCode?.toLowerCase() === locale)
    const bHasCountry = b.countries.some((country) => country.countryCode?.toLowerCase() === locale)

    if (aHasCountry && !bHasCountry) {
      return -1
    }
    if (!aHasCountry && bHasCountry) {
      return 1
    }
    return 0
  })
  const [filteredShipmentAreas, setFilteredShipmentAreas] = useState<ShipmentArea[] | undefined>(sortedShipments)
  const allOtherCountries = sortedShipments?.slice(-1)[0]

  const handleSearchInputChange = (input: string) => {
    setSearchInput(() => {
      setFilteredShipmentAreas(
        sortedShipments?.filter((shipmentArea) =>
          shipmentArea.countries.some((country) => country.name.toLowerCase().includes(input) || country.countryCode?.toLowerCase().includes(input)),
        ),
      )
      return input
    })
  }

  const tableRow = (tds: string[]) => {
    return (
      <tr className='border-b border-brand-grey transition-all hover:bg-brand-beige-light'>
        {tds.map((td, i) => (
          <td key={i} className={cn('m-20 mr-4 gap-4 p-4 last:mr-0', i !== 0 && 'w-40')}>
            {td}
          </td>
        ))}
      </tr>
    )
  }

  const shippingCosts = (costs: ShippingCosts): string => {
    switch (costs?.type) {
      case 'free':
        return translate('freeShipping', 'Free shipping')
      case 'freeFrom':
        return `${costs?.otherwisePrice && formatPrice(costs?.otherwisePrice, locale) + ','}  ${costs?.freeFromPrice && `${translate('freeShippingOver', 'Free shipping over').replace('{amount}', formatPrice(costs?.freeFromPrice, locale))}`}`
      case 'fixed':
        return formatPrice(costs?.price || 0, locale) + ' ' + translate('shippingCosts', 'shippingCosts')
      case 'custom':
        return costs?.description || ''
      default:
        return ''
    }
  }

  const deliveryTime = (deliveryTime: DeliveryTime) => {
    switch (deliveryTime.type) {
      case 'orderedBy':
        return translate('orderedBefore', `Ordered by {time} delivered tomorrow`).replace('{time}', deliveryTime.orderedBy || '')
      case 'businessDays':
        return deliveryTime.businessDays + ' ' + translate('businessDays', 'business days')
      default:
        return ''
    }
  }

  return (
    <div>
      <MisterInputSearchBar
        className='mb-10 md:mb-7'
        placeholderText={translate('searchYourCountry', 'Search your country')}
        onChangeHandler={(e) => handleSearchInputChange(e.target.value.toLowerCase().trim())}
        onClear={() => handleSearchInputChange('')}
        value={searchInput}
      />
      <table className='w-full table-auto'>
        <thead className='pointer-events-none whitespace-nowrap text-body-sm-bold md:text-body-md-bold'>
          {tableRow([translate('country', 'Country'), translate('deliveryTime', 'Delivery time'), translate('shippingCosts', 'Shipping costs')])}
        </thead>
        <tbody className='text-body-sm md:text-body-md'>
          {filteredShipmentAreas?.length === 0 &&
            allOtherCountries &&
            tableRow([
              allOtherCountries?.countries.map((country) => country.name).join(', '),
              deliveryTime(allOtherCountries?.deliveryTime),
              shippingCosts(allOtherCountries?.shippingCosts),
            ])}
          {filteredShipmentAreas?.map((shipmentArea) =>
            tableRow([shipmentArea.countries.map((country) => country.name).join(', '), deliveryTime(shipmentArea.deliveryTime), shippingCosts(shipmentArea.shippingCosts)]),
          )}
        </tbody>
      </table>
      {data.extraInformationHeader && <h3 className='mb-2 mt-10 py-2 text-heading-5 font-bold'>{data.extraInformationHeader}</h3>}
      {data.extraInformation && <RichText data={data.extraInformation} />}
    </div>
  )
}

export default SectionShippingInformation
