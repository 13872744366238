import { FunctionComponent } from 'react'
import { PortableText, PortableTextComponents } from '@portabletext/react'

import { RichText as RichTextType } from 'data-access/sanity/fragments/components/richText.fragment'
import { cn } from '@ui/lib/utils'
import { convertSlug } from 'utilities/string/url'

import MisterSiteLink from '../site-link/MisterSiteLink'

const setHref = (value: { tel?: string; mail?: string; href?: string }) => (value.tel ? `tel:${value.tel}` : value.mail ? `mailto:${value.mail}` : value.href ? value.href : null)

export interface RichTextProps {
  data: RichTextType
  className?: string
}

const RichText: FunctionComponent<RichTextProps> = ({ data, className = '' }) => {
  if (!data) {
    return null
  }

  const components: PortableTextComponents = {
    block: {
      h1: ({ children }) => <h1 className='text-heading-3 lg:text-heading-1'>{children}</h1>,
      h2: ({ children }) => <h2 className='text-heading-4 lg:text-heading-2'>{children}</h2>,
      'normal-large': ({ children }) => <p className='text-body-lg'>{children}</p>,
    },
    marks: {
      internalLink: ({ value, children }) => <MisterSiteLink link={convertSlug(value.slug, value.type)}>{children}</MisterSiteLink>,
      link: ({ value, children }) => {
        const href = setHref(value)

        if (!href) {
          console.error('Error in link')
          return children
        }

        return (
          <a href={href} target={value.blank ? '_blank' : '_self'} rel='noreferrer'>
            {children}
          </a>
        )
      },
      unknownMark: ({ children }) => <span data-label='unknownMark'>{children}</span>,
    },
  }

  return (
    <div className={cn('prose max-w-none text-body-blog-md text-brand-blue empty:hidden', className)}>
      <PortableText value={data} components={components} />
    </div>
  )
}

export default RichText
