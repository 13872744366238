import { FC, useEffect, useState } from 'react'
import { useCurrentRefinements } from 'react-instantsearch'

import { AlgoliaProductHit } from 'data-access/algolia/types'

import { mapAlgoliaHitToProductCard } from 'src/domain/algoliaProductCard.domain'
import ProductCard from 'src/features/product-card/ProductCard'

type MisterSearchProductCardProps = {
  hit: AlgoliaProductHit
  className?: string
  onClick?: () => void
  onAddToCart?: () => void
}

const MisterSearchProductCard: FC<MisterSearchProductCardProps> = ({ hit, className, onClick, onAddToCart }) => {
  const product = mapAlgoliaHitToProductCard(hit)
  const { items } = useCurrentRefinements()
  const [hasSizeRefinements, setHasSizeRefinements] = useState(false)

  /**
   * When the items change, e.g. when a search filter is applied, we check if any size filter is applied
   * This is used to determine whether to fetch live product data or not
   * We only need to fetch live product data if any size filter is applied
   * In other scenario's we can use the product data from Algolia as it is near-realtime data
   */
  useEffect(() => {
    const hasSizeFilterApplied = items.some((item) => item.attribute === 'waistSizes' || item.attribute === 'lengthSizes' || item.attribute === 'sizes')
    setHasSizeRefinements(hasSizeFilterApplied)
  }, [items])

  /**
   * If for any reason the product is not available, we don't render the product card
   */
  if (!product || !product?.slug?.current) {
    return null
  }

  return (
    <ProductCard
      index={hit.__position}
      product={product}
      quickAddToCart={<ProductCard.QuickAddToCart onAddToCart={onAddToCart} />}
      wishList={<ProductCard.WishListButton />}
      image={<ProductCard.HoverImage imageSizes='(max-width: 768px) 50vw, (max-width: 1200px) 33vw, 25vw' />}
      badge={<ProductCard.Badge />}
      info={<ProductCard.Info />}
      action={<ProductCard.Link onClick={onClick} />}
      className={className}
      variantSku={hasSizeRefinements ? hit.sku : undefined}
      liParams={{
        objectID: hit.objectID,
        position: hit.__position,
        queryID: hit.__queryID,
      }}
    />
  )
}

export default MisterSearchProductCard
