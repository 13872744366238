import { FunctionComponent, useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoaderCircle } from 'lucide-react'
import { useRouter } from 'next/router'
import { usePostHog } from 'posthog-js/react'
import { useUpdateEffect } from 'react-use'
import { z } from 'zod'

import { SectionLoginData } from 'data-access/sanity/fragments/sections/sectionLogin.fragment'
import { Button } from '@ui/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormMessage, useForm } from '@ui/components/ui/form'
import { InputFloatingLabel } from '@ui/components/ui/input-floating-label'
import { at } from 'utilities/array/arrayAt'

import { ElevarUserPropertiesLoginAndRegistration } from '@/types/telemetry'

import MisterAuthPageContainer from 'src/components/shared/account/MisterAuthPageContainer'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import useLoginCustomer from 'src/hooks/customer/useLoginCustomer'
import { ELEVAR_DATA_LAYER_EVENTS, trackEvent } from '../../../utils/telemetry.util'

interface SectionLoginProps {
  data: SectionLoginData
}

const SectionLogin: FunctionComponent<SectionLoginProps> = ({ data }) => {
  const {
    title,
    subtitle,
    backgroundImage,
    emailLabel,
    emailErrorMessage,
    passwordLabel,
    passwordErrorMessage,
    loginErrorMessage,
    submitButtonText,
    forgottenPasswordLink,
    createAccountTitle,
    createAccountSubtitle,
    createAccountLink,
  } = data || {}

  const posthog = usePostHog()
  const { query, locale } = useRouter()
  const { loginCustomer, state: loginState, setState: setLoginState } = useLoginCustomer(true)

  const loginFormSchema = z.object({
    email: z.string().email({ message: emailErrorMessage }),
    password: z.string().min(4, { message: passwordErrorMessage }),
  })

  const form = useForm<z.infer<typeof loginFormSchema>>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = async ({ email, password }: z.infer<typeof loginFormSchema>) => {
    if ('checkout_url' in query) {
      await loginCustomer(email, password, false, Array.isArray(query?.checkout_url) ? query?.checkout_url?.[0] : query?.checkout_url)
    } else {
      await loginCustomer(email, password)
    }
  }

  useUpdateEffect(() => {
    if (!loginState.loading && loginState.response && !loginState.error) {
      const payload: ElevarUserPropertiesLoginAndRegistration = {
        user_properties: {
          visitor_type: 'logged_in',
          customer_email: loginState.response?.email,
          customer_first_name: loginState.response?.firstName || '',
          customer_last_name: loginState.response?.lastName || '',
          // Customer ID should match the one in generic user data layer
          // @see apps/store/src/contexts/Telemetry.context.tsx
          customer_id: String(at((loginState.response?.id || '').split('/'), -1) || ''),
        },
      }

      // Elevar
      trackEvent(ELEVAR_DATA_LAYER_EVENTS.DL_LOGIN, payload)

      // PostHog
      // @see https://posthog.com/docs/libraries/js#identifying-users
      if (process.env.NEXT_PUBLIC_POSTHOG_KEY && loginState.response?.email) {
        posthog?.identify(loginState.response?.email, {
          email: loginState.response?.email || '',
          firstName: loginState.response?.firstName || '',
          lastName: loginState.response?.lastName || '',
          websiteLocale: locale,
        })
      }
    }
  }, [loginState])

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      // Clear login error state when user starts typing
      if (loginState.error && (name === 'email' || name === 'password')) {
        setLoginState((prev) => ({ ...prev, error: null }))
      }
    })

    return () => subscription.unsubscribe()
  }, [loginState.error])

  return (
    <MisterAuthPageContainer image={backgroundImage}>
      <>
        <div className='flex flex-col gap-9'>
          <div className='flex flex-col gap-2'>
            <h2 className='text-heading-3'>{title}</h2>
            {subtitle && <p className='text-body-md'>{subtitle}</p>}
          </div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='flex flex-col gap-y-3'>
              <FormField
                control={form.control}
                name='email'
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <InputFloatingLabel data-testid='login-email' label={emailLabel} type='email' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='password'
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <InputFloatingLabel data-testid='login-password' label={passwordLabel} type='password' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <MisterSiteLink className='text-body-sm underline' link={forgottenPasswordLink}>
                {forgottenPasswordLink?.linkText}
              </MisterSiteLink>

              <Button type='submit' variant='primary' className='mt-7'>
                {loginState.loading ? <LoaderCircle className='size-4 animate-spin' /> : submitButtonText}
              </Button>
              {loginState.error && <FormMessage>{loginErrorMessage}</FormMessage>}
            </form>
          </Form>
        </div>
        <div className='flex flex-col gap-7'>
          <div className='flex flex-col gap-2'>
            {createAccountTitle && <h3 className='text-heading-5'>{createAccountTitle}</h3>}
            {createAccountSubtitle && <p className='text-body-md'>{createAccountSubtitle}</p>}
          </div>
          {createAccountLink && (
            <MisterSiteLink className='text-body-sm' link={createAccountLink}>
              <Button variant='outline' className='w-full'>
                {createAccountLink.linkText}
              </Button>
            </MisterSiteLink>
          )}
        </div>
      </>
    </MisterAuthPageContainer>
  )
}

export default SectionLogin
