import React from 'react'

import MisterSearchBar from 'src/components/shared/search/MisterSearchBar'
import AlgoliaContextProvider from 'src/contexts/AlgoliaSearch.context'

type Props = {
  data: {
    _type: string
  }
}

const SectionSearchResults = (props: Props) => {
  return (
    <AlgoliaContextProvider>
      <MisterSearchBar />
    </AlgoliaContextProvider>
  )
}

export default SectionSearchResults
