import { FunctionComponent, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { ProductCardProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'
import { cn } from 'ui/lib/utils'

import { useUI } from 'src/contexts/UI.context'
import ProductCard from 'src/features/product-card/ProductCard'
import MisterSkeletonLoader from '../loader/MisterSkeletonLoader'
import MisterIcon from '../MisterIcon'

interface Props {
  title: string
  products: ProductCardProduct[]
  isLoading?: boolean
}

const MisterRecommendationsRow: FunctionComponent<Props> = ({ title, products, isLoading = false }) => {
  const { closeCart } = useUI()
  const [showScrollIndicator, setShowScrollIndicator] = useState(true)
  const { asPath } = useRouter()

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollIndicator(false)
    }

    window.addEventListener('scroll', handleScroll, true)
    return () => {
      window.removeEventListener('scroll', handleScroll, true)
    }
  }, [])

  return (
    <div className='relative flex flex-col gap-4 px-4 py-2 lg:py-6'>
      <div className='flex items-center justify-between'>
        <h2 className='text-left text-body-xl-bold'>{title}</h2>
        {/* Scroll Indicator */}
        <div className={cn('pointer-events-none transform transition-opacity duration-300 max-lg:hidden', showScrollIndicator ? 'opacity-100' : 'opacity-0')}>
          <MisterIcon type='chevron-down' className='size-6 animate-bounce text-brand-blue-action' />
        </div>
      </div>
      <ul aria-busy={isLoading} aria-live='polite' className={cn('flex gap-2 overflow-x-auto pb-4 scrollbar-hide max-lg:snap-x max-lg:snap-mandatory')}>
        {!isLoading &&
          products.map((product, i) => {
            const alreadyOnThisProductPage = asPath?.split('/')[2] === product?.slug.current

            return (
              <ProductCard
                index={i}
                key={`recommendation-product-${product._id}`}
                product={{ ...product, _id: `${product._id}-cart-recommendations` }}
                quickAddToCart={<ProductCard.QuickAddToCartDrawer showWithinCart />}
                image={<ProductCard.HoverImage imageSizes='(max-width: 640px) 90vw, (max-width: 1024px) 45vw, 33vw' />}
                badge={<ProductCard.Badge />}
                info={<ProductCard.Info />}
                action={alreadyOnThisProductPage ? <ProductCard.Button onClick={closeCart} /> : <ProductCard.Link onClick={closeCart} />}
                className='w-36 min-w-0 flex-none snap-center sm:w-40'
              />
            )
          })}
        {isLoading &&
          [...Array(4)].map((_, i) => (
            <li key={`loader-${i}`} className='w-36 min-w-0 flex-none snap-center sm:w-40'>
              <MisterSkeletonLoader element='div' className='h-[20rem] w-full' />
            </li>
          ))}
      </ul>
    </div>
  )
}

export default MisterRecommendationsRow
