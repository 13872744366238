import { FC, useCallback } from 'react'
import { useClearRefinements } from 'react-instantsearch'

import { cn } from '@ui/lib/utils'

import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import { useTranslations } from '../../../../contexts/Globals.context'

type Props = {
  className?: string
}

const SearchClearRefinements: FC<Props> = ({ className }) => {
  const translate = useTranslations()
  const { refine, canRefine } = useClearRefinements()

  const handleRefine = useCallback(() => {
    trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_CLEAR_ALL_FILTERS)
    refine()
  }, [refine])

  // Display nothing if we don't have any filters to clear
  if (!canRefine) {
    return null
  }

  return (
    <label tabIndex={0} title={translate('clearFilters', 'Clear filters')} onClick={handleRefine} className={cn(className, 'flex cursor-pointer flex-row gap-4 max-lg:hidden')}>
      <p className='underline'>{translate('clearFilters', 'Clear filters')}</p>
    </label>
  )
}

export default SearchClearRefinements
