const RMA_PORTAL_DOMAINS: { rmaPortalHostname: string; orderNamePrefix: string }[] = [
  { rmaPortalHostname: 'https://exchanges.mrmarvis.com', orderNamePrefix: '10016' },
  { rmaPortalHostname: 'https://exchanges.mrmarvis.de', orderNamePrefix: '10017' },
  { rmaPortalHostname: 'https://exchanges.mrmarvis.co.uk', orderNamePrefix: '10018' },
  { rmaPortalHostname: 'https://exchanges.mrmarvis.fr', orderNamePrefix: '10019' },
  { rmaPortalHostname: 'https://exchanges.mrmarvis.nl', orderNamePrefix: '10015' },
  { rmaPortalHostname: 'https://exchanges.mrmarvis.dk', orderNamePrefix: '10011' },
  { rmaPortalHostname: 'https://exchanges.mrmarvis.es', orderNamePrefix: '10012' },
  { rmaPortalHostname: 'https://exchanges.mrmarvis.it', orderNamePrefix: '10014' },
  { rmaPortalHostname: 'https://exchanges-ch.mrmarvis.com', orderNamePrefix: '10021' },
]

export const rmaPortalUrl = (shopifyOrderName: string, postcodeOrEmail: string) => {
  const orderNumberNoHash = shopifyOrderName.replace('#', '')
  const rmaPortalHostname = RMA_PORTAL_DOMAINS?.filter((param) => orderNumberNoHash.startsWith(param.orderNamePrefix))?.[0]?.rmaPortalHostname || 'https://exchanges.mrmarvis.com'
  const postcodeOrEmailNoSpaces = postcodeOrEmail.replace(' ', '%20').toUpperCase()

  return `${rmaPortalHostname}?order=${orderNumberNoHash}&secret=${postcodeOrEmailNoSpaces}`
}
