import { ElementType, FunctionComponent, PropsWithChildren } from 'react'

import { cn } from 'ui/lib/utils'

interface Props {
  className?: string
  element?: ElementType
  loading?: boolean
}

const MisterSkeletonLoader: FunctionComponent<PropsWithChildren<Props>> = ({ className = undefined, children, element = 'div', loading = true }) => {
  const Type = element

  if (!loading) {
    return null
  }

  return (
    <Type className={cn('flex animate-mister-pulse rounded-sm bg-black/20', className)} aria-live='polite' aria-atomic='true' aria-busy={loading ? 'true' : 'false'}>
      {children}
    </Type>
  )
}

export default MisterSkeletonLoader
