import { useQuery } from '@tanstack/react-query'

import { getProductsAvailability } from 'data-access'
import { SupportedLocale } from 'data-access/domain/constants'
import { BaseProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'

export type ProductWithEnrichedAvailability<T = object> = Awaited<ReturnType<typeof enrichProductWithLiveAvailability>> & T

export const enrichProductWithLiveAvailability = <T extends BaseProduct>(product: T, locale: SupportedLocale, variantSku?: string) => {
  const isGiftCard = product?.slug?.current?.includes('gift')

  const { data } = useQuery({
    queryKey: ['productsAvailability', locale, product?.shopifyProductId],
    staleTime: 5 * 60 * 1000, // 5 minutes
    queryFn: () => getProductsAvailability(locale, product?.shopifyProductId),
    enabled: !!locale && !!product?.shopifyProductId,
    select: (data) => (data || []).filter(Boolean)?.[0],
  })

  const mappedProductAvailability =
    (data?.variants.edges
      .flatMap(({ node }) => {
        return {
          sku: node.sku,
          availableForSale: node.availableForSale,
          quantityAvailable: node.quantityAvailable,
        }
      })
      .filter(Boolean)
      .map((variant) => {
        const matchingVariant = product.variants.find((productVariant) => productVariant.sku === variant.sku)
        if (!matchingVariant) return undefined
        return {
          ...matchingVariant,
          inventory: {
            quantity: variant.quantityAvailable ?? matchingVariant?.inventory?.quantity ?? 0,
          },
          isAvailable: isGiftCard ? true : (variant?.availableForSale ?? matchingVariant?.isAvailable),
        }
      })
      .filter(Boolean) as NonNullable<BaseProduct['variants']> | undefined) || product.variants

  const productIsAvailable = isGiftCard
    ? true
    : mappedProductAvailability
      ? // The MisterSearchProductCard component passes a variantSku to the product card to be used to check the stock status
        variantSku
        ? mappedProductAvailability.some(({ sku, inventory }) => sku === variantSku && inventory?.quantity > 0)
        : // Otherwise, we check if any variant is in stock
          mappedProductAvailability.some(({ inventory }) => inventory?.quantity > 0)
      : product.isAvailable

  return {
    ...product,
    isAvailable: productIsAvailable,
    variants: mappedProductAvailability,
  }
}
