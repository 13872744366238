import { FunctionComponent, useContext } from 'react'
import { useRouter } from 'next/router'

import { formatPrice } from 'utilities/string/format/price'

import { GlobalsContext } from 'src/contexts/Globals.context'

const Price: FunctionComponent<{ price: number }> = ({ price }) => {
  const { locale } = useRouter()
  const {
    localizedSettings: { showCurrencySymbol },
  } = useContext(GlobalsContext)

  return <>{formatPrice(price, locale, showCurrencySymbol)}</>
}

export default Price
