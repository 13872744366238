import { FunctionComponent } from 'react'
import { PlusIcon } from 'lucide-react'
import { useHits } from 'react-instantsearch'

import { AlgoliaCollectionHit } from 'data-access/algolia/types'
import { Button } from '@ui/components/ui/button'

import { useAlgoliaSearch } from 'src/contexts/AlgoliaSearch.context'
import { useTranslations } from 'src/contexts/Globals.context'
import { useUI } from 'src/contexts/UI.context'
import { MisterCollectionResult } from './MisterCollectionResult'
import { ResultListTitle } from './ResultListTitle'

type Props = {
  items: AlgoliaCollectionHit[]
}

export const CollectionResultsList: FunctionComponent<Props> = ({ items }) => {
  const { limitCollectionsBy, setLimitCollectionsBy } = useAlgoliaSearch()
  const { sendEvent } = useHits<AlgoliaCollectionHit>()
  const translate = useTranslations()
  const collectionCount = items.length
  const { hideSearchUI } = useUI()

  if (collectionCount === 0) {
    return null
  }

  const hasMoreCollections = collectionCount > limitCollectionsBy

  const handleShowMore = () => {
    setLimitCollectionsBy(limitCollectionsBy * 2)
  }

  return (
    <div className='flex flex-col gap-y-6'>
      <ResultListTitle title={translate('searchCollectionResultsTitle', 'Collections')} count={collectionCount} />
      <ol className={'grid w-full grid-cols-2 gap-4 lg:grid-cols-4'}>
        {items.slice(0, limitCollectionsBy).map((item) => (
          <MisterCollectionResult
            onClick={() => {
              sendEvent('click', item, 'Search Collection Clicked')
              hideSearchUI()
            }}
            item={item}
            key={`collection-result=${item.slug}`}
          />
        ))}
      </ol>
      {hasMoreCollections && (
        <div className='col-span-2 mx-auto w-fit'>
          <Button hover='expandIcon' Icon={PlusIcon} variant='outline' onClick={handleShowMore}>
            {translate('showMore', 'Show more')}
          </Button>
        </div>
      )}
    </div>
  )
}
