import { FunctionComponent, useContext } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/lib/utils'
import { sort } from 'utilities/array/sortArray'

import MisterSwiper from 'src/components/shared/MisterSwiper'
import { GlobalsContext } from 'src/contexts/Globals.context'
import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import MisterMedia from '../../shared/media/MisterMedia'
import MisterIcon from '../../shared/MisterIcon'
import { OverviewCardProps, SectionProps } from './section-overview-cards'

const OverviewPill: FunctionComponent<OverviewCardProps & { className?: string }> = ({ slug, title, bulletImage, className }) => {
  return (
    <Button variant='pill' className={cn('', className)} asChild>
      <Link onClick={() => trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_CATEGORY_PILL, { clickTitle: title, clickDestination: slug })} href={slug} title={title}>
        <div className='flex size-12 items-center justify-center rounded-full'>
          <MisterMedia className='rounded-full' media={bulletImage} />
        </div>
        <span>{title}</span>
      </Link>
    </Button>
  )
}

const OverviewPills: FunctionComponent<SectionProps> = ({ data }) => {
  const {
    query: { collection },
  } = useRouter()
  const displayCollection = data.collections[0]
  const { stylesOrder } = useContext(GlobalsContext)

  const currentPathMatchesSlug = (slug: string) => collection === slug

  if (!displayCollection) {
    return null
  }

  const pills = [
    // Back button pill
    displayCollection && displayCollection.slug && displayCollection.title && (
      <Link
        key='back-button'
        onClick={() => trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_CATEGORY_PILL, { clickTitle: displayCollection?.title, clickDestination: displayCollection?.slug })}
        href={displayCollection.slug}
        title={displayCollection.title}
      >
        <Button variant='pill'>
          <div className='flex size-12 items-center justify-center rounded-full bg-primary'>
            <MisterIcon type='chevron-left' className='w-5 text-white' />
          </div>
          <span>{displayCollection.title}</span>
        </Button>
      </Link>
    ),
    // Collection pills
    ...(stylesOrder.applyOrderTo?.pcpCollectionCards ? sort(displayCollection?.data, stylesOrder.styles || []) : displayCollection?.data).map((col, index) => (
      <OverviewPill
        key={`overview-pill-${index}`}
        {...col}
        className={cn(currentPathMatchesSlug(col.slug) && 'bg-primary text-primary-foreground hover:border-brand-beige-light hover:bg-primary')}
      />
    )),
  ]

  return (
    <MisterSwiper slideClassName='!w-fit animate-nudge' containerClassName={cn('!px-4 lg:!px-8')} showNavigation={false}>
      {pills}
    </MisterSwiper>
  )
}

export default OverviewPills
