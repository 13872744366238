import { FunctionComponent } from 'react'
import { TriangleAlert } from 'lucide-react'

import { SectionInvalidTokenData } from 'data-access/sanity/fragments/sections/sectionInvalidToken.fragment'
import { Button } from '@ui/components/ui/button'

import MisterAuthPageContainer from 'src/components/shared/account/MisterAuthPageContainer'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'

interface SectionInvalidTokenProps {
  data: SectionInvalidTokenData
}

const SectionInvalidToken: FunctionComponent<SectionInvalidTokenProps> = ({ data }) => {
  const { title, subtitle, backgroundImage, backLink } = data
  return (
    <MisterAuthPageContainer image={backgroundImage}>
      <div className='flex w-full flex-col gap-9'>
        <div className='flex flex-col gap-2'>
          <TriangleAlert className='mb-4 size-12' />
          <h2 className='text-pretty text-heading-3'>{title}</h2>
          {subtitle && <p className='text-body-md'>{subtitle}</p>}
        </div>
        <MisterSiteLink link={backLink}>
          <Button variant='outline' className='w-full'>
            {backLink.linkText}
          </Button>
        </MisterSiteLink>
      </div>
    </MisterAuthPageContainer>
  )
}

export default SectionInvalidToken
