import { FunctionComponent, type JSX } from 'react'

import { cn } from '@ui/lib/utils'

import { useGlobalTopMargin } from '../globalState/MisterScrollMargin'

interface Props {
  leftHalf: JSX.Element
  rightHalf: JSX.Element
  leftHalfClassName?: string
  rightHalfClassName?: string
  distribution?: string
}

const MisterHalfAndHalf: FunctionComponent<Props> = ({ leftHalf, rightHalf, leftHalfClassName, rightHalfClassName, distribution = '6' }) => {
  const [{ topPosition }] = useGlobalTopMargin()
  const scrollTopPositionMargin = 20

  return (
    <div className='grid grid-cols-1 lg:grid-cols-12'>
      <div
        style={{ top: `${topPosition + scrollTopPositionMargin}px` }}
        className={cn(`col-span-1 transition-spacing duration-300 lg:col-span-${distribution} self-start lg:sticky lg:px-5 xl:px-16 2xl:col-span-6`, leftHalfClassName)}
      >
        {leftHalf}
      </div>
      <div
        style={{ top: `${topPosition + scrollTopPositionMargin}px` }}
        className={cn(
          `col-span-1 transition-spacing duration-300 lg:col-span-${12 - Number(distribution)} self-start lg:sticky lg:px-5 xl:px-16 2xl:col-span-6`,
          rightHalfClassName,
        )}
      >
        {rightHalf}
      </div>
    </div>
  )
}

export default MisterHalfAndHalf
