import { FunctionComponent } from 'react'
import { useRouter } from 'next/router'

import { chunk } from 'utilities/array/chunkArray'

import { useTelemetry } from '../../contexts/Telemetry.context'
import { useReadyStateEffect } from '../../hooks/utils/useReadyStateEffect'
import { ecommerceInfoArticleList } from '../../utils/elevar.util'
import { set } from '../../utils/localStorage.util'
import { ELEVAR_DATA_LAYER_EVENTS, TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from '../../utils/telemetry.util'

interface Props {
  products: any[]
  currencyCode?: string
  category?: string
  type?: string
  season?: string
}

const MisterCollectionPageTelemetry: FunctionComponent<Props> = ({ products, currencyCode, category, type, season }) => {
  const { asPath, isReady } = useRouter()
  const { userProperties } = useTelemetry()
  //const [customUserId] = useCookie(ELEVAR_USER_ID_COOKIE)
  //const [algoliaLastIndexName] = useCookie('algoliaLastIndexName')
  //const authenticatedUserToken = useAuthenticatedUserToken()

  // Ensure this event comes after the generic dl_user_data
  // by means of the useReadyStateEffect hook (fires after page
  // load is done + additional delay)
  useReadyStateEffect(() => {
    if (!isReady) {
      return
    }

    set('elevar-list', asPath)

    setTimeout(() => {
      trackEvent(TELEMETRY_DATA_LAYER_EVENTS.VIEW_ITEM_LIST, {
        user_properties: userProperties,
        ecommerce: ecommerceInfoArticleList(0, [products], asPath, currencyCode, type, season),
      })

      // Elevar requires us to send batches of the items; otherwise
      // the HTTP requests become too big and that will result in the
      // browser discarding the request entirely due to the HTTP request
      // character limitations.
      const chunks = chunk(products, 8)

      chunks.map((chunk, i) => {
        trackEvent(ELEVAR_DATA_LAYER_EVENTS.DL_VIEW_ITEM_LIST, {
          user_properties: userProperties,
          ecommerce: ecommerceInfoArticleList(i, chunks, asPath, currencyCode, type, season),
        })
      })

      // Algolia
      // todo: can be enabled if default insights: true breaks, or rework to use chuncks too (TBD)?
      // if (algoliaLastIndexName) {
      //   algoliaInsightsClient('viewedObjectIDs', {
      //     index: algoliaLastIndexName,
      //     eventName: 'Viewed category',
      //     objectIDs: products.slice(0, 20).flatMap((product) => (product?.variants?.[0] ? [`product-${product.slug.current}-${product?.variants?.[0].sku}`] : [])),
      //     userToken: customUserId ?? undefined,
      //     authenticatedUserToken,
      //   })
      // }
    }, 1000)
  }, [asPath, isReady])

  return null
}

export default MisterCollectionPageTelemetry
