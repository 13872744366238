import { FunctionComponent, ReactNode, useState } from 'react'
import { ChevronDown } from 'lucide-react'
import { useRouter } from 'next/router'
import { useCookie } from 'react-use'

import { Button } from '@ui/components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@ui/components/ui/dropdown-menu'

import { useTranslations } from 'src/contexts/Globals.context'
import { I18N_COUNTRY_CODE_PREFERENCE } from 'src/middleware'
import { defaultCookieConfig } from 'src/utils/cookie.util'
import { countiesAndRegions } from 'src/utils/i18n.util'

export const Flag: FunctionComponent<{ code?: string }> = ({ code }): ReactNode => {
  const correctedCode = code === 'en' ? 'eu' : code
  const country = correctedCode ? countiesAndRegions.find((c) => c.isoAlpha2 === correctedCode.toUpperCase()) : false

  return (
    <span className='flex w-[15px] items-center justify-center empty:hidden' role='img' aria-label={correctedCode} aria-hidden={true}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      {country && <img src={`data:image/png;base64,${country?.flag}`} alt={correctedCode || ''} width={30} height={20} />}
    </span>
  )
}

export const CountryPicker: FunctionComponent = () => {
  const [open, setOpen] = useState(false)

  const translate = useTranslations()
  const [value, updateCookie] = useCookie(I18N_COUNTRY_CODE_PREFERENCE)
  const [, setCPCookie] = useCookie('usedCountryPicker')
  const { locale, locales, reload } = useRouter()

  const localesForDropdown = (locales || []).filter(
    (country) =>
      // Do not add current locale to dropdown
      country !== locale &&
      // Only add values to dropdown with a label/translation
      translate(`${country}CountryPicker`),
  )

  const handleClick = async (country: string) => {
    if (value !== country) {
      setCPCookie('true', defaultCookieConfig)
      updateCookie(country, defaultCookieConfig)
    }
    reload()
    setOpen(false)
  }

  return (
    <div className='group flex items-center px-3' onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <DropdownMenu open={open} onOpenChange={setOpen} modal={false}>
        <DropdownMenuTrigger asChild className='group focus-within:ring-transparent'>
          <Button variant='link' hover='underline' className='flex items-center gap-2 text-body-md text-current duration-300 data-[state=open]:bg-[length:100%_0.05rem]'>
            <Flag code={locale} />
            {translate(`${locale}CountryPicker`)}
            <ChevronDown className='size-4 transition-transform duration-300 group-data-[state=open]:rotate-180' />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent align='end' sideOffset={0} className='mt-2.5 flex min-w-40 flex-col items-start border-0 px-0 py-3'>
          {localesForDropdown.map((locale, i) => (
            <DropdownMenuItem key={`dropdown${i}`} className='w-full rounded-none px-3 hover:bg-[unset] focus:bg-[unset]'>
              <Button onClick={() => handleClick(locale)} variant='link' hover='underline' className='justify-start gap-2 text-body-md'>
                <Flag code={locale} />
                {translate(`${locale}CountryPicker`)}
              </Button>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
