import { ElementType, FunctionComponent } from 'react'

import { Article } from 'data-access/sanity/fragments/components/relatedArticles.fragments'
import { Button } from '@ui/components/ui/button'

import { useTranslations } from '../../../../contexts/Globals.context'
import MisterMedia from '../../../shared/media/MisterMedia'
import RichText from '../../../shared/rich-text/rich-text'
import MisterSiteLink from '../../../shared/site-link/MisterSiteLink'

interface MisterArticleCardProps {
  article: Article
  index: number
  titleAs?: ElementType
}

const MisterArticleCard: FunctionComponent<MisterArticleCardProps> = ({ article, index, titleAs = 'h2' }) => {
  const translate = useTranslations()
  const TitleTag = titleAs
  const teaserText = article?.sections?.find((section) => section?._type === 'sectionText')

  return (
    <li className='col-span-1 row-span-3 grid w-full grid-rows-subgrid gap-6' itemScope itemType='https://schema.org/BlogPosting'>
      {article?.dateCreated && <meta itemProp='datePublished' content={article.dateCreated} />}
      <MisterSiteLink link={{ type: 'internal', page: { _type: article._type, slug: article?.slug } }}>
        <MisterMedia
          sectionIndex={index}
          media={article?.articleThumbnailImage}
          mobileSizes='(max-width: 640px) 95vw, (max-width: 768px) 50vw, (max-width: 1024px) 25vw, 30rem'
          desktopSizes='(max-width: 640px) 95vw, (max-width: 768px) 50vw, (max-width: 1024px) 25vw, 30rem'
          itemProp='image'
          className='w-full rounded-md'
        />
      </MisterSiteLink>

      <div className='flex flex-col gap-4'>
        <TitleTag className='text-body-xl-bold' itemProp='headline'>
          {article?.title}
        </TitleTag>

        {teaserText?.text && <RichText className='line-clamp-3' data={teaserText?.text} />}
      </div>

      <Button asChild variant='outline' className='w-fit'>
        <MisterSiteLink
          link={{
            page: {
              _type: article._type,
              slug: article?.slug,
            },
          }}
        >
          {translate('readMore')}
        </MisterSiteLink>
      </Button>
    </li>
  )
}

export default MisterArticleCard
