import { FunctionComponent, useState } from 'react'
import { useMedia, useToggle } from 'react-use'

import { SectionTaggedImageData } from 'data-access/sanity/fragments/sections/sectionTaggedImage.fragment'
import { Button } from '@ui/components/ui/button'
import { Separator } from '@ui/components/ui/separator'
import { cn } from '@ui/lib/utils'

import CollapseContainer from 'src/components/shared/CollapseContainer'
import MisterImage from 'src/components/shared/image/MisterImage'
import { useTranslations } from 'src/contexts/Globals.context'
import { useUI } from 'src/contexts/UI.context'
import MiniProductCard from 'src/features/shop-the-look/MiniProductCard'
import ProductSpots from 'src/features/shop-the-look/ProductSpots'

interface SectionTaggedImageProps {
  data: SectionTaggedImageData
}

const calculateClusterCenter = (productSpots: { x: number; y: number }[]) => {
  const { x, y } = productSpots.reduce((sum, point) => ({ x: sum.x + point.x, y: sum.y + point.y }), { x: 0, y: 0 })
  const centerX = Math.floor(x / productSpots.length) / 100
  const centerY = Math.floor(y / productSpots.length) / 100

  return { x: centerX, y: centerY }
}

const SectionTaggedImage: FunctionComponent<SectionTaggedImageProps> = ({ data }) => {
  const translate = useTranslations()
  const { openShopTheLook } = useUI()
  const isMobile = useMedia('(max-width: 384px)', false)
  const [currentCard, setCurrentCard] = useState<number | null>(null)
  const [popupOpen, togglePopupOpen] = useToggle(false)

  const { productSpots, image, mobileImage } = data || {}
  if (!productSpots) return null
  const noCurrentCard = currentCard === null
  const availableProductSpots = productSpots?.filter((spot) => spot?.product?.isAvailable)

  return (
    <div className={cn('group relative grid size-full overflow-hidden @container-[inline-size]', mobileImage ? 'md:min-h-[40.5rem]' : 'min-h-[40.5rem]')}>
      {/* This mobile image is only used from the sectionTaggedImage type and isn't normally present */}
      {mobileImage && (
        <MisterImage
          alt='mobile image'
          mobile={mobileImage}
          mobileSizes='(max-width: 768px) 100vw, 33vw'
          className='col-end-1 row-end-1 size-full object-cover md:hidden'
          quality='100'
        />
      )}
      <MisterImage
        mobile={{
          ...image,
          hotspot: {
            ...calculateClusterCenter(productSpots),
            height: 0,
          },
        }}
        mobileSizes={`(max-width: 480px) ${Math.floor((image.width / 480) * 50)}vw, (max-width: 768px)  ${Math.floor((image.width / 768) * 50)}vw, (max-width: 1281px)  ${Math.floor((image.width / 1281) * 50)}vw, 1920px`}
        className={cn('col-end-1 row-end-1 size-full object-cover', mobileImage && 'hidden md:block')}
      />

      {(data._type === 'taggedImage' || availableProductSpots.length > 1) && (
        <ProductSpots
          productSpots={availableProductSpots}
          isLink={data._type === 'taggedImage'}
          className={cn('transition-opacity', popupOpen && 'pointer-events-none opacity-0')}
        />
      )}

      {productSpots.length > 1 && (
        <>
          <div onClick={() => (togglePopupOpen(false), setTimeout(() => setCurrentCard(null), 150))} className='absolute inset-0' />
          <div className='absolute bottom-0 right-0 flex flex-col gap-5 p-4 md:px-10 md:py-7'>
            {!isMobile && (
              <div
                className={cn(
                  'pointer-events-none relative z-10 hidden max-h-[32.5rem] w-full overflow-scroll rounded-md bg-white p-4 opacity-0 transition-[height,opacity] duration-300 scrollbar-hide @sm:block',
                  !popupOpen ? 'pointer-events-none @sm:opacity-0' : '@sm:pointer-events-auto @sm:opacity-100',
                )}
              >
                <div className='flex flex-col'>
                  {productSpots.map(({ product }, i) => {
                    const isCurrentCard = currentCard === i
                    return (
                      <CollapseContainer key={i} collapse={!noCurrentCard && !isCurrentCard} className='min-w-80'>
                        <div className={cn('grid transition-opacity duration-500', noCurrentCard || isCurrentCard ? '' : 'opacity-0')}>
                          <MiniProductCard key={i} id={i} product={product} setCurrentCard={setCurrentCard} currentCard={currentCard} />
                          {i < productSpots.length - 1 && (
                            <CollapseContainer collapse={!noCurrentCard}>
                              <Separator className='my-4' />
                            </CollapseContainer>
                          )}
                        </div>
                      </CollapseContainer>
                    )
                  })}
                </div>
              </div>
            )}
            {data._type !== 'taggedImage' && availableProductSpots.length > 1 && (
              <div className='ml-auto'>
                <Button
                  data-label='floating'
                  className='hidden grid-rows-1 overflow-hidden transition-all duration-100 @sm:grid'
                  variant={popupOpen ? 'primary' : 'secondary'}
                  onClick={() => (togglePopupOpen(), setTimeout(() => setCurrentCard(null), 150))}
                >
                  <div
                    className={cn(
                      'grid-row-1 row-span-1 row-start-1 grid transition-[grid-template-columns,opacity] duration-100',
                      popupOpen ? 'grid-cols-[1fr]' : 'grid-cols-[0fr] opacity-0',
                    )}
                  >
                    <div className='min-w-0'>{translate('close')}</div>
                  </div>
                  <div
                    className={cn(
                      'grid-row-1 row-span-1 row-start-1 grid transition-[grid-template-columns,opacity] duration-100',
                      !popupOpen ? 'grid-cols-[1fr]' : 'grid-cols-[0fr] opacity-0',
                    )}
                  >
                    <div className='min-w-0'>{translate('shopTheLook')}</div>
                  </div>
                </Button>
                <Button data-label='sheet' className='@sm:hidden' variant='secondary' onClick={() => openShopTheLook(productSpots.map(({ product }) => product))}>
                  {translate('shopTheLook')}
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default SectionTaggedImage
