/**
 * Anonymize an author name by replacing the first letter of each word with the anonymization character.
 *
 * @param name - The author name to anonymize.
 * @param anonymizationCharacter - The character to use for anonymization.
 * @param maxAnonymizationCharacterLength - The maximum length of the anonymized character.
 * @returns The anonymized author name.
 */
export const anonymize = (name: string, anonymizationCharacter = '.', maxAnonymizationCharacterLength = 1): string => {
  const trimmedName = name.trim()
  if (!trimmedName) return ''

  const [firstName, ...rest] = trimmedName.split(/\s+/)

  return `${firstName} ${rest
    ?.map(
      (word) =>
        `${Array.from(word.trim())
          ?.map((letter, i) => (i === 0 ? letter : anonymizationCharacter))
          ?.join('')
          ?.slice(0, maxAnonymizationCharacterLength + 1)}`,
    )
    ?.join(' ')}`?.trim()
}
