import React, { FunctionComponent, useEffect } from 'react'
import { useRouter } from 'next/router'

import { CookieCustomiser } from 'data-access/sanity/fragments/globals/cookieBanner.fragment'
import { Button } from '@ui/components/ui/button'

import { useCookieConsentContext } from '../../hooks/context/useCookieConsentContext'
import MisterToggleWithDescription from '../shared/input/MisterToggleWithDescription'
import MisterSlideOverPanel from '../shared/panel/MisterSlideOverPanel'
import RichText from '../shared/rich-text/rich-text'

const MisterCookieCustomiser: FunctionComponent<CookieCustomiser> = ({ cookieTypes, customiseCopy, customiserTitle, closeCustomiserLabel }) => {
  const { handleCookieConsentChange, customiseCookies, endCustomise, consent } = useCookieConsentContext()
  const { asPath } = useRouter()

  useEffect(() => {
    customiseCookies && endCustomise()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath])

  return (
    <MisterSlideOverPanel
      title={customiserTitle || 'Customise Cookies'}
      visible={customiseCookies}
      onClose={endCustomise}
      footer={
        <div className='w-full justify-center px-4 py-4 text-center'>
          <Button onClick={endCustomise} e2eSelector='save-cookies'>
            {closeCustomiserLabel || 'Save and close'}
          </Button>
        </div>
      }
    >
      <div className='space-y-4 pr-2 text-body-md md:space-y-6 lg:space-y-8 2xl:space-y-14'>
        {customiseCopy && <RichText data={customiseCopy} className='text-body-md' />}
        {cookieTypes?.map(({ type, title, description }) => (
          <MisterToggleWithDescription
            id={type}
            key={type}
            label={title}
            description={description}
            value={consent[type] || false}
            disabled={type === 'necessary'}
            onChange={(value) => handleCookieConsentChange(value, type)}
          />
        ))}
      </div>
    </MisterSlideOverPanel>
  )
}

export default MisterCookieCustomiser
