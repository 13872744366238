import { FunctionComponent, useEffect, useRef } from 'react'
import Link from 'next/link'

import { Button } from '@ui/components/ui/button'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetOverlay, SheetTitle } from '@ui/components/ui/sheet'

import { useMobileMenu } from 'src/contexts/MobileMenu.context'
import { useUI } from 'src/contexts/UI.context'
import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import MobileMenuBreadcrumbs from './MobileMenuBreadcrumbs'
import TransitionContainer from './TransitionContainer'

export const MobileMenu: FunctionComponent = () => {
  const { displayMobileMenu } = useUI()
  const { handleMobileMenuClose, nextMenu, resetMenus, direction, currentMenu } = useMobileMenu()

  const mobileMenuRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    mobileMenuRef.current ? mobileMenuRef.current.scrollTo(0, 0) : null
  }, [nextMenu])

  return (
    <Sheet
      open={displayMobileMenu}
      onOpenChange={() => {
        trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_CLOSE)
        handleMobileMenuClose()
      }}
    >
      {/* Hacky overlay button to allow clicking logo */}
      <SheetOverlay>
        <div className='absolute inset-x-0 flex items-center justify-center'>
          <Link href='/'>
            <Button variant='ghost' className='h-12 w-40' size='xl' />
          </Link>
        </div>
      </SheetOverlay>
      <SheetContent fade={true} withOverlay={false} withClose={false} side='bottom' className='h-[calc(100dvh-48px)] border-0 p-0'>
        <SheetHeader>
          <SheetTitle className='sr-only'>Mobile Menu</SheetTitle>
          <SheetDescription className='sr-only'>Mobile menu</SheetDescription>
        </SheetHeader>
        <div ref={mobileMenuRef} className='relative flex h-full flex-col overflow-scroll scrollbar-hide'>
          <MobileMenuBreadcrumbs />

          <div className='relative h-full'>
            <TransitionContainer motion={direction === null ? null : direction === 'forward' ? 'to-start' : 'to-end'} onAnimationEnd={resetMenus}>
              {currentMenu.component}
            </TransitionContainer>
            {nextMenu && (
              <TransitionContainer motion={direction === null ? null : direction === 'forward' ? 'from-end' : 'from-start'} onAnimationEnd={resetMenus}>
                {nextMenu?.component}
              </TransitionContainer>
            )}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}
