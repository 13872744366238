import { FunctionComponent } from 'react'

import { cn } from '@ui/lib/utils'

import MisterImage from 'src/components/shared/image/MisterImage'
import { Measurement } from 'src/domain/sizeGuide.domain'

interface MisterMeasurementsGuideProps {
  measurements: Measurement[]
  className?: string
  howToMeasureAsDrawer?: boolean
}

const MeasureGuide: FunctionComponent<{ measurement: Measurement }> = ({ measurement }) => {
  if (!measurement.toolTipCopy) return null
  return (
    <div className='flex flex-col items-stretch justify-between overflow-hidden rounded-md border border-brand-beige-light sm:flex-row'>
      {measurement.toolTipInfographic && <MisterImage mobile={measurement.toolTipInfographic} alt={measurement.title} className='w-full object-contain sm:w-1/2' />}
      <div className={cn('flex w-full flex-col gap-3 bg-brand-beige-light p-7', measurement.toolTipInfographic && 'sm:w-1/2')}>
        <span className='text-body-lg-bold'>{measurement.title}</span>
        <span className='text-body-md'>{measurement.toolTipCopy}</span>
      </div>
    </div>
  )
}

const MisterMeasurementsGuide: FunctionComponent<MisterMeasurementsGuideProps> = ({ measurements, className }) => (
  <div className={className}>
    <div className='flex flex-col gap-5'>
      {measurements.map((measurement, i) => (
        <MeasureGuide key={i} measurement={measurement} />
      ))}
    </div>
  </div>
)

export default MisterMeasurementsGuide
