import { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { ChevronDown, ChevronUp, LoaderCircle } from 'lucide-react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'

import { SupportedLocale } from 'data-access/domain/constants'
import { ProductCardProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'
import { Button } from '@ui/components/ui/button'
import { Separator } from '@ui/components/ui/separator'
import { cn } from '@ui/lib/utils'
import { getPriceValidUntil } from 'utilities/date/format/formatPriceValidUntil'
import { formatPrice, localeCurrencyMap } from 'utilities/string/format/price'

import CollapseContainer from 'src/components/shared/CollapseContainer'
import MisterImage from 'src/components/shared/image/MisterImage'
import MisterIcon from 'src/components/shared/MisterIcon'
import { useTranslations } from 'src/contexts/Globals.context'
import { enrichProductWithLiveAvailability, ProductWithEnrichedAvailability } from 'src/domain/productCard.domain'
import useProductForm from 'src/hooks/shop/useProductForm'
import { productTitle as productTitleHandler } from 'src/utils/product.util'
import SplitOptionSelect from '../product-card/quick-add-to-cart/MiniSplitOptionSelect'
import ScrollableListOptionSelect from '../product-card/quick-add-to-cart/ScrollableListOptionSelect'

interface QuickAddToCartProps {
  selectedVariant: ProductWithEnrichedAvailability['variants'][0] | null
  setSelectedVariant: Dispatch<SetStateAction<ProductWithEnrichedAvailability['variants'][0] | null>>
  atcOpen?: boolean
  product: ProductWithEnrichedAvailability<ProductCardProduct>
}

const QuickAddToCart: FunctionComponent<QuickAddToCartProps> = ({ selectedVariant, setSelectedVariant, atcOpen, product }) => {
  const translate = useTranslations()

  return (
    <form>
      <div className='flex flex-col py-5'>
        <fieldset className='space-y-2'>
          <label className='text-body-sm-bold'>{translate('chooseSize', 'Choose a size')}</label>
          <div className='max-h-48 min-h-40 w-full overflow-scroll pr-2 scrollbar-hide'>
            <div className='relative h-full pb-4'>
              {product.primaryCollection?.productType === 'trousers' ? (
                <SplitOptionSelect product={product} selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant} />
              ) : (
                <ScrollableListOptionSelect product={product} selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant} />
              )}
              <div className='pointer-events-none absolute inset-0 @container'>
                <div className='br-gradient-from-t sticky inset-x-0 -top-[1px] mt-auto hidden h-5 animate-fade-at-start items-center justify-center bg-gradient-to-b from-white to-transparent [animation-timeline:scroll()] @h-[12rem]:flex'>
                  <ChevronUp className='hidden size-5 text-brand-grey-dark supports-[animation-timeline]:block' />
                </div>

                <div className='br-gradient-from-b sticky inset-x-0 top-[11rem] mt-auto hidden h-5 animate-fade-at-end items-center justify-center bg-gradient-to-t from-white to-transparent [animation-timeline:scroll()] @h-[12rem]:flex'>
                  <ChevronDown className='hidden size-5 text-brand-grey-dark supports-[animation-timeline]:block' />
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <Separator />
      </div>
    </form>
  )
}

interface MiniProductCardProps {
  product: ProductCardProduct
  setCurrentCard: (currentCard: number | null) => void
  currentCard: number | null
  id: number
}

const MiniProductCard: FunctionComponent<MiniProductCardProps> = ({ product, setCurrentCard, currentCard, id }) => {
  const { locale } = useRouter()
  const translate = useTranslations()
  const enrichedProduct = enrichProductWithLiveAvailability(product, locale as SupportedLocale)
  const { loadingAddToCart, handleAddToCart, selectedVariant, setSelectedVariant } = useProductForm({
    product: enrichedProduct,
    onAddToCartComplete: () => setCurrentCard(null),
  })
  const isCurrentCard = currentCard === id

  const priceValidUntil = getPriceValidUntil()

  const handleQuickAddToCart = async () => {
    if (!isCurrentCard) {
      return setCurrentCard(id)
    }

    const { error } = await handleAddToCart()

    if (error) {
      return toast(error, { type: 'error' })
    }
  }

  const { title, category: subtitle } = productTitleHandler(product.productTitle, true)

  return (
    <div className='grid h-full grid-cols-[auto_1fr] gap-x-3'>
      <Link href={`/products/${product?.slug.current}`} className='col-span-2 col-start-1 row-span-2 row-start-1 grid grid-cols-subgrid grid-rows-subgrid'>
        <div className='col-start-1 row-span-2 row-start-1 flex w-24 justify-center rounded-md bg-brand-pck-bg'>
          <MisterImage className='w-20 object-cover' mobile={product.images[0]} mobileSizes='200px' desktopSizes='200px' />
        </div>
        <div className='row-start-1 flex w-full flex-col gap-1 py-2'>
          <div className='flex justify-between'>
            <span className='text-body-lg-bold'>{title}</span>
            <span className='text-body-lg-bold'>{formatPrice(product.price)}</span>
          </div>
          <span className='text-body-md'>{subtitle}</span>
        </div>
      </Link>

      <CollapseContainer collapse={!isCurrentCard} className='col-span-2 col-start-1 row-start-3 row-end-4'>
        <QuickAddToCart selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant} atcOpen={isCurrentCard} product={enrichedProduct} />
      </CollapseContainer>
      <Button
        className={cn(
          'z-10 col-start-1 col-end-3 row-start-2 row-end-5 self-end justify-self-end bg-brand-beige-light text-white transition-[width,background-color] duration-300',
          isCurrentCard ? 'w-full bg-brand-blue-action' : '',
        )}
        variant='ghost'
        size='icon'
        onClick={handleQuickAddToCart}
        disabled={isCurrentCard && !selectedVariant}
        data-testid='quick-add-to-cart'
        itemProp={isCurrentCard ? 'offers' : undefined}
        itemType={isCurrentCard ? 'https://schema.org/Offer' : undefined}
        itemScope={isCurrentCard ? true : undefined}
      >
        <div className={cn('inline-grid items-center transition-[grid-template-columns] duration-300 ease-out', isCurrentCard ? 'grid-cols-[auto,1fr]' : 'grid-cols-[auto,0fr]')}>
          {loadingAddToCart ? (
            <LoaderCircle className='size-4 animate-spin' />
          ) : (
            <MisterIcon type='cart' className={cn('size-6 transition-colors', !isCurrentCard && 'text-brand-blue')} />
          )}
          <span className={cn('min-w-0 text-white transition-[opacity] duration-300 ease-out', isCurrentCard ? 'opacity-1 ml-3' : 'ml-0 opacity-0')}>
            {isCurrentCard && (
              <>
                <meta itemProp='price' content={String(product.price)} />
                <meta itemProp='priceValidUntil' content={priceValidUntil} />
                <meta itemProp='itemCondition' content='https://schema.org/NewCondition' />
                <meta itemProp='priceCurrency' content={localeCurrencyMap?.[locale || 'en'] || 'EUR'} />
              </>
            )}
            {selectedVariant ? translate('addToCartButtonText', 'Add to Cart') : translate('chooseSize', 'Choose a size')}
          </span>
        </div>
      </Button>
      <CollapseContainer collapse={!isCurrentCard} className='col-span-2 col-start-1 row-start-4 self-end'>
        <div className='h-10' />
      </CollapseContainer>
      <CollapseContainer collapse={!isCurrentCard} className={cn('col-span-2 col-start-1 row-start-5 self-end transition-all duration-150', !isCurrentCard && 'delay-150')}>
        <Button
          variant='outline'
          size='md'
          onClick={() => setCurrentCard(null)}
          className={cn('flex w-full transition-[margin,opacity] duration-300', !isCurrentCard ? '-mt-11 opacity-0' : 'mt-2')}
        >
          {translate('close')}
        </Button>
      </CollapseContainer>
    </div>
  )
}

export default MiniProductCard
