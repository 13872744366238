import { FunctionComponent } from 'react'

import { CareInstructionSetWithDescription } from 'data-access/sanity/fragments/sections/sectionCare.fragment'
import { cn } from '@ui/lib/utils'

import { useTranslations } from 'src/contexts/Globals.context'
import MisterCareDescription from './MisterCareDescription'
import MisterCareIconList from './MisterCareIconList'

interface MisterCareSetProps {
  data: CareInstructionSetWithDescription
  className?: string
}

const MisterCareSet: FunctionComponent<MisterCareSetProps> = ({ data, className }) => {
  const translate = useTranslations()
  return (
    <div className={cn('flex flex-col gap-10', className)}>
      <div className={cn('flex flex-col gap-4')}>
        {data?.washing?.description && <MisterCareDescription title={translate('washing', 'Washing')} description={data?.washing?.description} />}
        {data?.drying?.description && <MisterCareDescription title={translate('drying', 'Drying')} description={data?.drying?.description} />}
        {data?.ironing?.description && <MisterCareDescription title={translate('ironing', 'Ironing')} description={data?.ironing?.description} />}
        {data?.storage?.description && <MisterCareDescription title={translate('storage', 'Storage')} description={data?.storage?.description} />}
        {data?.wearAndTear?.description && <MisterCareDescription title={translate('wearAndTear', 'Wear and tear')} description={data?.wearAndTear?.description} />}
      </div>
      <MisterCareIconList data={data} />
    </div>
  )
}

export default MisterCareSet
