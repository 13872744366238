'use client'

import { FunctionComponent, KeyboardEvent, TransitionEvent, useEffect, useRef, useState } from 'react'
import { ChevronDown, ChevronUp, LoaderCircle } from 'lucide-react'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'

import { DEFAULT_LOCALE } from 'data-access/domain/constants'
import { cn } from '@ui/lib/utils'
import { getPriceValidUntil } from 'utilities/date/format/formatPriceValidUntil'
import { localeCurrencyMap } from 'utilities/string/format/price'

import MisterIcon from 'src/components/shared/MisterIcon'
import { useTranslations } from 'src/contexts/Globals.context'
import useProductForm from 'src/hooks/shop/useProductForm'
import { useProductCard } from '../ProductCard.context'
import ScrollableListOptionSelect from './ScrollableListOptionSelect'
import SplitOptionSelect from './SplitOptionSelect'

interface QuickAddToCartProps {
  onAddToCart?: () => void
  forceQuickAdd?: boolean
  addToCartEffect?: 'toast' | 'drawer'
}

const QuickAddToCart: FunctionComponent<QuickAddToCartProps> = ({ onAddToCart, forceQuickAdd = false, addToCartEffect = 'drawer' }) => {
  const { product } = useProductCard('Quick add to cart')
  const isGiftCard = product.slug.current.includes('gift-card')
  const ref = useRef<HTMLFormElement>(null)
  const { locale } = useRouter()
  const [atcOpen, setAtcOpen] = useState(false)
  const translate = useTranslations()
  const [buttonTransitionEndEvent, setButtonTransitionEndEvent] = useState<TransitionEvent<HTMLButtonElement> | null>(null)
  const [buttonKeyDownEvent, setButtonKeyDownEvent] = useState<KeyboardEvent<HTMLButtonElement> | null>(null)

  // Frustratingly Safari and Firefox don't appear to support this approach.
  // TODO: Investigate alternative approaches.
  // https://developer.mozilla.org/en-US/docs/Web/API/FocusEvent/relatedTarget
  // const handleBlur = (e: any) => {
  //   console.log('blur handler fired', e.relatedTarget)
  //   if (ref.current && !ref.current.contains(e.relatedTarget)) {
  //     setAtcOpen(false)
  //   }
  // }

  const priceValidUntil = getPriceValidUntil()

  const { loadingAddToCart, handleAddToCart, selectedVariant, setSelectedVariant } = useProductForm({
    product,
  })

  const resetQuickAddToCart = () => {
    setSelectedVariant(null)
    setAtcOpen(false)
    setButtonTransitionEndEvent(null)
    setButtonKeyDownEvent(null)
  }

  const handleQuickAddToCart = async () => {
    const { error } = await handleAddToCart(addToCartEffect)

    if (error) {
      return toast(error, { type: 'error' })
    }

    onAddToCart?.()
    resetQuickAddToCart()
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (atcOpen && ref.current && !ref.current.contains(event.target as Node)) {
        setAtcOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [atcOpen])

  if (!product.isAvailable) {
    return null
  }

  return (
    <form
      className={cn(forceQuickAdd ? 'block' : 'hidden @[15.625rem]/product-card:block')}
      onMouseLeave={() => {
        if (!forceQuickAdd) setAtcOpen(false)
        resetQuickAddToCart()
      }}
      ref={ref}
    >
      <div
        className={cn(
          'absolute bottom-4 flex flex-row-reverse transition-all duration-200 ease-out',
          forceQuickAdd
            ? 'inset-x-2 md:inset-x-4'
            : '-inset-x-4 opacity-0 group-focus-within/product:inset-x-2 group-focus-within/product:opacity-100 group-hover/product:inset-x-2 group-hover/product:opacity-100 md:-inset-x-5 group-focus-within/product:md:inset-x-4 group-hover/product:md:inset-x-4',
          atcOpen &&
            'inset-x-0 opacity-100 group-focus-within/product:inset-x-0 group-hover/product:inset-x-0 group-focus-within/product:md:inset-x-1 group-hover/product:md:inset-x-1',
        )}
      >
        <button
          type='button'
          onClick={atcOpen ? handleQuickAddToCart : () => setAtcOpen(true)}
          className={cn(
            'relative inline-flex h-10 items-center justify-center self-end whitespace-nowrap rounded-md text-body-sm font-medium ring-offset-transparent transition-all duration-300 ease-out after:absolute after:-z-[1] after:h-10 after:scale-90 after:rounded-md after:opacity-50 after:transition-all after:duration-300 after:ease-out hover:after:scale-100 hover:after:opacity-100 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
            atcOpen
              ? 'z-[20] w-full bg-brand-blue-action text-white opacity-100 after:w-full after:bg-brand-blue-action'
              : 'z-[18] w-10 text-secondary-foreground after:w-10 after:bg-secondary',
            atcOpen && !selectedVariant && 'cursor-not-allowed bg-brand-blue-light after:bg-brand-blue-light',
            !atcOpen && forceQuickAdd && 'after:scale-100 after:opacity-100',
          )}
          onTransitionEnd={setButtonTransitionEndEvent}
          onKeyDown={setButtonKeyDownEvent}
          disabled={atcOpen && !selectedVariant}
          data-testid='quick-add-to-cart'
          itemProp={atcOpen ? 'offers' : undefined}
          itemType={atcOpen ? 'https://schema.org/Offer' : undefined}
          itemScope={atcOpen ? true : undefined}
        >
          <div className={cn('inline-grid items-center transition-[grid-template-columns] duration-300 ease-out', atcOpen ? 'grid-cols-[auto,1fr]' : 'grid-cols-[auto,0fr]')}>
            {loadingAddToCart ? <LoaderCircle className='size-4 animate-spin' /> : <MisterIcon type='cart' className='size-6' />}
            <span className={cn('min-w-0 text-white transition-[opacity] duration-300 ease-out', atcOpen ? 'opacity-1 ml-3' : 'ml-0 opacity-0')}>
              {atcOpen && (
                <>
                  <meta itemProp='price' content={String(product.price)} />
                  <meta itemProp='priceValidUntil' content={priceValidUntil} />
                  <meta itemProp='itemCondition' content='https://schema.org/NewCondition' />
                  <meta itemProp='priceCurrency' content={localeCurrencyMap?.[locale || DEFAULT_LOCALE] || 'EUR'} />
                </>
              )}
              {selectedVariant
                ? translate('addToCartButtonText', 'Add to Cart')
                : isGiftCard
                  ? translate('noAmountError', 'Please select an amount')
                  : translate('chooseSize', 'Choose a size')}
            </span>
          </div>
        </button>
      </div>
      <div
        className={cn(
          'absolute inset-x-0 top-full z-[18] rounded-t-md bg-white transition-all duration-300 ease-out',
          atcOpen ? '-translate-y-full' : 'scrollbar-hide',
          product.primaryCollection?.productType != 'trousers' ? 'pb-20' : 'pb-0',
        )}
      >
        <fieldset className='space-y-2 px-1 pt-4'>
          <label className='px-2 text-body-sm-bold'>{translate('chooseSize', 'Choose a size')}</label>
          <div className='h-full w-full overflow-scroll scrollbar-hide'>
            <div className='relative h-full pb-4'>
              {atcOpen &&
                (product.primaryCollection?.productType === 'trousers' ? (
                  <SplitOptionSelect
                    product={product}
                    selectedVariant={selectedVariant}
                    setSelectedVariant={setSelectedVariant}
                    loadingAddToCart={loadingAddToCart}
                    handleQuickAddToCart={handleQuickAddToCart}
                    onDeactivate={resetQuickAddToCart}
                    buttonTransitionEndEvent={buttonTransitionEndEvent}
                    buttonKeyDownEvent={buttonKeyDownEvent}
                    className='pb-20'
                  />
                ) : (
                  <ScrollableListOptionSelect product={product} selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant} />
                ))}
              <div className='pointer-events-none absolute inset-0 @container'>
                <div className='br-gradient-from-t sticky inset-x-0 -top-[1px] mt-auto hidden h-5 animate-fade-at-start items-center justify-center bg-gradient-to-b from-white to-transparent [animation-timeline:scroll()] @h-xs:flex'>
                  <ChevronUp className='hidden size-5 text-brand-grey-dark supports-[animation-timeline]:block' />
                </div>

                <div className='br-gradient-from-b sticky inset-x-0 top-[calc(14rem)] mt-auto hidden h-5 animate-fade-at-end items-center justify-center bg-gradient-to-t from-white to-transparent [animation-timeline:scroll()] @h-xs:flex'>
                  <ChevronDown className='hidden size-5 text-brand-grey-dark supports-[animation-timeline]:block' />
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </form>
  )
}

export default QuickAddToCart
