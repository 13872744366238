'use client'

import { FunctionComponent, KeyboardEvent, TransitionEvent, useState } from 'react'
import { LoaderCircle } from 'lucide-react'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'

import { DEFAULT_LOCALE } from 'data-access/domain/constants'
import { Drawer, DrawerContent, DrawerTrigger } from '@ui/components/ui/drawer'
import { cn } from '@ui/lib/utils'
import { getPriceValidUntil } from 'utilities/date/format/formatPriceValidUntil'
import { formatPrice, localeCurrencyMap } from 'utilities/string/format/price'

import MisterButton from 'src/components/shared/button/MisterButton'
import MisterImage from 'src/components/shared/image/MisterImage'
import MisterIcon from 'src/components/shared/MisterIcon'
import { useTranslations } from 'src/contexts/Globals.context'
import useProductForm from 'src/hooks/shop/useProductForm'
import { useProductCard } from '../ProductCard.context'
import ScrollableListOptionSelect from './ScrollableListOptionSelect'
import SplitOptionSelect from './SplitOptionSelect'

interface QuickAddToCartDrawerProps {
  addToCartEffect?: 'toast' | 'drawer'
  showWithinCart?: boolean
  onAddToCart?: () => void
  forceQuickAdd?: boolean
}

const QuickAddToCartDrawer: FunctionComponent<QuickAddToCartDrawerProps> = ({ addToCartEffect = 'drawer', onAddToCart, forceQuickAdd, showWithinCart }) => {
  const translate = useTranslations()
  const { product } = useProductCard('Quick add to cart')
  const [buttonTransitionEndEvent, setButtonTransitionEndEvent] = useState<TransitionEvent<HTMLButtonElement> | null>(null)
  const [buttonKeyDownEvent, setButtonKeyDownEvent] = useState<KeyboardEvent<HTMLButtonElement> | null>(null)

  const { locale } = useRouter()

  const [atcOpen, setAtcOpen] = useState(false)
  const { loadingAddToCart, handleAddToCart, selectedVariant, setSelectedVariant } = useProductForm({
    product,
  })

  const handleQuickAddToCartClick = async () => {
    if (!atcOpen) {
      return setAtcOpen(true)
    }

    const { error } = await handleAddToCart(addToCartEffect)

    if (error) {
      return toast(error, { type: 'error' })
    }

    onAddToCart?.()
  }

  const resetQuickAddToCart = () => {
    setSelectedVariant(null)
    setAtcOpen(false)
    setButtonTransitionEndEvent(null)
    setButtonKeyDownEvent(null)
  }

  const [productTile, collectionTitle] = product.productTitle?.includes('*') ? product.productTitle.split('*') : [product.productTitle, undefined]

  return (
    <div
      className={cn(
        'absolute bottom-2 flex flex-row-reverse transition-all duration-200 ease-out md:bottom-4',
        forceQuickAdd
          ? 'inset-x-2 opacity-100'
          : '-inset-x-4 opacity-0 group-focus-within/product:inset-x-2 group-focus-within/product:opacity-100 group-hover/product:inset-x-2 group-hover/product:opacity-100 md:-inset-x-5 group-focus-within/product:md:inset-x-4 group-hover/product:md:inset-x-4',
      )}
    >
      <Drawer open={atcOpen} onOpenChange={(open) => setAtcOpen(open)}>
        <DrawerTrigger
          onTransitionEnd={setButtonTransitionEndEvent}
          onKeyDown={setButtonKeyDownEvent}
          className={cn(
            'relative z-[18] inline-flex h-10 w-10 items-center justify-center justify-self-end rounded-md ring-offset-transparent transition-all duration-300 ease-out after:absolute after:-z-[1] after:h-10 after:w-10 after:scale-90 after:rounded-md after:bg-secondary after:opacity-50 after:transition-all after:duration-300 after:ease-out hover:after:scale-100 hover:after:opacity-100 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
          )}
        >
          <div className={cn('inline-grid items-center transition-[grid-template-columns] duration-300 ease-out', atcOpen ? 'grid-cols-[auto,1fr]' : 'grid-cols-[auto,0fr]')}>
            {loadingAddToCart ? <LoaderCircle className='size-4 animate-spin' /> : <MisterIcon type='cart' className='size-6' />}
          </div>
        </DrawerTrigger>
        <DrawerContent
          className={cn('flex w-11/12 flex-col gap-4 border-l px-4 pb-4 sm:max-w-sm md:max-w-xl', showWithinCart ? 'right-0' : 'right-4')}
          overlayClassName={cn(showWithinCart && 'bg-black/20')}
        >
          <div className='flex'>
            <MisterImage className='w-24 rounded-sm' mobile={product.images[0]} />
            <div className='ml-4 flex-1'>
              <div className='flex justify-between text-body-lg'>
                <span className='text-body-lg-bold'>{productTile}</span>
                <span>{formatPrice(product.price, locale)}</span>
              </div>
              {collectionTitle && <span className='text-body-md'>{collectionTitle}</span>}
            </div>
          </div>
          <div>
            {product.primaryCollection?.productType === 'trousers' ? (
              <SplitOptionSelect
                product={product}
                selectedVariant={selectedVariant}
                setSelectedVariant={setSelectedVariant}
                loadingAddToCart={loadingAddToCart}
                handleQuickAddToCart={handleQuickAddToCartClick}
                onDeactivate={resetQuickAddToCart}
                buttonTransitionEndEvent={buttonTransitionEndEvent}
                buttonKeyDownEvent={buttonKeyDownEvent}
                className='pb-20'
                forceShowButton={true}
              />
            ) : (
              <>
                <ScrollableListOptionSelect product={product} selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant} />
                <button
                  type='button'
                  onClick={() => (selectedVariant ? handleQuickAddToCartClick() : null)}
                  className={cn(
                    'after:full inset-x-0 mt-4 inline-flex h-10 w-full items-center justify-center self-end whitespace-nowrap rounded-md bg-brand-blue-action text-body-sm font-medium text-white opacity-100 ring-offset-transparent transition-all duration-300 ease-out after:absolute after:-z-[1] after:h-10 after:w-full after:scale-90 after:rounded-md after:bg-brand-blue-action',
                    !selectedVariant ? 'cursor-not-allowed bg-brand-blue-light' : 'bg-brand-blue-action text-white opacity-100 after:w-full after:bg-brand-blue-action',
                  )}
                >
                  <div className='flex items-center'>
                    {loadingAddToCart ? <LoaderCircle className='size-4 animate-spin' /> : <MisterIcon type='cart' className='size-6' />}
                    <span className='ml-3 text-white'>
                      <meta itemProp='price' content={String(product.price)} />
                      <meta itemProp='priceValidUntil' content={getPriceValidUntil()} />
                      <meta itemProp='itemCondition' content='https://schema.org/NewCondition' />
                      <meta itemProp='priceCurrency' content={localeCurrencyMap?.[locale || DEFAULT_LOCALE] || 'EUR'} />

                      {selectedVariant ? translate('addToCartButtonText', 'Add to Cart') : translate('chooseSize', 'Choose a size')}
                    </span>
                  </div>
                </button>
              </>
            )}
          </div>
          <MisterButton
            onClick={() => setAtcOpen(false)}
            variant='transparent'
            buttonText={translate('continueShopping', 'Continue shopping')}
            type='button'
            className='w-full rounded-md'
          />
        </DrawerContent>
      </Drawer>
    </div>
  )
}

export default QuickAddToCartDrawer
