import { FunctionComponent, HTMLAttributes } from 'react'

import { Media } from 'data-access/sanity/fragments/components/media.fragment'

import MisterImage from '../image/MisterImage'
import MisterVideo from '../video/MisterVideo'

interface MisterMediaProps {
  id?: string
  media?: Media
  sectionIndex?: number | null
  className?: string | null
  desktopSizes?: string
  mobileSizes?: string
  priority?: boolean
  itemProp?: HTMLAttributes<HTMLImageElement | HTMLVideoElement>['itemProp']
  quality?: string
}

const MisterMedia: FunctionComponent<MisterMediaProps> = ({ id, media, sectionIndex, className, desktopSizes, mobileSizes, priority, itemProp, quality }) => {
  if (!media) {
    return null
  }

  if (media?.type === 'remoteVideo') {
    return (
      <MisterVideo
        id={id}
        className={className}
        autoplay={media?.videoAutoplay}
        controls={media?.videoControls}
        videoPoster={media?.videoPoster}
        videoPosterDesktop={media?.videoPosterDesktop}
        remoteVideoUrl={media?.remoteVideoUrl}
        remoteVideoUrlDesktop={media?.remoteVideoUrlDesktop}
        priority={priority || sectionIndex === 0}
      />
    )
  }

  return (
    <MisterImage
      priority={priority || sectionIndex === 0}
      desktop={media?.desktop}
      mobile={media?.mobile}
      alt={media?.alt}
      className={className}
      desktopSizes={desktopSizes}
      mobileSizes={mobileSizes}
      itemProp={itemProp}
      quality={quality}
    />
  )
}

export default MisterMedia
