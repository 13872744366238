import { FunctionComponent } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { Configure, InstantSearch, useFrequentlyBoughtTogether } from 'react-instantsearch'

import { getProductsForRecommendations, getRecommendationFilterData, GetRecommendationFilterDataResponse } from 'data-access'
import { algoliaLiteClient, getAlgoliaIndexName } from 'data-access/algolia/utils'
import { SupportedLocale } from 'data-access/domain/constants'

import { useExperimentFeatureFlag } from 'src/hooks/posthog/useExperimentFeatureFlag'
import useCart from 'src/hooks/shop/useCart'
import { RecommendationsProvider } from '../../../contexts/Recommendations.context'
import MisterRecommendationsRow from './MisterRecommendationsRow'

interface Props {
  title: string
  productHandles: string[]
  limit?: number
}

const AlgoliaFrequentlyBoughtTogether: FunctionComponent<Props> = ({ limit = 5, productHandles, title }) => {
  const { locale } = useRouter()
  const { lineItems } = useCart()
  const flag = 'cart-cross-sell'
  const abTestVariant = useExperimentFeatureFlag(flag, undefined, false) || 'control'

  const { data: { activeSeason, productTypes, hasSsProducts, hasAwProducts } = {}, isLoading: filterDataLoading } = useQuery<GetRecommendationFilterDataResponse>({
    queryKey: ['initialProducts', lineItems.map((item) => item?.merchandise?.product?.id), locale],
    queryFn: () => getRecommendationFilterData(lineItems?.map((item) => item?.merchandise?.product?.id).filter(Boolean) as string[], locale as SupportedLocale),
    staleTime: 1000 * 60 * 60, // 1h
    enabled: lineItems.length > 0 && abTestVariant === 'with-filtered-cross-sell',
    refetchOnWindowFocus: false,
  })

  let filters = `isAvailable:true`
  if (activeSeason === 'ss' && !hasAwProducts) {
    filters += ' AND NOT collectionSeason:aw'
  } else if (activeSeason === 'aw' && !hasSsProducts) {
    filters += ' AND NOT collectionSeason:ss'
  }

  productTypes?.forEach((productType) => {
    filters += ` AND NOT primaryCollection.productType:${productType}`
  })

  const { items } = useFrequentlyBoughtTogether({
    limit,
    objectIDs: productHandles,
    queryParameters: {
      filters: abTestVariant === 'with-filtered-cross-sell' ? filters : `isAvailable:true`,
    },
  })

  const { data, isLoading } = useQuery({
    queryKey: ['frequentlyBoughtProducts', items.map((item) => item.objectID), locale],
    queryFn: () =>
      getProductsForRecommendations(
        items.map((item) => item.slug.current),
        locale as SupportedLocale,
        limit,
      ),
    staleTime: 1000 * 60 * 60, // 1h
    enabled: items.length > 0,
    refetchOnWindowFocus: false,
  })

  if (!isLoading && !filterDataLoading && (data || [])?.length === 0) {
    return null
  }

  return (
    <RecommendationsProvider type='frequently_bought_together'>
      <MisterRecommendationsRow title={title} products={data || []} isLoading={isLoading} />
    </RecommendationsProvider>
  )
}

const MisterFrequentlyBoughtTogether: FunctionComponent<Props> = ({ limit = 4, productHandles, title }) => {
  const { locale } = useRouter()
  const indexName = getAlgoliaIndexName(locale as string)

  return (
    <InstantSearch searchClient={algoliaLiteClient} indexName={indexName}>
      <Configure enablePersonalization={true} />
      <AlgoliaFrequentlyBoughtTogether limit={limit} productHandles={productHandles} title={title} />
    </InstantSearch>
  )
}

export default MisterFrequentlyBoughtTogether
