import { SupportedLocale } from 'data-access/domain/constants'
import { BreadcrumbItem, CollectionBreadcrumb } from 'data-access/sanity/fragments/components/breadcrumb.fragment'
import { BreadcrumbPageData } from '@ui/components/ui/dynamic-breadcrumb'
import { buildUrl } from 'utilities/string/url'

/**
 * Create the breadcrumb items to display in the dynamic breadcrumb component
 */
export function createBreadcrumbItems(breadcrumb: CollectionBreadcrumb, locale: string, lastItemAsLink = false): (BreadcrumbPageData | BreadcrumbPageData[])[] {
  if (!breadcrumb || !breadcrumb.items || breadcrumb.items.length === 0) {
    return []
  }

  const { grandparent, parents, current } = categorizeBreadcrumbItems(breadcrumb.items)

  return [...createGrandparentItem(grandparent, locale), ...createParentItems(parents, locale), ...createCurrentItem(current, locale, lastItemAsLink)].filter(Boolean)
}

/**
 * Categorize the breadcrumb items into grandparent, parent and current
 */
function categorizeBreadcrumbItems(items: BreadcrumbItem[]) {
  return {
    grandparent: items.find((item) => item.type === 'grandparent'),
    parents: items.filter((item) => item.type === 'parent'),
    current: items.find((item) => item.type === 'current'),
  }
}

/**
 * Create the grandparent item to display in the breadcrumb
 */
function createGrandparentItem(item: BreadcrumbItem | undefined, locale: string): BreadcrumbPageData[] {
  return item ? [createBreadcrumbItem(item, locale)] : []
}

/**
 * Create the parent items to display in the breadcrumb
 */
function createParentItems(items: BreadcrumbItem[], locale: string): (BreadcrumbPageData | BreadcrumbPageData[])[] {
  if (items.length > 1) {
    return [items.map((item) => createBreadcrumbItem(item, locale))]
  } else if (items.length === 1 && items[0]) {
    return [createBreadcrumbItem(items[0], locale)]
  }
  return []
}

/**
 * Create the current item to display in the breadcrumb
 */
function createCurrentItem(item: BreadcrumbItem | undefined, locale: string, lastItemAsLink: boolean): BreadcrumbPageData[] {
  if (!item) return []
  return lastItemAsLink ? [createBreadcrumbItem(item, locale)] : [{ label: item.title }]
}

/**
 * Create the breadcrumb item to display in the breadcrumb
 */
function createBreadcrumbItem(item: BreadcrumbItem, locale: string): BreadcrumbPageData {
  return {
    href: buildUrl({ slug: item.slug, _type: 'collection' }, { locale: locale as SupportedLocale }),
    label: item.title,
  }
}
