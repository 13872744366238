import { FunctionComponent, useEffect, useState } from 'react'
import { X } from 'lucide-react'
import { useRouter } from 'next/router'
import { useMedia } from 'react-use'

import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '@ui/components/ui/drawer'
import { Separator } from '@ui/components/ui/separator'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from '@ui/components/ui/sheet'
import { cn } from '@ui/lib/utils'

import { useTranslations } from 'src/contexts/Globals.context'
import { useUI } from 'src/contexts/UI.context'
import { useExperimentFeatureFlag } from 'src/hooks/posthog/useExperimentFeatureFlag'
import useCart from 'src/hooks/shop/useCart'
import CollapseContainer from '../../components/shared/CollapseContainer'
import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from '../../utils/telemetry.util'
import ProductCard from '../product-card/ProductCard'
import MiniProductCard from './MiniProductCard'

const ShopTheLookDrawer: FunctionComponent = () => {
  const translate = useTranslations()
  const { asPath } = useRouter()
  const { cart } = useCart()
  const isMobile = useMedia('(max-width: 768px)', false)
  const [currentCard, setCurrentCard] = useState<number | null>(null)
  const noCurrentCard = currentCard === null

  // A/B test Compare the new shop the look drawer vs the current slide over panel on mobile devices
  // @see https://eu.posthog.com/project/12378/experiments/23696
  const flag = 'shop-the-look-mobile-drawer'
  const variant: 'control' | 'drawer' | string | boolean | undefined = useExperimentFeatureFlag(flag, undefined, false)

  const {
    displayShopTheLook: { open, products },
    closeShopTheLook,
  } = useUI()

  useEffect(() => {
    if (open) {
      trackEvent(TELEMETRY_DATA_LAYER_EVENTS.VIEW_SHOP_THE_LOOK, { ecommerce: { products, currency: cart?.cost?.totalAmount?.currencyCode || 'EUR' } })
    }
  }, [cart?.cost?.totalAmount?.currencyCode, open, products])

  useEffect(() => {
    if (open) {
      closeShopTheLook()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath])

  return (
    <>
      {variant === 'drawer' && isMobile ? (
        <Drawer open={open} onOpenChange={() => (closeShopTheLook(), setCurrentCard(null))}>
          <DrawerContent className='max-h-[90dvh]'>
            <DrawerHeader className='flex justify-between text-heading-5'>
              <DrawerTitle>{translate('shopTheLook', 'Shop the Look')}</DrawerTitle>
              <DrawerClose>
                <X className='size-7 stroke-[1.5px]' />
              </DrawerClose>
              <DrawerDescription className='sr-only'>{translate('shopTheLook', 'Shop the Look')}</DrawerDescription>
            </DrawerHeader>
            <div className='flex h-full flex-col overflow-scroll p-4'>
              {products.map((product, i) => {
                const isCurrentCard = currentCard === i
                return (
                  <CollapseContainer key={i} collapse={!noCurrentCard && !isCurrentCard} className='min-w-80'>
                    <div className={cn('grid transition-opacity duration-500', noCurrentCard || isCurrentCard ? '' : 'opacity-0')}>
                      <MiniProductCard key={i} id={i} product={product} setCurrentCard={setCurrentCard} currentCard={currentCard} />
                      {i < products.length - 1 && (
                        <CollapseContainer collapse={!noCurrentCard}>
                          <Separator className='my-4' />
                        </CollapseContainer>
                      )}
                    </div>
                  </CollapseContainer>
                )
              })}
            </div>
          </DrawerContent>
        </Drawer>
      ) : (
        <Sheet open={open} onOpenChange={closeShopTheLook}>
          <SheetContent side='right' className='h-[100dvh] pb-0 data-[state=closed]:duration-200 data-[state=open]:duration-300'>
            <div className='relative flex h-full flex-col overflow-scroll scrollbar-hide'>
              <SheetHeader>
                <SheetTitle className='pb-7 text-left'>{translate('shopTheLook', 'Shop the Look')}</SheetTitle>
                <SheetDescription className='sr-only'>{translate('shopTheLook', 'Shop the Look')}</SheetDescription>
              </SheetHeader>
              <div className='flex flex-col gap-4 md:grid md:grid-cols-2 md:gap-x-2 md:gap-y-6'>
                {products.map((product, i) => {
                  const alreadyOnThisProductPage = asPath?.split('/')[2] === product?.slug.current
                  return (
                    <ProductCard
                      key={i}
                      index={i}
                      product={product}
                      quickAddToCart={<ProductCard.QuickAddToCart addToCartEffect='toast' />}
                      wishList={<ProductCard.WishListButton />}
                      image={<ProductCard.HoverImage imageSizes='(max-width: 640px) 90vw, (max-width: 768px) 30rem, 15rem' />}
                      badge={<ProductCard.Badge />}
                      info={<ProductCard.Info />}
                      action={
                        alreadyOnThisProductPage ? (
                          <ProductCard.Button
                            onClick={() => {
                              closeShopTheLook()
                            }}
                          />
                        ) : (
                          <ProductCard.Link />
                        )
                      }
                    />
                  )
                })}
              </div>
            </div>
          </SheetContent>
        </Sheet>
      )}
    </>
  )
}

export default ShopTheLookDrawer
