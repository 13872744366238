import { createContext, ReactNode, useContext } from 'react'

type RecommendationType = 'trending' | 'frequently_bought_together'

interface RecommendationsContextType {
  type: RecommendationType | null
}

const RecommendationsContext = createContext<RecommendationsContextType>({
  type: null,
})

export const RecommendationsProvider = ({ children, type }: { children: ReactNode; type: RecommendationType }) => {
  return <RecommendationsContext.Provider value={{ type }}>{children}</RecommendationsContext.Provider>
}

export const useRecommendations = () => useContext(RecommendationsContext)
