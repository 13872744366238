import { FC } from 'react'

import { cn } from '@ui/lib/utils'

import MisterSkeletonLoader from './MisterSkeletonLoader'

type Props = {
  className?: string
}

const SearchFiltersSkeleton: FC<Props> = ({ className }) => {
  return (
    <aside className={cn('flex w-full flex-col gap-4 bg-white lg:pr-8', className)}>
      <div className='flex h-full flex-col divide-y divide-brand-blue-pale [&>*]:py-8'>
        {/* Search Stats Skeleton */}
        <div className='flex flex-row items-center gap-2 !pt-0'>
          <MisterSkeletonLoader className='h-10 w-32' /> {/* For "X items" text */}
        </div>

        {/* Sorting Skeleton */}
        <div className='flex flex-col gap-6 !border-t-0 !pt-0'>
          <MisterSkeletonLoader className='h-6 w-24' /> {/* Sort by title */}
          <div className='flex flex-col gap-[22px]'>
            {[...Array(5)].map((_, i) => (
              <div key={i} className='flex items-center gap-2'>
                <MisterSkeletonLoader className='h-4 w-4 rounded-full' />
                <MisterSkeletonLoader className='h-4 w-32' />
              </div>
            ))}
          </div>
        </div>

        {/* Color Filter Skeleton */}
        <div className='flex flex-col gap-6'>
          <MisterSkeletonLoader className='h-6 w-24' /> {/* Color title */}
          <div className='flex flex-row flex-wrap gap-2'>
            {[...Array(8)].map((_, i) => (
              <div key={i} className='flex flex-col items-center gap-1 rounded-md'>
                <MisterSkeletonLoader className='h-[72px] w-[64px]' />
              </div>
            ))}
          </div>
        </div>

        {/* Size Filter Skeleton */}
        <div className='flex flex-col gap-6'>
          <MisterSkeletonLoader className='h-6 w-24' /> {/* Size title */}
          <div className='flex flex-row flex-wrap gap-2'>
            {[...Array(10)].map((_, i) => (
              <MisterSkeletonLoader key={i} className='h-8 w-11 rounded-md' /> /* Size buttons */
            ))}
          </div>
        </div>

        {/* Price Filter Skeleton */}
        <div className='flex flex-col gap-6'>
          <MisterSkeletonLoader className='h-6 w-24' /> {/* Price title */}
          <div className='flex w-full flex-col gap-4'>
            <MisterSkeletonLoader className='h-5 w-full' /> {/* Price slider */}
            <div className='flex flex-row justify-between'>
              <MisterSkeletonLoader className='h-10 w-14' /> {/* Min price input */}
              <MisterSkeletonLoader className='h-10 w-14' /> {/* Max price input */}
            </div>
          </div>
        </div>

        {/* Default Filter Groups Skeleton */}
        {[...Array(2)].map((_, i) => (
          <div key={i} className='flex flex-col gap-6'>
            <MisterSkeletonLoader className='h-6 w-32' /> {/* Filter group title */}
            <div className='flex flex-col gap-[22px]'>
              {[...Array(5)].map((_, j) => (
                <div key={j} className='flex items-center gap-2'>
                  <MisterSkeletonLoader className='h-4 w-4 rounded-sm' />
                  <MisterSkeletonLoader className='h-4 w-40' />
                </div>
              ))}
              <MisterSkeletonLoader className='h-4 w-20' /> {/* Show more text */}
            </div>
          </div>
        ))}
      </div>
    </aside>
  )
}

export default SearchFiltersSkeleton
