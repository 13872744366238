import { FC } from 'react'

import { SectionFilterConfigurationData } from 'data-access/sanity/fragments/sections/sectionFilterConfiguration.fragment'
import { cn } from '@ui/lib/utils'

import { useTranslations } from '../../../../contexts/Globals.context'
import SearchSorting from '../SearchSorting'
import SearchStats from '../SearchStats'
import SearchFilterGroup from './SearchFilterGroup'

type Props = {
  config: SectionFilterConfigurationData
  locale: string
  className?: string
}

const SearchFilters: FC<Props> = ({ config, locale, className }) => {
  const translate = useTranslations()

  return (
    <aside className={cn(className, 'flex w-full flex-col gap-4 bg-white')}>
      <div className='flex h-full flex-col divide-y divide-brand-blue-pale [&>*]:py-8'>
        {/* Basis search stats */}
        <SearchStats className='!pt-0' />

        {/* Search Sorting */}
        {config.enableSorting && <SearchSorting className='!border-t-0 !pt-0' locale={locale} items={config.sortingOptions} title={translate('sortBy', 'Sort by')} />}

        {/* The actual search filters */}
        {config.enableFilters &&
          config.filterOptions.map(({ label, value, operator }) => (
            <SearchFilterGroup key={`search-filter-group-${value}`} categoryName={value} operator={operator} attribute={value} categoryTitle={label} />
          ))}
      </div>
    </aside>
  )
}

export default SearchFilters
