import { ProductCardProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'

const useProductImage = (product: ProductCardProduct) => {
  const modelShotFirst = product.primaryCollection?.productType === 'tops'

  if (!modelShotFirst || product.images.length === 1) {
    return product.images
  }

  const [packShot, modelShot] = product.images

  return [modelShot, packShot]
}

export default useProductImage
