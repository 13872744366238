import { FunctionComponent } from 'react'

import { RichText as RichTextType } from 'data-access/sanity/fragments/components/richText.fragment'
import { cn } from '@ui/lib/utils'

import RichText from '../rich-text/rich-text'

interface Props {
  title: string
  description: RichTextType
  className?: string
  index?: number
}

const MisterCareDescription: FunctionComponent<Props> = ({ title, description, className, index }) => {
  return (
    <div key={index} className={cn('flex flex-col', className)}>
      <span className='text-body-lg font-semibold capitalize'>{title}</span>
      <RichText data={description} />
    </div>
  )
}

export default MisterCareDescription
