import { FunctionComponent } from 'react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { SectionBannerCarouselData } from 'data-access/sanity/fragments/sections/sectionBannerCarousel.fragment'

import SectionBannerCta from '../section-banner-cta/section-banner-cta'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

interface Props {
  data: SectionBannerCarouselData
  index: number
}

const SectionBannerCarousel: FunctionComponent<Props> = ({ data, index }) => {
  const Element = index === 0 ? 'header' : 'section'

  if (data.slides.length === 0) {
    return null
  }

  return (
    <Element className='w-full bg-brand-grey text-brand-grey'>
      <Swiper
        threshold={5}
        touchAngle={25}
        slidesPerView={1}
        navigation={{
          enabled: true,
        }}
        pagination={{
          bulletClass: 'swiper-pagination-bullet !bg-white',
        }}
        modules={[Navigation, Autoplay, Pagination]}
        loop={true}
        autoplay={{ delay: data?.delay || 6000 }}
        initialSlide={0}
        speed={200}
        breakpoints={{
          0: {
            navigation: {
              enabled: false,
            },
            pagination: {
              enabled: true,
            },
          },
          1024: {
            navigation: {
              enabled: true,
            },
            pagination: {
              enabled: false,
            },
          },
        }}
      >
        {data.slides
          .filter((x) => x._id)
          .map((slide, i) => (
            <SwiperSlide key={slide._id}>
              <SectionBannerCta index={index} data={slide} container='section' priority={index === 0 && i === 0} fixedAspectRatio={true} />
            </SwiperSlide>
          ))}
      </Swiper>
    </Element>
  )
}

export default SectionBannerCarousel
