import { FunctionComponent } from 'react'
import { PlusIcon } from 'lucide-react'
import { useHits } from 'react-instantsearch'

import { AlgoliaArticleHit } from 'data-access/algolia/types'
import { Button } from '@ui/components/ui/button'

import { useAlgoliaSearch } from 'src/contexts/AlgoliaSearch.context'
import { useTranslations } from 'src/contexts/Globals.context'
import { useUI } from 'src/contexts/UI.context'
import { MisterArticleResult } from './MisterArticleResult'
import { ResultListTitle } from './ResultListTitle'

type Props = {
  items: AlgoliaArticleHit[]
}

export const ArticleResultsList: FunctionComponent<Props> = ({ items }) => {
  const { limitArticlesBy, setLimitArticlesBy } = useAlgoliaSearch()
  const { sendEvent } = useHits<AlgoliaArticleHit>()
  const translate = useTranslations()
  const articleCount = items.length
  const { hideSearchUI } = useUI()

  if (articleCount === 0) {
    return null
  }

  const hasMoreArticles = articleCount > limitArticlesBy

  const handleShowMore = () => {
    setLimitArticlesBy(limitArticlesBy * 2)
  }

  return (
    <div className='flex flex-col gap-y-6'>
      <ResultListTitle title={translate('searchArticleResultsTitle', 'Articles')} count={articleCount} />

      <ol className={'grid w-full grid-cols-1 gap-4 lg:grid-cols-4 lg:gap-3'}>
        {items.slice(0, limitArticlesBy).map((item) => (
          <MisterArticleResult
            onClick={() => {
              sendEvent('click', item, 'Search Article Clicked')
              hideSearchUI()
            }}
            key={`article-result-${item.slug}`}
            item={item}
          />
        ))}
      </ol>
      {hasMoreArticles && (
        <div className='col-span-2 mx-auto w-fit'>
          <Button hover='expandIcon' Icon={PlusIcon} variant='outline' onClick={handleShowMore}>
            {translate('showMore', 'Show more')}
          </Button>
        </div>
      )}
    </div>
  )
}
