'use client'

import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@ui/lib/utils'

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef<React.ElementRef<typeof TabsPrimitive.List>, React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>>(({ className, ...props }, ref) => (
  <TabsPrimitive.List ref={ref} className={cn('inline-flex w-full', className)} {...props} />
))
TabsList.displayName = TabsPrimitive.List.displayName

// Define the variants using cva
const tabsVariants = cva('group relative w-full min-w-20 rounded-t-md px-2 py-4 text-body-md-bold font-bold outline-none transition-all', {
  variants: {
    variant: {
      default: [
        'before:absolute before:bottom-0 before:left-1/2 before:h-0.5 before:w-full before:-translate-x-1/2 before:bg-brand-blue-light before:content-[""]',
        'after:absolute after:bottom-0 after:left-1/2 after:h-0.5 after:w-0 after:-translate-x-1/2 after:bg-brand-blue after:transition-all after:duration-200 after:content-[""]',
        'hover:after:w-full',
        'data-[state=active]:bg-brand-blue-pale after:data-[state=active]:w-full',
      ],
      alternative: [
        'h-full p-4 text-body-xl-bold !font-normal text-[#6E7482] data-[state=active]:!font-semibold data-[state=active]:text-black md:p-6',
        'before:absolute before:bottom-0 before:left-1/2 before:h-0.5 before:w-full before:-translate-x-1/2 before:bg-brand-blue-light before:content-[""]',
        'after:absolute after:bottom-0 after:left-1/2 after:h-0.5 after:w-0 after:-translate-x-1/2 after:bg-brand-blue after:transition-all after:duration-200 after:content-[""]',
        'hover:after:w-full',
        'after:data-[state=active]:w-full',
      ],
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

interface TabsTriggerProps extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>, VariantProps<typeof tabsVariants> {}

const TabsTrigger = React.forwardRef<React.ElementRef<typeof TabsPrimitive.Trigger>, TabsTriggerProps>(({ className, variant = 'default', ...props }, ref) => (
  <TabsPrimitive.Trigger ref={ref} className={cn(tabsVariants({ variant }), className)} {...props} />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<React.ElementRef<typeof TabsPrimitive.Content>, React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>>(
  ({ className, ...props }, ref) => <TabsPrimitive.Content ref={ref} className={cn(className)} {...props} />,
)
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsContent, TabsList, TabsTrigger }
