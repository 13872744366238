import { FunctionComponent } from 'react'
import { useLocalStorage } from 'react-use'

import { Link } from 'data-access/sanity/fragments/components/link.fragment'
import { Media } from 'data-access/sanity/fragments/components/media.fragment'
import { Article } from 'data-access/sanity/fragments/components/relatedArticles.fragments'
import { SectionMediaData } from 'data-access/sanity/fragments/sections/sectionMedia.fragment'
import { SectionTextData } from 'data-access/sanity/fragments/sections/sectionText.fragment'
import { Slug } from 'data-access/sanity/types'

import ArticleContent from 'src/components/pages/blog/ArticleContent'
import RelatedArticles from 'src/components/pages/blog/RelatedArticles'
import MisterMedia from 'src/components/shared/media/MisterMedia'
import MisterContainer from 'src/components/shared/MisterContainer'
import MisterIcon from 'src/components/shared/MisterIcon'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { useTranslations } from 'src/contexts/Globals.context'

interface SectionArticleProps {
  data: {
    _type: 'pagePressRelease' | 'pageArticle'
    sections: (SectionMediaData | SectionTextData)[]
    slug: Slug
    dateCreated: string
    relatedBlogOverview: Link
    relatedArticles: Article[]
    articleMainImage: Media
    title: string
  }
}

const SectionArticle: FunctionComponent<SectionArticleProps> = ({ data }) => {
  const translate = useTranslations()
  const { sections, slug, dateCreated, relatedArticles, articleMainImage, title, _type } = data || {}
  const pageType = _type === 'pageArticle' ? 'blog' : 'press-releases'
  const [lastVisitedPageNumber] = useLocalStorage<number>(`${pageType}|lastVisitedPageNumber`)

  return (
    <>
      <article itemScope itemType='https://schema.org/BlogPosting'>
        {dateCreated && <meta itemProp='datePublished' content={dateCreated} />}
        {title && <meta itemProp='headline' content={title} />}
        <MisterContainer dataLabel='section-article-header' as='header' type='small'>
          <MisterMedia
            sectionIndex={0}
            media={articleMainImage}
            className='my-8'
            mobileSizes='(max-width: 768px) 95vw, 46rem'
            desktopSizes='(max-width: 768px) 95vw, 46rem'
            itemProp='image'
          />
          <div className='mb-14 text-btn-md'>
            <MisterSiteLink
              suppressHydrationWarning
              link={{
                page: {
                  _type: _type,
                  slug: { current: `${lastVisitedPageNumber || 1}` },
                },
              }}
              className='flex items-center'
              disabled={undefined}
              onMouseEnter={undefined}
            >
              <MisterIcon type='chevron-left' className='mr-2 size-4' /> {translate('backToOverview', 'Back to overview')}
            </MisterSiteLink>
          </div>
        </MisterContainer>
        <ArticleContent slug={slug} dateCreated={dateCreated} sections={sections} articleMainImage={articleMainImage} />
      </article>
      <RelatedArticles relatedArticles={relatedArticles} />
    </>
  )
}

export default SectionArticle
