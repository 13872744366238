import { FunctionComponent } from 'react'

import { cn } from '@ui/lib/utils'

import MisterProductCardImage from 'src/components/shared/productCard/MisterProductCardImage'
import { productTitle } from 'src/utils/product.util'
import { useProductCard } from '../ProductCard.context'
import useProductImage from './useProductImage'

const HoverProductCardImage: FunctionComponent<{ imageSizes?: string; className?: string }> = ({ imageSizes = '100vw', className }) => {
  const { index, product, isBeingDragged } = useProductCard('Hover product card image')
  const [productCardImage, hoverImage] = useProductImage(product)

  const { title, category: subtitle } = productTitle(product.productTitle, true)
  const linkTitle = subtitle ? `${title} - ${subtitle}` : title

  if (!productCardImage) {
    return null
  }

  return (
    <div className={cn('grid grid-cols-1 grid-rows-1', !product.isAvailable && 'opacity-50')}>
      <div
        className={cn(
          'col-span-1 col-start-1 row-span-1 row-start-1',
          hoverImage && 'transition-[opacity,transform] duration-200 ease-out md:group-hover/product:scale-[94%] md:group-hover/product:opacity-0',
          hoverImage && isBeingDragged && 'group-hover/product:scale-[94%] group-hover/product:opacity-0',
          className,
        )}
      >
        <MisterProductCardImage image={productCardImage} priority={index === 0} alt={linkTitle} sizes={imageSizes} />
      </div>
      <div
        className={cn(
          'col-span-1 col-start-1 row-span-1 row-start-1 scale-[106%] opacity-0 transition-[opacity,transform] duration-200 ease-out md:group-hover/product:scale-100 md:group-hover/product:opacity-100',
          hoverImage && isBeingDragged && 'group-hover/product:scale-100 group-hover/product:opacity-100',
        )}
      >
        {hoverImage && <MisterProductCardImage image={hoverImage} priority={false} alt={linkTitle} sizes={imageSizes} />}
      </div>
    </div>
  )
}

export default HoverProductCardImage
