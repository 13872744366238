import { useState } from 'react'
import { useRouter } from 'next/router'

import { ShopifyCustomerAccessToken, ShopifyCustomerUpdateOptions, ShopifyMinimalCustomer } from 'data-access/shopify/types'
import { getErrorMessage } from 'utilities/errors/errorMessage'

import { post } from '../../utils/request.util'
import useRouterRefresh from '../utils/useRouterRefresh'
import useCustomer from './useCustomer'

interface State {
  response: ShopifyMinimalCustomer | null
  loading: boolean
  error: null | string
}

export const useUpdateAccountInfo = () => {
  const [state, setState] = useState<State>({
    response: null,
    loading: false,
    error: null,
  })

  const { locale } = useRouter()
  const { mutateCustomer } = useCustomer()
  const update = useRouterRefresh()

  const updateAccountInfo = async (customerAccessToken?: ShopifyCustomerAccessToken, customer?: ShopifyCustomerUpdateOptions) => {
    if (!customerAccessToken || !customer) {
      setState({
        response: null,
        loading: false,
        error: 'Missing customer or access token.',
      })
      return
    }

    setState({ response: null, loading: true, error: null })

    try {
      await mutateCustomer(
        post('/api/shopify/account/update-account-info', {
          customerAccessToken,
          customer,
          locale,
        }).then((res) => {
          setState({
            response: res as ShopifyMinimalCustomer,
            loading: false,
            error: null,
          })
          update()
        }),
      )
    } catch (error) {
      setState({
        response: null,
        loading: false,
        error: getErrorMessage(error) || 'Update account info failed.',
      })
    }
  }

  return { updateAccountInfo, state }
}

export default useUpdateAccountInfo
