import { createContext, Dispatch, FunctionComponent, PropsWithChildren, SetStateAction, useCallback, useContext, useState, type JSX } from 'react'

import { ActiveVariant } from 'src/components/layout/header/Header'
import TopLevelMobileMenu from 'src/components/layout/header/MobileMenu/TopLevelMobileMenu'
import { Navigation } from 'src/domain/navigation.domain'
import { useUI } from './UI.context'

type Direction = 'forward' | 'backward'
type MenuType = 'top' | 'categories' | 'styles' | 'secondary' | 'countryPicker'

interface MobileMenuContextType {
  data: Navigation
  currentMenu: { type: MenuType; component: JSX.Element }
  setCurrentMenu: Dispatch<SetStateAction<{ type: MenuType; component: JSX.Element }>>
  nextMenu: { type: MenuType; component: JSX.Element } | null
  setNextMenu: Dispatch<SetStateAction<{ type: MenuType; component: JSX.Element } | null>>
  currentMainMenuItem: number | null
  setCurrentMainMenuItem: Dispatch<SetStateAction<number | null>>
  currentCategory: number | null
  setCurrentCategory: Dispatch<SetStateAction<number | null>>
  currentSecondaryMenuItem: number | null
  setCurrentSecondaryMenuItem: Dispatch<SetStateAction<number | null>>
  handleMobileMenuClose: () => void
  direction: Direction | null
  setDirection: Dispatch<SetStateAction<Direction | null>>
  animating: boolean
  setAnimating: Dispatch<SetStateAction<boolean>>
  activeVariant: ActiveVariant
}

export const MobileMenuContext = createContext<MobileMenuContextType | null>(null)

export const MobileMenuContextProvider: FunctionComponent<PropsWithChildren<{ data: Navigation; activeVariant: ActiveVariant }>> = ({ data, activeVariant, children }) => {
  const { displayMobileMenu, closeMobileMenu, openMobileMenu } = useUI()

  const [currentMenu, setCurrentMenu] = useState<{ type: MenuType; component: JSX.Element }>({ type: 'top', component: <TopLevelMobileMenu /> })
  const [nextMenu, setNextMenu] = useState<{ type: MenuType; component: JSX.Element } | null>(null)

  const [currentMainMenuItem, setCurrentMainMenuItem] = useState<number | null>(null)
  const [currentCategory, setCurrentCategory] = useState<number | null>(null)
  const [currentSecondaryMenuItem, setCurrentSecondaryMenuItem] = useState<number | null>(null)

  const [direction, setDirection] = useState<Direction | null>(null)
  const [animating, setAnimating] = useState(false)

  const handleMobileMenuClose = useCallback(() => {
    displayMobileMenu ? closeMobileMenu() : openMobileMenu()
    setCurrentMainMenuItem(null)
    setCurrentCategory(null)
    setCurrentMenu({ type: 'top', component: <TopLevelMobileMenu /> })
  }, [closeMobileMenu, displayMobileMenu, openMobileMenu])

  return (
    <MobileMenuContext.Provider
      value={{
        data,
        currentMenu,
        setCurrentMenu,
        nextMenu,
        setNextMenu,
        currentMainMenuItem,
        setCurrentMainMenuItem,
        currentCategory,
        setCurrentCategory,
        handleMobileMenuClose,
        direction,
        setDirection,
        animating,
        setAnimating,
        currentSecondaryMenuItem,
        setCurrentSecondaryMenuItem,
        activeVariant,
      }}
    >
      {children}
    </MobileMenuContext.Provider>
  )
}

export const useMobileMenu = () => {
  const state = useContext(MobileMenuContext)

  if (!state) throw new Error('useMobileMenu must be used within MobileMenuProvider')

  const { data, animating, setAnimating, setDirection, nextMenu, setNextMenu, setCurrentMenu, activeVariant, ...rest } = state

  const handleMenuChange = (nextMenu: { type: MenuType; component: JSX.Element }, dir: Direction) => {
    if (animating) return
    setDirection(dir)
    setAnimating(true)
    setNextMenu(nextMenu)
  }

  const resetMenus = () => {
    setAnimating(false)
    if (nextMenu) {
      setCurrentMenu(nextMenu)
      setNextMenu(null)
      setDirection(null)
    }
  }

  return {
    data,
    animating,
    setAnimating,
    setDirection,
    nextMenu,
    setNextMenu,
    setCurrentMenu,
    handleMenuChange,
    resetMenus,
    activeVariant,
    ...rest,
  }
}
