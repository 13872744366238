import { FunctionComponent } from 'react'
import { PlusIcon } from 'lucide-react'
import { useHits } from 'react-instantsearch'

import { AlgoliaProductHit } from 'data-access/algolia/types'
import { Button } from '@ui/components/ui/button'

import { useAlgoliaSearch } from 'src/contexts/AlgoliaSearch.context'
import { useTranslations } from 'src/contexts/Globals.context'
import { useUI } from 'src/contexts/UI.context'
import useCart from 'src/hooks/shop/useCart'
import MisterSearchProductCard from '../productCard/MisterSearchProductCard'
import { ResultListTitle } from './ResultListTitle'

type Props = {
  items: AlgoliaProductHit[]
}

export const ProductResultsList: FunctionComponent<Props> = ({ items }) => {
  const { results } = useHits()

  const { limitProductsBy, setLimitProductsBy } = useAlgoliaSearch()
  const translate = useTranslations()
  const productCount = items.length
  const { sendEvent } = useHits<AlgoliaProductHit>()
  const { hideSearchUI } = useUI()
  const { currencyCode } = useCart()

  if (productCount === 0) {
    return null
  }

  const hasMoreProducts = productCount > limitProductsBy

  const handleShowMore = () => {
    setLimitProductsBy(limitProductsBy * 2)
  }

  return (
    <div className='col-span-full flex flex-col gap-6'>
      <ResultListTitle title={translate('searchProductResultsTitle', 'Products')} count={productCount} />
      <ol className={'grid w-full auto-rows-fr grid-cols-2 gap-4 lg:grid-cols-4 lg:gap-3'}>
        {items.slice(0, limitProductsBy).map((item) => (
          <MisterSearchProductCard
            onClick={() => {
              sendEvent('click', item, 'Search Product Clicked')
              hideSearchUI()
            }}
            onAddToCart={() => {
              sendEvent('conversion', item, 'Search Add to Cart', {
                eventSubtype: 'addToCart',
                currency: currencyCode,
                objectData: [
                  {
                    quantity: 1,
                    price: item?.price || 0,
                    ...(results?.queryID && { queryID: results.queryID }),
                  },
                ],
              })
            }}
            key={`product-search-result-${item.objectID}`}
            hit={item}
          />
        ))}
      </ol>

      {hasMoreProducts && (
        <div className='col-span-2 mx-auto w-fit'>
          <Button hover='expandIcon' Icon={PlusIcon} variant='outline' onClick={handleShowMore}>
            {translate('showMore', 'Show more')}
          </Button>
        </div>
      )}
    </div>
  )
}
