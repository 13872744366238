import { Fragment } from 'react'

import { useAlgoliaSearch } from 'src/contexts/AlgoliaSearch.context'
import { useTranslations } from 'src/contexts/Globals.context'
import MisterSearchTrendingItems from './MisterSearchTrendingItems'

/**
 * Funky code to render the search term in bold inside of the localized text
 */
export const MisterNoResults = () => {
  const { searchTerm } = useAlgoliaSearch()
  const translate = useTranslations()

  return (
    <div className='flex flex-col gap-4 lg:gap-y-8'>
      <p className='lg:text-center'>
        {translate('noResultsMessage', "Sorry, we can't find what you are looking for. Please try using another keyword or one of our suggestions.")
          .split('{searchTerm}')
          .map((part: string, index: number, array: string[]) => (
            <Fragment key={`no-results-part-${index}`}>
              {part}
              {index < array.length - 1 && <span className='font-bold'>{searchTerm}</span>}
            </Fragment>
          ))}
      </p>
      <MisterSearchTrendingItems title={translate('suggestedProductsTitle', 'Suggested Products')} />
    </div>
  )
}
