import { useState } from 'react'
import { useRouter } from 'next/router'

import { getErrorMessage } from 'utilities/errors/errorMessage'

import { post } from '../../utils/request.util'
import useCustomer from './useCustomer'

interface State {
  response: null | boolean
  loading: boolean
  error: null | string
}

export const useResetPassword = (): [(passwordField1?: string, passwordField2?: string, id?: string | null, resetToken?: string | null) => Promise<void>, State] => {
  const { locale } = useRouter()

  const [state, setState] = useState<State>({
    response: null,
    loading: false,
    error: null,
  })

  const { mutateCustomer } = useCustomer('', true)

  const resetPassword = async (passwordField1?: string, passwordField2?: string, id?: string | null, resetToken?: string | null) => {
    setState({ response: null, loading: true, error: null })

    if (passwordField1 !== passwordField2) {
      setState({
        response: null,
        loading: false,
        error: 'Passwords do not match.',
      })
    } else if (passwordField1 && resetToken && id) {
      try {
        await mutateCustomer(
          post('/api/shopify/account/reset-password', {
            id,
            locale,
            resetToken,
            password: passwordField1,
          }),
        )

        setState({
          response: true,
          loading: false,
          error: null,
        })
      } catch (error: unknown) {
        setState({
          response: null,
          loading: false,
          error: getErrorMessage(error) || 'Something went wrong.',
        })
      }
    } else {
      setState({
        response: null,
        loading: false,
        error: 'The password, reset token, or customer id is missing.',
      })
    }
  }

  return [resetPassword, state]
}

export default useResetPassword
