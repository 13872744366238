import { useState } from 'react'
import { useRouter } from 'next/router'

import { getErrorMessage } from 'utilities/errors/errorMessage'

import { post } from '../../utils/request.util'
import useCustomer from './useCustomer'

interface State {
  response: null | Record<string, any>
  loading: boolean
  error: null | string
}

export const useRegisterCustomer = (): [(email?: string, passwordField1?: string, passwordField2?: string, firstName?: string, lastName?: string) => Promise<void>, State] => {
  const { locale } = useRouter()
  const { mutateCustomer } = useCustomer('/account', true)
  const [state, setState] = useState<State>({ response: null, loading: false, error: null })

  const registerCustomer = async (email?: string, passwordField1?: string, passwordField2?: string, firstName?: string, lastName?: string): Promise<void> => {
    setState({ response: null, loading: true, error: null })

    if (passwordField1 !== passwordField2) {
      setState({ response: null, loading: false, error: 'Passwords do not match' })

      return
    }

    try {
      const response = await mutateCustomer(
        post('/api/shopify/account/register', {
          email,
          password: passwordField1,
          firstName,
          lastName,
          locale,
        }),
      )

      setState({
        response,
        loading: false,
        error: response.error || null,
      })
    } catch (error) {
      console.error('useRegisterCustomer failed: ', getErrorMessage(error))

      setState({
        response: null,
        loading: false,
        error: getErrorMessage(error) || 'Register customer failed',
      })
    }
  }

  return [registerCustomer, state]
}

export default useRegisterCustomer
