import { forwardRef, FunctionComponent, RefObject, useEffect, useRef, useState } from 'react'
import { useToggle } from 'react-use'

import { cn } from 'ui/lib/utils'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@ui/components/ui/dropdown-menu'

import MisterIcon from '../MisterIcon'

export interface Option {
  label?: string
  value: string
}

interface DropdownProps {
  id?: string
  label?: string
  name?: string
  wrapperClassName?: string
  value?: string
  onChangeHandler?: (value: string) => void
  options?: Option[]
  primaryOptions?: Option[]
  inForm?: boolean
  placeholderText?: string
}

interface DropDownItemProps {
  option: Option
  onSelect: (option: Option) => void
  ref?: RefObject<HTMLDivElement>
}

const DropDownItem = forwardRef<HTMLDivElement, DropDownItemProps>(({ option, onSelect }, ref) => (
  <DropdownMenuItem className='mb-2 cursor-pointer' onClick={() => onSelect(option)} textValue={option.value} ref={ref}>
    {option.label || option.value}
  </DropdownMenuItem>
))

DropDownItem.displayName = 'DropDownItem'

interface ScrollGradientProps {
  showGradient: boolean
  isScrollable: boolean
}

const ScrollGradient = ({ showGradient }: ScrollGradientProps) => {
  return (
    <div className={cn('pointer-events-none transition-opacity duration-300', showGradient ? 'opacity-100' : 'opacity-0')}>
      <div className='absolute inset-0 bottom-0 left-0 right-0 h-full bg-gradient-to-t from-white via-white/10 to-transparent' />
      <MisterIcon
        type='dropdown-arrow'
        className={cn('absolute bottom-1 right-1/2 size-4 -translate-x-1/2 rotate-180 transition-transform', showGradient ? 'translate-y-0' : 'translate-y-3')}
      />
    </div>
  )
}

const MisterDropdown: FunctionComponent<DropdownProps> = ({
  id,
  label,
  name,
  wrapperClassName,
  value,
  onChangeHandler,
  options = [],
  primaryOptions,
  inForm = false,
  placeholderText,
}) => {
  const [open, setOpen] = useToggle(false)
  const [showGradient, setShowGradient] = useState(true)
  const [isScrollable, setIsScrollable] = useState(true)
  const contentRef = useRef<HTMLDivElement>(null)

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement
    const isAtTop = target.scrollTop === 0
    setShowGradient(isAtTop)
  }

  useEffect(() => {
    if (open && contentRef.current) {
      const element = contentRef.current
      setShowGradient(element.scrollHeight > element.clientHeight && element.scrollTop > 0)
    }
  }, [open])

  const handleSelect = (option: Option) => {
    onChangeHandler?.(option.value)
    setOpen(false)
  }

  useEffect(() => {
    const allOptions = primaryOptions ? [...options, ...primaryOptions] : [...options]
    setIsScrollable(allOptions.length > 7)
  }, [options, primaryOptions])

  if (!options.length) return null

  const valueLabel = options.find((option) => option.value === value)?.label || value

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <div className={cn(wrapperClassName, 'flex w-full flex-col')}>
        {label && <div className='pb-1 text-body-lg transition-transform empty:hidden'>{label}</div>}

        <DropdownMenuTrigger
          id={id}
          name={name}
          className={cn('relative rounded-md border border-brand-blue-light bg-white p-4', 'text-start text-body-md-bold', inForm && 'mb-5')}
          value={value}
        >
          {valueLabel || placeholderText}
          <MisterIcon
            type='dropdown-arrow'
            className={cn('pointer-events-none absolute right-3 top-1/2 size-4 -translate-y-1/2', 'ml-2 font-extrabold duration-150 ease-in', open && 'scale-y-[-1]')}
          />
        </DropdownMenuTrigger>
      </div>

      <DropdownMenuContent align='start' className='relative w-[var(--radix-dropdown-menu-trigger-width)] p-0 text-body-md-bold' sideOffset={5}>
        <div ref={contentRef} className='max-h-64 overflow-y-auto p-3' onScroll={handleScroll}>
          {primaryOptions && (
            <>
              {primaryOptions.map((option, i) => (
                <DropDownItem key={`primary-${option.value}-${i}`} option={option} onSelect={handleSelect} />
              ))}
              <DropdownMenuSeparator className='mb-2' />
            </>
          )}

          {options.map((option, i) => (
            <DropDownItem key={`option-${option.value}-${i}`} option={option} onSelect={handleSelect} />
          ))}
        </div>
        <ScrollGradient showGradient={showGradient} isScrollable={isScrollable} />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default MisterDropdown
