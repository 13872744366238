import { Dispatch, FunctionComponent, KeyboardEvent, SetStateAction, TransitionEvent, useEffect, useId, useState } from 'react'
import { LoaderCircle } from 'lucide-react'
import { useRouter } from 'next/router'
import { useDebounce } from 'react-use'

import { DEFAULT_LOCALE } from 'data-access/domain/constants'
import { cn } from 'ui/lib/utils'
import { Separator } from '@ui/components/ui/separator'
import { combineOptions, optionsToObject } from 'utilities/array/combineOptions'
import { getPriceValidUntil } from 'utilities/date/format/formatPriceValidUntil'
import { localeCurrencyMap } from 'utilities/string/format/price'

import { FocusTrapWrapper } from 'src/components/shared/focus/FocusTrapWrapper'
import MisterIcon from 'src/components/shared/MisterIcon'
import OptionIcon from 'src/components/shared/OptionIcon'
import { useTranslations } from 'src/contexts/Globals.context'
import { ProductWithEnrichedAvailability } from 'src/domain/productCard.domain'
import LowStockWarning from './LowStockWarning'

interface SplitOptionSelectProps {
  product: ProductWithEnrichedAvailability
  selectedVariant: ProductWithEnrichedAvailability['variants'][0] | null
  setSelectedVariant: Dispatch<SetStateAction<ProductWithEnrichedAvailability['variants'][0] | null>>
  loadingAddToCart: boolean
  showSplitOptionSelectButton?: boolean
  handleQuickAddToCart: () => void
  onDeactivate: () => void
  buttonTransitionEndEvent: TransitionEvent<HTMLButtonElement> | null
  buttonKeyDownEvent: KeyboardEvent<HTMLButtonElement> | null
  className?: string
  forceShowButton?: boolean
}

const SplitOptionSelect: FunctionComponent<SplitOptionSelectProps> = ({
  product,
  selectedVariant,
  setSelectedVariant,
  loadingAddToCart,
  handleQuickAddToCart,
  onDeactivate,
  buttonTransitionEndEvent,
  buttonKeyDownEvent,
  className,
  forceShowButton = false,
}) => {
  const { locale } = useRouter()
  const translate = useTranslations()
  const id = useId()

  const [showButton, setShowButton] = useState(forceShowButton)
  const [focusLock, setFocusLock] = useState(false)
  const [isFocusTrapEnabled, setIsFocusTrapEnabled] = useState(false)

  // We need to debounce the focus lock, because without it the focus trap is not working properly
  useDebounce(
    () => {
      setIsFocusTrapEnabled(focusLock)
    },
    200,
    [focusLock],
  )

  const options = combineOptions(product.variants.flatMap(({ options }) => options))

  // Get all available options
  const availableOptions = product.variants.filter(({ isAvailable }) => isAvailable).map(({ options }) => optionsToObject(options || []))

  const [selectedOptions, setSelectedOptions] = useState(product.variants.length === 1 ? availableOptions[0] : {})

  const handleSelectedOption = (optionValue: string, optionName: string) => {
    const newSelectedOptions = { ...selectedOptions, [optionName]: optionValue }
    const newSelectedVariant = product.variants.find(({ options }) => options.every(({ name, value }) => newSelectedOptions[name] === value)) || null

    setSelectedVariant(newSelectedVariant)
    setSelectedOptions(newSelectedOptions)
  }

  useEffect(() => {
    if (buttonTransitionEndEvent && buttonTransitionEndEvent.propertyName === 'width') {
      setShowButton(true)
    }
  }, [buttonTransitionEndEvent])

  useEffect(() => {
    if (buttonKeyDownEvent && buttonKeyDownEvent.key === 'Enter') {
      setFocusLock(true)
    }
  }, [buttonKeyDownEvent])

  return (
    <FocusTrapWrapper isFocusTrapEnabled={isFocusTrapEnabled} escapeDeactivates={true} returnFocusOnDeactivate={false} onDeactivate={onDeactivate}>
      <div className={cn('relative', className)}>
        <div className='mb-5 grid grid-cols-[auto_1fr] gap-2 py-1'>
          {options.map(({ name: optionName, values: optionValues }, sizeRowIndex) => {
            const { [optionName]: remove, ...otherSelectedOption } = selectedOptions || {}

            return (
              <fieldset key={`${product._id}-${optionName}-options`} className='contents'>
                <div className='col-span-2 grid grid-cols-subgrid'>
                  <div className='flex w-min select-none items-center justify-center gap-1'>
                    <OptionIcon aria-hidden className='size-6 lg:size-7' option={optionName} />
                    <span className='text-nowrap text-body-sm capitalize'>{translate(`option${optionName.replace(' ', '')}`, optionName)}</span>
                  </div>

                  <div className='flex flex-wrap justify-start gap-1 text-btn-sm font-semibold'>
                    {optionValues.map((optionValue, sizeColIndex) => {
                      const optionSelected = selectedOptions?.[optionName] === optionValue
                      const optionId = `${product._id}-${optionName}-${optionValue}-option-value-${id}`.toLowerCase()
                      const optionIsAvailable = availableOptions
                        .filter((option) => Object.keys(otherSelectedOption).length === 0 || Object.entries(otherSelectedOption).every(([key, v]) => option[key] === v))
                        .find((option) => option[optionName] === optionValue)

                      return (
                        <div className='relative flex flex-col items-center' data-testid='size-select' key={sizeColIndex}>
                          <input
                            id={optionId}
                            name={optionName}
                            onChange={(e) => handleSelectedOption(e.target.value, optionName)}
                            type='radio'
                            value={optionValue}
                            checked={optionSelected}
                            className='peer hidden'
                          />
                          <label
                            tabIndex={0}
                            role='button'
                            onKeyUp={(e) => {
                              e.preventDefault()
                              if (optionIsAvailable && e.key === 'Enter' && e.type === 'keyup') {
                                // @ts-ignore
                                handleSelectedOption(e.target.textContent || '', optionName)
                              }
                            }}
                            htmlFor={optionId}
                            className={cn(
                              'flex size-6 items-center justify-center whitespace-nowrap rounded-sm text-center text-body-sm tracking-tight transition-colors duration-100 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 active:ring-1 active:ring-brand-blue-action peer-disabled:cursor-default peer-disabled:text-brand-blue-dark-disabled sm:size-8 xl:size-7',
                              !optionIsAvailable && 'pointer-events-none text-brand-muted peer-checked:bg-brand-muted peer-checked:text-white',
                              optionSelected ? 'bg-brand-blue-light' : 'hover:bg-brand-beige',
                            )}
                          >
                            {optionValue}
                          </label>

                          <LowStockWarning product={product} options={options} sizeColIndex={sizeColIndex} sizeRowIndex={sizeRowIndex} selectedVariant={selectedVariant} />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </fieldset>
            )
          })}
        </div>
        <Separator />
        {showButton && (
          <button
            type='button'
            onClick={() => (selectedVariant ? handleQuickAddToCart() : null)}
            className={cn(
              'after:full absolute inset-x-0 bottom-0 inline-flex h-10 w-full items-center justify-center self-end whitespace-nowrap rounded-md bg-brand-blue-action text-body-sm font-medium text-white opacity-100 ring-offset-transparent transition-all duration-300 ease-out after:absolute after:-z-[1] after:h-10 after:w-full after:scale-100 after:rounded-md after:bg-brand-blue-action after:opacity-50 after:transition-all after:duration-1000 after:ease-out hover:after:scale-100 hover:after:opacity-100',
              !selectedVariant ? 'cursor-not-allowed bg-brand-blue-light' : 'bg-brand-blue-action text-white opacity-100 after:w-full after:bg-brand-blue-action',
            )}
          >
            <div className='flex items-center'>
              {loadingAddToCart ? <LoaderCircle className='size-4 animate-spin' /> : <MisterIcon type='cart' className='size-6' />}
              <span className='ml-3 text-white'>
                <meta itemProp='price' content={String(product.price)} />
                <meta itemProp='priceValidUntil' content={getPriceValidUntil()} />
                <meta itemProp='itemCondition' content='https://schema.org/NewCondition' />
                <meta itemProp='priceCurrency' content={localeCurrencyMap?.[locale || DEFAULT_LOCALE] || 'EUR'} />

                {selectedVariant ? translate('addToCartButtonText', 'Add to Cart') : translate('chooseSize', 'Choose a size')}
              </span>
            </div>
          </button>
        )}
      </div>
    </FocusTrapWrapper>
  )
}

export default SplitOptionSelect
