'use client'

import * as React from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'

import { cn } from '@ui/lib/utils'

import { Label } from './label'

const RadioGroup = React.forwardRef<React.ElementRef<typeof RadioGroupPrimitive.Root>, React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>>(
  ({ className, ...props }, ref) => {
    return <RadioGroupPrimitive.Root className={cn('grid gap-2', className)} {...props} ref={ref} />
  },
)
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

const RadioGroupItem = React.forwardRef<React.ElementRef<typeof RadioGroupPrimitive.Item>, React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>>(
  ({ className, ...props }, ref) => {
    return (
      <RadioGroupPrimitive.Item
        ref={ref}
        className={cn(
          'group peer relative aspect-square size-5 rounded-full border-[1.5px] border-primary text-primary ring-offset-transparent after:absolute after:left-1/2 after:top-1/2 after:size-3.5 after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-full hover:after:bg-brand-grey-dark focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:border-brand-muted disabled:after:hidden data-[state=checked]:hover:after:hidden',
          className,
        )}
        {...props}
      >
        <RadioGroupPrimitive.Indicator className='flex items-center justify-center'>
          <div className='size-3.5 rounded-full bg-current group-data-[state=checked]:group-disabled:bg-brand-muted' />
        </RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>
    )
  },
)
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

const RadioGroupBareItem = React.forwardRef<React.ElementRef<typeof RadioGroupPrimitive.Item>, React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>>(
  ({ className, children, ...props }, ref) => {
    return (
      <RadioGroupPrimitive.Item ref={ref} className={cn('group peer', className)} {...props}>
        {children}
      </RadioGroupPrimitive.Item>
    )
  },
)
RadioGroupBareItem.displayName = 'RadioGroupBareItem'

const RadioGroupLabel = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>>(
  ({ className, ...props }, ref) => {
    return <Label className={cn('cursor-pointer text-body-lg md:text-body-md', className)} {...props} ref={ref} />
  },
)
RadioGroupLabel.displayName = LabelPrimitive.Root.displayName

export { RadioGroup, RadioGroupItem, RadioGroupBareItem, RadioGroupLabel }
