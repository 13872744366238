import { FunctionComponent } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoaderCircle } from 'lucide-react'
import { useUpdateEffect } from 'react-use'
import { z } from 'zod'

import { SectionRegistrationData } from 'data-access/sanity/fragments/sections/sectionRegistration.fragment'
import { Button } from '@ui/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormMessage, useForm } from '@ui/components/ui/form'
import { InputFloatingLabel } from '@ui/components/ui/input-floating-label'
import { at } from 'utilities/array/arrayAt'

import { ElevarUserPropertiesLoginAndRegistration } from '@/types/telemetry'

import MisterAuthPageContainer from 'src/components/shared/account/MisterAuthPageContainer'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { useTranslations } from 'src/contexts/Globals.context'
import useRegisterCustomer from 'src/hooks/customer/useRegisterCustomer'
import { ELEVAR_DATA_LAYER_EVENTS, trackEvent } from '../../../utils/telemetry.util'

interface SectionRegistrationProps {
  data: SectionRegistrationData
}

const SectionRegistration: FunctionComponent<SectionRegistrationProps> = ({ data }) => {
  const {
    title,
    subtitle,
    backgroundImage,
    firstNameLabel,
    firstNameErrorMessage,
    lastNameLabel,
    lastNameErrorMessage,
    emailLabel,
    emailErrorMessage,
    passwordLabel,
    passwordErrorMessage,
    confirmPasswordLabel,
    passwordMatchErrorMessage,
    submitButtonText,
    alreadyHaveAccountLink,
  } = data
  const translate = useTranslations()
  const [registerCustomer, registerState] = useRegisterCustomer()

  const registrationFormSchema = z
    .object({
      firstName: z.string().min(1, { message: firstNameErrorMessage }),
      lastName: z.string().min(1, { message: lastNameErrorMessage }),
      email: z.string().email({ message: emailErrorMessage }),
      password: z.string().min(6, { message: passwordErrorMessage }),
      confirmPassword: z.string().min(6, { message: passwordErrorMessage }),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: passwordMatchErrorMessage,
      path: ['confirmPassword'], // Specify the field path for the error message
    })

  const form = useForm<z.infer<typeof registrationFormSchema>>({
    mode: 'onBlur',
    resolver: zodResolver(registrationFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
  })

  const onSubmit = async (values: z.infer<typeof registrationFormSchema>) => {
    const { firstName, lastName, email, password, confirmPassword } = values
    await registerCustomer(email, password, confirmPassword, firstName, lastName)
  }

  useUpdateEffect(() => {
    if (!registerState.loading && registerState.response && !registerState.error) {
      const payload: ElevarUserPropertiesLoginAndRegistration = {
        user_properties: {
          visitor_type: 'logged_in',
          customer_email: registerState?.response?.email || '',
          customer_first_name: registerState.response?.firstName || '',
          customer_last_name: registerState.response?.lastName || '',
          // Customer ID should match the one in generic user data layer
          // @see apps/store/src/contexts/Telemetry.context.tsx
          customer_id: String(at((registerState.response?.id || '').split('/'), -1) || ''),
        },
      }

      trackEvent(ELEVAR_DATA_LAYER_EVENTS.DL_SIGN_UP, payload)
    }
  }, [registerState])

  return (
    <MisterAuthPageContainer image={backgroundImage}>
      <div className='flex flex-col gap-9'>
        <div className='flex flex-col gap-2'>
          <h2 className='text-heading-3'>{title}</h2>
          {subtitle && <p className='text-body-md'>{subtitle}</p>}
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className='flex flex-col gap-y-3'>
            <div className='flex gap-3'>
              <FormField
                control={form.control}
                name='firstName'
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <InputFloatingLabel label={firstNameLabel} type='text' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='lastName'
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <InputFloatingLabel label={lastNameLabel} type='text' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputFloatingLabel label={emailLabel} type='email' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputFloatingLabel label={passwordLabel} type='password' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='confirmPassword'
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputFloatingLabel label={confirmPasswordLabel} type='password' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type='submit' variant='primary' className='mt-7'>
              {registerState.loading ? <LoaderCircle className='size-4 animate-spin' /> : submitButtonText}
            </Button>
            {registerState.error && <FormMessage className='text-center'>{translate('formErrorMessage', 'Something went wrong, please try again.')}</FormMessage>}
            <MisterSiteLink className='text-center text-body-sm underline' link={alreadyHaveAccountLink}>
              {alreadyHaveAccountLink?.linkText}
            </MisterSiteLink>
          </form>
        </Form>
      </div>
    </MisterAuthPageContainer>
  )
}

export default SectionRegistration
