import { FunctionComponent } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'

import { AverageRating } from 'data-access/database/types'
import { SectionBusinessReviewsData } from 'data-access/sanity/fragments/sections/sectionBusinessReviews.fragment'

import { TrustpilotBusinessReview } from '@/types/businessReview'

import MisterContainer from 'src/components/shared/MisterContainer'
import MisterReviewCarousel from 'src/components/shared/review/MisterReviewCarousel'
import MisterTrustpilotWidget from 'src/components/shared/review/MisterTrustpilotWidget'
import { useTranslations } from '../../../contexts/Globals.context'
import fetcher from '../../../utils/request.util'

interface SectionBusinessReviewsProps {
  data: SectionBusinessReviewsData
  initialReviews?: TrustpilotBusinessReview[]
  averageRating?: AverageRating
}

const SectionBusinessReviews: FunctionComponent<SectionBusinessReviewsProps> = ({ data, initialReviews, averageRating }) => {
  const { locale } = useRouter()
  const translate = useTranslations()

  const { data: reviews } = useQuery({
    queryKey: ['business-reviews', locale],
    queryFn: () => fetcher<TrustpilotBusinessReview[]>(`/api/reviews/business-reviews?locale=${locale}`),
    initialData: initialReviews,
    refetchOnMount: true,
  })

  if (reviews?.length === 0) {
    return null
  }

  return (
    <MisterContainer dataLabel='section-business-reviews' type='fluid' padding={false} data-id={data?._id} className='md:flex' id='business-reviews'>
      <div className='flex w-full flex-col items-start justify-between gap-10 px-6 pb-10 pt-16 md:mb-0 md:shrink-0 md:basis-[25rem] md:px-14 md:pb-16 md:pt-24'>
        {data?.title && <h2 className='text-heading-5 md:text-heading-3 lg:text-left'>{data?.title}</h2>}
        {averageRating && (
          <MisterTrustpilotWidget
            reviewRating={averageRating?.rating}
            reviewCount={averageRating?.count}
            reviewExplanationTooltip={translate('reviewExplanationTooltip', 'Average rating of {count} Trusted Shops and Trustpilot reviews from the past 6 months.')}
          />
        )}
      </div>
      <div className='overflow-x-hidden pb-10 pl-6 pt-6 md:py-16 md:pl-16 md:pt-24'>
        <MisterReviewCarousel reviews={reviews || []} />
      </div>
    </MisterContainer>
  )
}

export default SectionBusinessReviews
