/**
 * Split array into chunks of size x
 */
export const chunk = <T = unknown>(array: T[], chunkSize: number) => {
  const result = []

  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }

  return result
}
