import { FunctionComponent } from 'react'

import { SectionMediaData } from 'data-access/sanity/fragments/sections/sectionMedia.fragment'

import { useSectionLayout } from 'src/contexts/SectionLayout.context'
import MisterMedia from '../../shared/media/MisterMedia'

interface Props {
  data: SectionMediaData
  index: number
}

const SectionMedia: FunctionComponent<Props> = ({ data, index: sectionIndex }) => {
  const layoutData = useSectionLayout(data._id)

  if (!data?.media) {
    return null
  }

  const { sizes } = layoutData || {}

  return (
    <MisterMedia
      id={data._id}
      media={data?.media}
      sectionIndex={sectionIndex}
      className='size-full object-cover'
      mobileSizes={sizes || '(max-width: 1024px) 100vw, (max-width: 1536px) 80vw, 60rem'}
      desktopSizes={sizes || '(max-width: 1024px) 100vw, (max-width: 1536px) 80vw, 60rem'}
    />
  )
}

export default SectionMedia
