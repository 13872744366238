import { FunctionComponent } from 'react'

import { DefaultPageProps } from 'src/domain/defaultPageProps.domain'
import MisterFluidContainer from '../../shared/MisterFluidContainer'
import { MisterExtraMenuItems } from './MisterExtraMenuItems'
import MisterFooterContact from './MisterFooterContact'
import MisterFooterEmailCapture from './MisterFooterEmailCapture'
import MisterFooterFindStore from './MisterFooterFindStore'
import MisterFooterMenuItems from './MisterFooterMenuItems'
import MisterFooterRecentlyViewed from './MisterFooterRecentlyViewed'
import { MisterFooterSocial } from './MisterFooterSocialLinks'

export type Footer = NonNullable<DefaultPageProps['footer']>

interface MisterFooterProps {
  data: Footer
  averageRating: DefaultPageProps['averageRating']
  showFooterRecommendations?: boolean
}

const MisterFooter: FunctionComponent<MisterFooterProps> = ({ data, averageRating, showFooterRecommendations = true }) => {
  return (
    <footer className='text-body-md xl:text-body-lg'>
      <MisterFooterRecentlyViewed recentlyViewedText={data?.recentlyViewed || 'Recently viewed'} clearText={data?.clear || '(clear)'} show={showFooterRecommendations} />
      <MisterFooterFindStore data={data} />

      <div className='flex flex-col gap-y-14 px-4 py-14 md:grid md:grid-cols-2 md:py-28'>
        {data && <MisterFooterContact data={data} />}
        <MisterFooterEmailCapture emailSectionHeader={data?.emailSectionHeader} emailSectionText={data?.emailSectionText} emailSectionPlaceholder={data?.emailSectionPlaceholder} />
      </div>
      <MisterFluidContainer verticalPadding={false} className='flex flex-col gap-12 bg-brand-blue pb-10 pt-11 text-white lg:gap-[4.75rem] lg:pt-[4.5rem]'>
        <div className='flex flex-col gap-10'>
          {data?.productMenuItemsHeader && <h2 className='text-heading-3 md:text-heading-2'>{data?.productMenuItemsHeader}</h2>}
          <div className='xs:columns-2 sm:columns-3 md:columns-4 lg:columns-5 xl:columns-6'>
            <MisterFooterMenuItems menuItems={data?.productMenuItems} />
          </div>
        </div>

        <div className='flex flex-col gap-10'>
          {data?.supportMenuItemsHeader && <h2 className='text-heading-3 md:text-heading-2'>{data?.supportMenuItemsHeader}</h2>}
          <div className='flex flex-col gap-y-10 sm:grid-cols-3 md:grid md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6'>
            <div className='xs:columns-2 sm:columns-3 md:col-span-2 md:columns-2 lg:col-span-3 lg:columns-3'>
              <MisterFooterMenuItems menuItems={data?.supportMenuItems} />
            </div>
            <div className='col-span-2 md:col-end-5 lg:col-end-6 xl:col-end-7'>
              <MisterFooterSocial
                averageRating={averageRating}
                reviewExplanationTooltip={data?.reviewExplanationTooltip}
                bCorpLink={data?.bCorpLink}
                bCorpNoticeLink={data?.bCorpNoticeLink}
              />
            </div>
          </div>
        </div>

        <MisterExtraMenuItems className='col-span-2 mt-20 pr-0 sm:pr-20 md:col-span-6' extraMenuItems={data?.extraMenuItems} copyRight={data?.copyright} />
      </MisterFluidContainer>
    </footer>
  )
}

export default MisterFooter
