import { FunctionComponent } from 'react'

import { SectionContactData } from 'data-access/sanity/fragments/sections/sectionContact.fragment'

import MisterContainer from '../../shared/MisterContainer'
import ContactBlock from './contack-block/contact-block'

interface SectionContactProps {
  data: SectionContactData
}

const SectionContact: FunctionComponent<SectionContactProps> = ({ data }) => {
  return (
    <MisterContainer dataLabel='section-contact' type='small' className='mb-14' data-id={data._id}>
      <div className='lg:mb-20 2xl:mb-28'>
        <div className='grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-4'>
          {data?.contactBlocks?.map((block) => <ContactBlock key={block?._id} block={block} customerServiceData={data.customerServiceData} />)}
        </div>

        <div className='mt-4 flex flex-col justify-start gap-2 py-4 sm:items-center'>
          <span className='grow-0 text-body-sm md:text-body-sm-bold'>{data.customerServiceData.openingHoursHeader}</span>
          <span className='flex-none text-body-sm'>{data.customerServiceData.openingHours}</span>
          {data.customerServiceData.specialOpeningHours && <span className='flex-none text-body-sm'>{data.customerServiceData.specialOpeningHours}</span>}
        </div>
      </div>
    </MisterContainer>
  )
}

export default SectionContact
