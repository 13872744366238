import { FunctionComponent } from 'react'
import { icons } from 'lucide-react'

import { Link } from 'data-access/sanity/fragments/components/link.fragment'

import MisterIcon from '../MisterIcon'
import MisterSiteLink from './MisterSiteLink'

export interface ContactMethodProps {
  icon: string
  link?: Omit<Link, '_type'>
  className?: string
  onClick?: () => void
  linkText?: string
  animate?: boolean
}

const MisterContactMethodLink: FunctionComponent<ContactMethodProps> = ({ icon, link, onClick, linkText }) => {
  const Element = link ? MisterSiteLink : 'div'
  const LucidIcon = icons?.[icon as keyof typeof icons]

  return (
    <Element className='group relative flex w-10 cursor-pointer flex-col items-center gap-2 text-body-sm' link={link} onClick={onClick}>
      <div className='transition duration-500'>{LucidIcon ? <LucidIcon strokeWidth={1} className='size-8' /> : <MisterIcon className='size-8' type={icon} />}</div>
      <span className='text-center group-hover:underline'>{linkText || link?.linkText || null}</span>
    </Element>
  )
}

export default MisterContactMethodLink
