import { FunctionComponent, useEffect } from 'react'
import { useRouter } from 'next/router'

import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from '@ui/components/ui/sheet'

import MisterSearchBar from 'src/components/shared/search/MisterSearchBar'
import { useUI } from 'src/contexts/UI.context'

export type Motion = 'from-start' | 'to-start' | 'from-end' | 'to-end' | null
export type MenuType = 'top' | 'secondary' | 'productCategories' | 'styles' | 'countryPicker'
export type Direction = 'forward' | 'backward'

export const SearchOverlay: FunctionComponent = () => {
  const router = useRouter()
  const { displaySearchUI, hideSearchUI } = useUI()

  // Close the Search Sheet when the route changes
  useEffect(() => {
    const handleRouteChange = () => {
      hideSearchUI()
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Manages body scroll behavior when the search overlay is shown/hidden.
   *
   * iOS Safari doesn't honor overflow:hidden on body, so we use position:fixed
   * with scroll position management as a more reliable cross-browser solution.
   */
  useEffect(() => {
    let scrollY = '0'

    const setBodyStyles = (isOpen: boolean) => {
      if (isOpen) {
        scrollY = window.scrollY.toString()
        document.body.style.position = 'fixed'
        document.body.style.top = `-${scrollY}px`
        document.body.style.left = '0'
        document.body.style.width = '100%'
      } else {
        document.body.style.position = ''
        document.body.style.top = ''
        document.body.style.left = ''
        document.body.style.width = ''
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      }
    }

    setBodyStyles(displaySearchUI)

    return () => setBodyStyles(false)
  }, [displaySearchUI])

  return (
    // Use modal={false} to prevent the sheet from closing when clicking outside
    <Sheet open={displaySearchUI} modal={false}>
      <SheetContent fade={true} withOverlay={true} withClose={false} side='bottom' className='z-quickSearch h-[calc(100svh-55px)] border-0 p-0'>
        <SheetHeader>
          <SheetTitle className='sr-only'>Mobile Search</SheetTitle>
          <SheetDescription className='sr-only'>Mobile Search</SheetDescription>
        </SheetHeader>
        <MisterSearchBar className='h-[calc(100svh-20px)] overflow-y-scroll bg-white lg:h-[calc(100svh-55px)]' />
      </SheetContent>
    </Sheet>
  )
}
