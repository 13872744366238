import { FunctionComponent, useEffect } from 'react'
import { useToggle } from 'react-use'

import { useTranslations } from 'src/contexts/Globals.context'
import { useCookieConsentContext } from '../../../hooks/context/useCookieConsentContext'
import MisterContactMethodLink from '../../shared/site-link/MisterContactMethodLink'
import { Footer } from './MisterFooter'

interface MisterFooterContactProps {
  data: Footer
}

const MisterFooterContact: FunctionComponent<MisterFooterContactProps> = ({ data }) => {
  const {
    customerServiceData: { openingHoursHeader, openingHours, specialOpeningHours, email, messenger, phone, whatsApp },
    infoSectionHeader,
    returnsLink,
    faqLink,
  } = data

  const translate = useTranslations()
  const { consent, hasConsentedToCookie } = useCookieConsentContext()
  const [showChatToggle, setShowChatToggle] = useToggle(false)

  useEffect(() => {
    setShowChatToggle(hasConsentedToCookie('preferences'))
  }, [consent, hasConsentedToCookie, setShowChatToggle])

  return (
    <div className='flex flex-col items-center md:gap-8'>
      <h2 className='mb-6 text-heading-5 md:m-0 md:text-heading-3'>{infoSectionHeader}</h2>
      <div className='flex flex-col items-center gap-2'>
        <span className='text-body-md'>{openingHoursHeader}</span>
        <span className='flex-none text-body-md'>{openingHours}</span>
        {specialOpeningHours && <span className='flex-none text-body-md'>{specialOpeningHours}</span>}
        {phone && (
          <a href={`tel:${phone}`} className='text-body-md underline md:text-body-lg'>
            {phone}
          </a>
        )}
      </div>
      <div className='mt-8 grid grid-cols-3 gap-x-8 gap-y-6 md:m-0 lg:flex lg:gap-x-8'>
        {returnsLink && <MisterContactMethodLink icon='Package' link={returnsLink} />}
        {faqLink && <MisterContactMethodLink icon='openBook' link={faqLink} />}
        {email && <MisterContactMethodLink icon='Mail' link={{ type: 'email', newTab: true, linkText: translate('email', 'Email'), email: email }} />}
        {messenger && (
          <MisterContactMethodLink
            icon='messenger'
            link={{ type: 'external', linkText: translate('messenger', 'Messenger'), newTab: true, externalLink: 'https://m.me/' + messenger?.replace(/\s/g, '') }}
          />
        )}
        {showChatToggle && <MisterContactMethodLink icon='MessagesSquare' onClick={() => window.Kustomer.open()} linkText={translate('chat', 'Chat')} />}
        {whatsApp && (
          <MisterContactMethodLink
            icon='whatsapp'
            link={{ type: 'external', newTab: true, linkText: translate('whatsApp', 'WhatsApp'), externalLink: 'https://wa.me/' + whatsApp?.replace(/\s/g, '') }}
          />
        )}
      </div>
    </div>
  )
}

export default MisterFooterContact
