import { DiscountCampaign } from '@/types/globals'

export function getDiscountCampaign(discountCampaigns?: DiscountCampaign[], productId?: string): DiscountCampaign | undefined {
  if (!discountCampaigns) {
    return undefined
  }

  return discountCampaigns.filter(({ showOnCollections, productIds }) =>
    productIds?.some((id) => (showOnCollections === 'select' ? id === productId : showOnCollections === 'exclude' ? id !== productId : true)),
  )[0]
}

export function getDiscountCampaignByCollectionId(discountCampaigns?: DiscountCampaign[], collectionId?: string): DiscountCampaign | undefined {
  if (!discountCampaigns) {
    return undefined
  }

  return discountCampaigns.filter(({ showOnCollections, collectionIds }) =>
    collectionIds?.some((id) => (showOnCollections === 'select' ? id === collectionId : showOnCollections === 'exclude' ? id !== collectionId : true)),
  )[0]
}
