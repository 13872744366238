import { FunctionComponent } from 'react'

import { ShopifyVariantInSanity } from 'data-access/sanity/fragments/components/productCardProduct.fragment'
import { cn } from '@ui/lib/utils'
import { CombinedOptions } from 'utilities/array/combineOptions'

import { useTranslations } from 'src/contexts/Globals.context'
import { ProductWithEnrichedAvailability } from 'src/domain/productCard.domain'

const STOCK_WARNING_THRESHOLD = 5

interface LowStockWarningProps {
  product: ProductWithEnrichedAvailability
  options: CombinedOptions
  selectedVariant: ShopifyVariantInSanity | null
  sizeColIndex: number
  sizeRowIndex: number
}

const LowStockWarning: FunctionComponent<LowStockWarningProps> = ({ product, options, selectedVariant, sizeColIndex, sizeRowIndex }) => {
  const translate = useTranslations()

  const singleOption = options?.length === 1
  const availableInventories = product.variants.map(({ inventory }) => inventory?.quantity || 0)
  const inventory = singleOption ? availableInventories[sizeColIndex] : selectedVariant?.inventory.quantity
  const isBottomRowOfOptions = options.length === sizeRowIndex + 1
  const showLimitedStockWarning = isBottomRowOfOptions && !!inventory && inventory <= STOCK_WARNING_THRESHOLD && inventory > 0

  if (!showLimitedStockWarning) {
    return
  }
  return (
    <div className={cn('absolute top-full text-nowrap pt-1 text-center text-body-xs opacity-0 peer-checked:opacity-100', singleOption && 'peer-hover:opacity-100')}>
      {translate('onlyQuantityLeftText', 'Only {quantity} left').replace('{quantity}', `${inventory}`)}
    </div>
  )
}

export default LowStockWarning
