import { FunctionComponent, RefObject, useEffect } from 'react'
import { useToggle } from 'react-use'

import MisterLucideIcon from '../MisterLucideIcon'

interface VideoPauseButtonProps {
  videoRef: RefObject<HTMLVideoElement | null>
}

const MisterVideoPauseButton: FunctionComponent<VideoPauseButtonProps> = ({ videoRef }) => {
  const [isPlaying, setIsPlaying] = useToggle(true)

  useEffect(() => {
    const videoElement = videoRef.current
    if (!videoElement) return

    const handlePlay = () => setIsPlaying(true)
    const handlePause = () => setIsPlaying(false)

    videoElement.addEventListener('play', handlePlay)
    videoElement.addEventListener('pause', handlePause)

    setIsPlaying(!videoElement.paused)

    return () => {
      videoElement.removeEventListener('play', handlePlay)
      videoElement.removeEventListener('pause', handlePause)
    }
  }, [videoRef, setIsPlaying])

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()

    const videoElement = videoRef.current
    if (!videoElement) return

    if (videoElement.paused) {
      videoElement.play()
    } else {
      videoElement.pause()
    }
  }

  return (
    <button
      className='absolute bottom-[2%] right-[3%] flex size-8 items-center justify-center rounded-md bg-secondary md:opacity-0 md:transition-opacity md:delay-150 md:duration-500 md:group-hover:opacity-100'
      onClick={handleClick}
    >
      <MisterLucideIcon name={isPlaying ? 'pause' : 'play'} className='size-6 fill-secondary-foreground stroke-none' size={20} />
    </button>
  )
}

export default MisterVideoPauseButton
