import { getProductsForRecommendations } from 'data-access'
import { SupportedLocale } from 'data-access/domain/constants'
import { ProductCardProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'

interface GetRecommendationsParams {
  productSlugs: string[]
  locale: SupportedLocale
  count: number
}

export const getRecommendedProducts = async ({ productSlugs, locale, count }: GetRecommendationsParams): Promise<ProductCardProduct[]> => {
  try {
    return await getProductsForRecommendations(productSlugs, locale, count)
  } catch (error) {
    console.error('Error fetching recommended products:', error)
    return []
  }
}
