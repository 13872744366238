import { useState } from 'react'
import { useRouter } from 'next/router'

import { getErrorMessage } from 'utilities/errors/errorMessage'

import { useTranslations } from 'src/contexts/Globals.context'
import { post } from '../../utils/request.util'
import useCustomer from './useCustomer'

interface State {
  response: null | boolean
  loading: boolean
  error: null | string
}

export const useActivateCustomer = (): [(passwordField1?: string, passwordField2?: string, id?: string | null, activationToken?: string | null) => Promise<void>, State] => {
  const { locale } = useRouter()
  const translate = useTranslations()
  const [state, setState] = useState<State>({
    response: null,
    loading: false,
    error: null,
  })

  const { mutateCustomer } = useCustomer('/account/', true)

  const activeCustomer = async (passwordField1?: string, passwordField2?: string, id?: string | null, activationToken?: string | null) => {
    if (passwordField1 !== passwordField2) {
      setState({
        response: null,
        loading: false,
        error: translate('Passwords do not match', 'Passwords do not match.'),
      })
    } else if (passwordField1 && passwordField2 && id && activationToken) {
      setState({ response: null, loading: true, error: null })

      try {
        await mutateCustomer(
          post(
            '/api/shopify/account/activate',
            {
              id,
              locale,
              activationToken,
              password: passwordField1,
            },
            {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': 'Content-Type',
            },
          ),
        )

        setState({ response: true, loading: false, error: null })
      } catch (error) {
        console.error('useActivateCustomer failed: ', getErrorMessage(error))

        setState({
          response: null,
          loading: false,
          error: getErrorMessage(error) || 'Activation failed',
        })
      }
    }
  }

  return [activeCustomer, state]
}

export default useActivateCustomer
