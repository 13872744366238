import { FunctionComponent } from 'react'

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@ui/components/ui/table'
import { cn } from '@ui/lib/utils'

import { type ConversionTable } from 'src/domain/sizeGuide.domain'

interface ConversionTableProps {
  conversionTable: ConversionTable
}

const ConversionTable: FunctionComponent<ConversionTableProps> = ({ conversionTable }) => {
  if (!conversionTable) return null

  return (
    <div className='overflow-hidden'>
      <div className='mt-9 overflow-x-auto overflow-y-hidden rounded-md border border-brand-beige-light text-center scrollbar-hide'>
        <Table className='relative table-fixed [&:has(td:nth-child(2):hover)_td:nth-child(2)]:bg-brand-blue-pale [&:has(td:nth-child(2):hover)_th:nth-child(2)]:bg-brand-blue [&:has(td:nth-child(2):hover)_th:nth-child(2)]:text-white [&:has(td:nth-child(3):hover)_td:nth-child(3)]:bg-brand-blue-pale [&:has(td:nth-child(3):hover)_th:nth-child(3)]:bg-brand-blue [&:has(td:nth-child(3):hover)_th:nth-child(3)]:text-white [&:has(td:nth-child(4):hover)_td:nth-child(4)]:bg-brand-blue-pale [&:has(td:nth-child(4):hover)_th:nth-child(4)]:bg-brand-blue [&:has(td:nth-child(4):hover)_th:nth-child(4)]:text-white [&:has(td:nth-child(5):hover)_td:nth-child(5)]:bg-brand-blue-pale [&:has(td:nth-child(5):hover)_th:nth-child(5)]:bg-brand-blue [&:has(td:nth-child(5):hover)_th:nth-child(5)]:text-white [&:has(td:nth-child(6):hover)_td:nth-child(6)]:bg-brand-blue-pale [&:has(td:nth-child(6):hover)_th:nth-child(6)]:bg-brand-blue [&:has(td:nth-child(6):hover)_th:nth-child(6)]:text-white [&:has(td:nth-child(7):hover)_td:nth-child(7)]:bg-brand-blue-pale [&:has(td:nth-child(7):hover)_th:nth-child(7)]:bg-brand-blue [&:has(td:nth-child(7):hover)_th:nth-child(7)]:text-white [&:has(td:nth-child(8):hover)_td:nth-child(8)]:bg-brand-blue-pale [&:has(td:nth-child(8):hover)_th:nth-child(8)]:bg-brand-blue [&:has(td:nth-child(8):hover)_th:nth-child(8)]:text-white [&:has(td:nth-child(9):hover)_td:nth-child(9)]:bg-brand-blue-pale [&:has(td:nth-child(9):hover)_th:nth-child(9)]:bg-brand-blue [&:has(td:nth-child(9):hover)_th:nth-child(9)]:text-white'>
          <TableHeader>
            <TableRow>
              {conversionTable.headings.map((heading, i) => (
                <TableHead
                  key={i}
                  className='sticky left-0 h-16 max-w-[120px] content-center border-r-0 border-none transition-all after:absolute after:inset-y-0 after:right-0 after:bg-brand-beige first:after:w-[1px]'
                >
                  {heading}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {conversionTable.sizeConversions.map((conversions, i) => (
              <TableRow key={i} className='group/row max-w-12 odd:bg-white even:bg-brand-beige-light'>
                {conversions.map((conversion, j) => (
                  <TableCell
                    key={j}
                    className={cn(
                      'min-w-16 transition-all hover:font-bold hover:text-white group-hover/row:bg-brand-blue-pale group-hover/row:hover:!bg-brand-blue',
                      j === 0 &&
                        'sticky left-0 border-none after:absolute after:inset-y-0 after:right-0 after:w-[1px] after:bg-brand-beige group-odd/row:bg-white group-even/row:bg-brand-beige-light group-hover/row:bg-brand-blue group-hover/row:text-white',
                    )}
                  >
                    <div
                      data-content={conversion}
                      className='text-center after:invisible after:block after:h-0 after:min-w-16 after:text-center after:font-bold after:content-[attr(data-content)]'
                    >
                      {conversion}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default ConversionTable
