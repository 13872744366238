import { FunctionComponent } from 'react'

import { cn } from '@ui/lib/utils'

import MisterSkeletonLoader from 'src/components/shared/loader/MisterSkeletonLoader'

const MisterSizeGuideSkeleton: FunctionComponent = () => {
  return (
    <div className='[container-type:inline-size]'>
      <div className={cn('mb-10 grid overflow-hidden rounded-md bg-brand-beige @md:grid-cols-2')}>
        <MisterSkeletonLoader className='hidden h-[600px] rounded-e-none @md:block' />
        <div className='flex flex-col gap-5 px-4 py-5'>
          <MisterSkeletonLoader className='h-6 w-24' />
          <div className='flex flex-col gap-3'>
            <MisterSkeletonLoader className='h-4 w-full' />
            <MisterSkeletonLoader className='h-4 w-full' />
            <MisterSkeletonLoader className='h-4 w-full' />
            <MisterSkeletonLoader className='h-4 w-full' />
            <MisterSkeletonLoader className='h-4 w-3/4' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MisterSizeGuideSkeleton
