import { FunctionComponent, PropsWithChildren, ReactNode } from 'react'

import { cn } from 'ui/lib/utils'
import { Sheet, SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@ui/components/ui/sheet'

interface Props {
  title: string
  visible: boolean
  onClose: () => void
  footer?: ReactNode
  showTitle?: boolean
  padding?: boolean
  header?: ReactNode
  size?: 'medium' | 'large'
}

/**
 * @deprecated: still works, but components should directly use the Sheet component from the ui package
 */
const MisterSlideOverPanel: FunctionComponent<PropsWithChildren<Props>> = ({
  title,
  visible,
  onClose,
  footer,
  showTitle = true,
  padding = true,
  header = undefined,
  size = 'medium',
  children,
}) => {
  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose()
    }
  }

  return (
    <Sheet open={visible} onOpenChange={handleOpenChange}>
      <SheetContent
        overlayClassname='bg-black/50'
        className={cn(
          'flex flex-col border-none',
          size === 'large' && 'w-[95%] sm:w-10/12 sm:max-w-none md:max-w-2xl xl:max-w-4xl 2xl:max-w-4xl 3xl:max-w-4xl',
          !padding && 'gap-0 p-0',
        )}
      >
        <SheetHeader>
          <SheetTitle className={cn('text-heading-4 font-bold', (!!header || !showTitle) && 'sr-only')}>{title}</SheetTitle>
          {header}
        </SheetHeader>
        <div className='flex h-full flex-col overflow-y-auto overflow-x-hidden'>{children}</div>
        {footer && <SheetFooter>{footer}</SheetFooter>}
      </SheetContent>
    </Sheet>
  )
}

export default MisterSlideOverPanel
