import { FunctionComponent } from 'react'
import { X } from 'lucide-react'
import Link from 'next/link'

const MisterPreview: FunctionComponent = () => {
  return (
    <div className='pointer-events-none sticky bottom-5 z-50 flex justify-center'>
      <button>
        <Link
          href='/api/exit-draft-mode'
          className='pointer-events-auto flex w-auto items-center justify-center gap-4 rounded-full bg-brand-blue py-2 pl-4 pr-2 font-semibold text-white'
        >
          <p>Draft mode enabled</p>
          <span className='aspect-square rounded-full bg-white p-1 text-black'>
            <X />
          </span>
        </Link>
      </button>
    </div>
  )
}

export default MisterPreview
