import { createContext, useContext } from 'react'
import { useRouter } from 'next/router'

import { SupportedLocale } from 'data-access/domain/constants'
import { ProductCardProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'

import { enrichProductWithLiveAvailability } from 'src/domain/productCard.domain'

type ProductCardContextType = {
  index: number
  product: ProductCardProduct | undefined
  isBeingDragged: boolean
  variantSku?: string
}

export const ProductCardContext = createContext<ProductCardContextType>({ index: 0, product: undefined, variantSku: undefined, isBeingDragged: false })

export const useProductCard = (componentType: string) => {
  const { locale } = useRouter()
  const { index, product, variantSku, isBeingDragged } = useContext(ProductCardContext)

  if (product === undefined) {
    throw new Error(`${componentType} must be used within a ProductCard.`)
  }

  const productWithStock = enrichProductWithLiveAvailability(product, locale as SupportedLocale, variantSku)

  return { index, product: productWithStock, isBeingDragged }
}
