import { FunctionComponent } from 'react'
import { X } from 'lucide-react'

import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/lib/utils'

import { useUI } from 'src/contexts/UI.context'

export const MobileMenuCloseButton: FunctionComponent = () => {
  const { closeMobileMenu, displayMobileMenu, displaySearchUI, hideSearchUI } = useUI()
  return (
    <Button
      onClick={displayMobileMenu ? closeMobileMenu : displaySearchUI ? hideSearchUI : () => {}}
      title='Close menu'
      variant='ghost'
      size='icon'
      tabIndex={displayMobileMenu || displaySearchUI ? 0 : -1}
      className={cn(
        'absolute right-0 flex items-center justify-center transition-[transform,opacity] duration-500 ease-out',
        !displayMobileMenu && !displaySearchUI && 'translate-x-10 translate-y-5 opacity-0',
        displaySearchUI && 'lg:hidden',
      )}
    >
      <X className='size-7 stroke-[1.5px]' />
    </Button>
  )
}
