import { FunctionComponent, useContext } from 'react'

import { cn } from 'ui/lib/utils'
import { getPriceValidUntil } from 'utilities/date/format/formatPriceValidUntil'

import Price from 'src/components/shared/Price'
import { GlobalsContext } from 'src/contexts/Globals.context'
import useCart from 'src/hooks/shop/useCart'
import { getDiscountCampaign } from 'src/utils/discountCampaign.util'
import { productTitle } from 'src/utils/product.util'
import { useProductCard } from './ProductCard.context'

const ProductInfo: FunctionComponent = () => {
  const { product } = useProductCard('Product Info')
  const { discountCampaigns } = useContext(GlobalsContext)
  const { currencyCode } = useCart()

  const { discount } = getDiscountCampaign(discountCampaigns, product._id) || {}
  const isGiftCard = product.slug.current === 'gift-card'
  const { title, category: subtitle } = productTitle(product.productTitle, true)

  return (
    <div data-label='ProductInfoHeader' className='w-full pr-2 pt-3' itemProp='offers' itemType='https://schema.org/Offer' itemScope>
      <meta itemProp='price' content={String(product.price)} />
      <meta itemProp='priceValidUntil' content={getPriceValidUntil()} />
      <meta itemProp='itemCondition' content='https://schema.org/NewCondition' />
      <meta itemProp='priceCurrency' content={currencyCode} />
      <meta itemProp='availability' content={product.isAvailable ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock'} />
      <div className='flex items-baseline'>
        <h3>
          <span className='text-body-md-bold font-bold @[15.625rem]/product-card:text-body-lg-bold sm:mb-1'>{title}</span>
          {subtitle && <p className='mt-1 text-body-sm @[15.625rem]/product-card:text-body-md'>{subtitle}</p>}
        </h3>
        {!isGiftCard && (
          <div
            className={cn(
              'ml-auto shrink-0 gap-2 text-body-md @[15.625rem]/product-card:text-body-lg',
              discount && 'text-end @[15.625rem]/product-card:flex @[15.625rem]/product-card:align-baseline',
            )}
          >
            {discount && (
              <s className={cn('line-through', discount && 'text-body-sm @[15.625rem]/product-card:text-body-lg')}>
                <Price price={product.price} />
              </s>
            )}
            <div className={cn(discount && 'text-body-md @[15.625rem]/product-card:text-body-lg')}>
              <Price price={discount ? product.price * (1 - discount / 100) : product.price} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductInfo
