import { FunctionComponent } from 'react'

import { SectionTextData } from 'data-access/sanity/fragments/sections/sectionText.fragment'
import { cn } from 'ui/lib/utils'

import MisterCtaButton from 'src/components/shared/MisterCtaButton'
import { useSectionLayout } from 'src/contexts/SectionLayout.context'
import MisterContainer from '../../shared/MisterContainer'
import RichText from '../../shared/rich-text/rich-text'

export interface SectionTextProps {
  data: SectionTextData
  index: number
}

const SectionText: FunctionComponent<SectionTextProps> = ({ data, index }) => {
  const layoutData = useSectionLayout(data._id)

  const { title, text, textAlignementOptions: textAlignmentOptions, button, textColor } = data || {}

  const TitleTag = index === 0 ? 'h1' : 'h3'
  const titleClasses = cn(index === 0 ? 'text-heading-3 md:text-heading-1' : 'text-heading-5 md:text-heading-4')

  const alignmentClasses = cn(textAlignmentOptions === 'center' ? 'items-center text-center' : textAlignmentOptions === 'left' ? 'text-left' : 'text-right')

  return (
    <MisterContainer
      dataLabel='section-text'
      padding={false}
      data-id={data._id}
      type='fluid'
      className={cn(
        'flex flex-col gap-9',
        textAlignmentOptions === 'center' && 'items-center',
        textColor,
        !layoutData && 'mx-auto max-w-prose',
        layoutData && 'mt-auto',
        layoutData && !layoutData.layoutDataForSection.isInFirstColumn.base && textAlignmentOptions === 'left' && 'pl-10',
        layoutData && !layoutData.layoutDataForSection.isInFirstColumn.md && textAlignmentOptions === 'left' ? 'md:pl-10' : 'md:pl-0',
        layoutData && !layoutData.layoutDataForSection.isInFirstColumn.lg && textAlignmentOptions === 'left' ? 'lg:pl-10' : 'lg:pl-0',
      )}
    >
      {title && title !== '' && <TitleTag className={cn('text-balanced', layoutData && 'w-[clamp(10ch,60ch,90%)]', titleClasses, alignmentClasses)}>{title}</TitleTag>}
      {text && (
        <RichText className={cn('text-pretty', layoutData && 'flex flex-col [&_*]:w-[clamp(10ch,60ch,80%)] [&_h2]:w-[clamp(10ch,60ch,90%)]', alignmentClasses)} data={text} />
      )}
      {button && <MisterCtaButton cta={button} />}
    </MisterContainer>
  )
}

export default SectionText
