import { FunctionComponent } from 'react'
import { Minus, Plus } from 'lucide-react'
import { useRouter } from 'next/router'

import { ShopifyCartLineItem } from 'data-access/shopify/types'

import { useTelemetry } from '../../../../contexts/Telemetry.context'
import useCart from '../../../../hooks/shop/useCart'
import { ecommerceInfoUpdateCartItem } from '../../../../utils/elevar.util'
import { get } from '../../../../utils/localStorage.util'
import { ELEVAR_DATA_LAYER_EVENTS, trackEvent } from '../../../../utils/telemetry.util'

interface Props {
  lineItem: ShopifyCartLineItem
  type: 'plus' | 'minus'
  setIsLoading: (nextValue?: (prev: boolean) => boolean) => void
  onRemove: () => Promise<void>
}

const MisterQuantityButton: FunctionComponent<Props> = ({ lineItem, type, setIsLoading, onRemove }) => {
  const { locale } = useRouter()
  const { addItems, replaceItem } = useCart()
  const { userProperties } = useTelemetry()

  const handleQuantityChange = async () => {
    setIsLoading((prev) => !prev)

    if (type === 'plus') {
      const { error } = await addItems({ lines: [{ quantity: 1, merchandiseId: lineItem?.merchandise.id }], reorderCart: false })
      if (!error) {
        trackEvent(ELEVAR_DATA_LAYER_EVENTS.DL_ADD_TO_CART, {
          user_properties: userProperties,
          ecommerce: ecommerceInfoUpdateCartItem(lineItem, 1, 'add', get('elevar-list') ?? '', locale),
        })
      }
    } else {
      if (lineItem?.quantity === 1) {
        await onRemove()
      } else {
        await replaceItem({ quantity: lineItem?.quantity - 1, id: lineItem?.id })
      }

      trackEvent(ELEVAR_DATA_LAYER_EVENTS.DL_REMOVE_FROM_CART, {
        user_properties: userProperties,
        ecommerce: ecommerceInfoUpdateCartItem(lineItem, 1, 'remove', get('elevar-list') ?? '', locale),
      })
    }

    setTimeout(() => {
      setIsLoading((prev) => !prev)
    }, 500)
  }

  return (
    <button
      type='button'
      onClick={handleQuantityChange}
      className='rounded-full border border-brand-blue-dark-disabled p-1 hover:border-brand-blue disabled:cursor-not-allowed disabled:opacity-30 hover:disabled:border-brand-blue-dark-disabled'
    >
      {type === 'plus' && <Plus className='size-3' />}
      {type === 'minus' && <Minus className='size-3' />}
    </button>
  )
}

export default MisterQuantityButton
