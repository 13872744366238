import { useEffect, useState } from 'react'
import { addDays, endOfDay, isAfter, isBefore, isSameDay, parseISO, startOfDay } from 'date-fns'

import { RetailStoreData } from 'data-access/sanity/fragments/sections/sectionStore.fragment'
import { SpecialOpeningTime } from 'data-access/sanity/fragments/sections/sectionStoreIndex.fragment'
import { Days } from 'utilities/date/format/formatDate'

const useSpecialDays = (defaultOpeningTimes?: RetailStoreData['defaultOpeningTimes'], specialOpeningTimes?: RetailStoreData['specialOpeningTimes']) => {
  const [today, setToday] = useState<Date | null>(null)
  const [dayOfTheWeek, setDayOfTheWeek] = useState<Days | null>(null)
  const [lastDayInAWeek, setLastDayInAWeek] = useState<Date | null>(null)
  const [upcomingSpecialDays, setUpcomingSpecialDays] = useState<SpecialOpeningTime[]>([])

  useEffect(() => {
    setToday(startOfDay(new Date()))
    setLastDayInAWeek(endOfDay(addDays(startOfDay(new Date()), 6)))
    setDayOfTheWeek(new Date().toLocaleString('en', { weekday: 'long', timeZone: 'Europe/Amsterdam' }).toLowerCase() as Days)
  }, [])

  useEffect(() => {
    if (defaultOpeningTimes && specialOpeningTimes && dayOfTheWeek && today && lastDayInAWeek) {
      setUpcomingSpecialDays(
        specialOpeningTimes?.filter(({ date }) => {
          const specialOpeningDate = parseISO(date)

          return (isSameDay(specialOpeningDate, today) || isAfter(specialOpeningDate, today)) && isBefore(specialOpeningDate, lastDayInAWeek)
        }),
      )
    }
  }, [dayOfTheWeek, defaultOpeningTimes, lastDayInAWeek, specialOpeningTimes, today])

  return { today, dayOfTheWeek, lastDayInAWeek, upcomingSpecialDays }
}

export default useSpecialDays
