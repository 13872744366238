import { FunctionComponent, useEffect } from 'react'
import { useToggle } from 'react-use'

import { Link } from 'data-access/sanity/fragments/components/link.fragment'
import { cn } from 'ui/lib/utils'

import { useTranslations } from 'src/contexts/Globals.context'
import { useCookieConsentContext } from '../../../hooks/context/useCookieConsentContext'
import MisterContactMethodLink from '../../shared/site-link/MisterContactMethodLink'

interface Props {
  openingHoursHeader?: string
  openingHours?: string
  specialOpeningHours?: string
  email?: string
  phone?: string
  whatsApp?: string
  messenger?: string
  faqLink?: Link
  returnsLink?: Link
  className?: string
}

const MisterContactMethods: FunctionComponent<Props> = ({
  openingHoursHeader,
  openingHours,
  specialOpeningHours,
  email,
  phone,
  whatsApp,
  messenger,
  faqLink,
  returnsLink,
  className,
}) => {
  const translate = useTranslations()
  const [animate, setAnimate] = useToggle(false)
  const { consent, hasConsentedToCookie } = useCookieConsentContext()
  const [showChatToggle, setShowChatToggle] = useToggle(false)

  useEffect(() => {
    setShowChatToggle(hasConsentedToCookie('preferences'))
  }, [consent, hasConsentedToCookie, setShowChatToggle])

  return (
    <div className={cn('flex flex-col gap-5 md:gap-8 lg:gap-10', className)}>
      <div className='grid grid-cols-4 gap-y-5 sm:gap-x-4 md:flex md:gap-7'>
        {returnsLink && <MisterContactMethodLink icon='RefreshCw' link={returnsLink} />}
        {faqLink && <MisterContactMethodLink icon='ConciergeBell' link={faqLink} />}
        {email && <MisterContactMethodLink icon='Mail' link={{ type: 'email', newTab: true, linkText: translate('email', 'Email'), email: email }} />}
        {showChatToggle && <MisterContactMethodLink icon='MessagesSquare' onClick={() => window.Kustomer.open()} linkText='Chat' />}
        {whatsApp && (
          <MisterContactMethodLink icon='whatsapp' link={{ type: 'external', newTab: true, linkText: 'WhatsApp', externalLink: 'https://wa.me/' + whatsApp?.replace(/\s/g, '') }} />
        )}
        {phone && <MisterContactMethodLink icon='Phone' link={{ type: 'phone', linkText: translate('phone', 'Phone'), phone: phone?.replace(/\s/g, '') }} />}
        {messenger && (
          <MisterContactMethodLink
            animate={animate}
            icon='messenger'
            className='stroke-2'
            link={{ type: 'external', linkText: 'Messenger', newTab: true, externalLink: 'https://m.me/' + messenger?.replace(/\s/g, '') }}
          />
        )}
      </div>
      <div className='flex flex-col items-center gap-2 bg-brand-grey p-4 md:flex-row md:items-start md:bg-transparent md:p-0 lg:gap-4 xl:gap-8'>
        {openingHoursHeader && <span className='grow-0 text-body-sm-bold'>{openingHoursHeader}</span>}
        {openingHours && <span className='text-body-sm'>{openingHours}</span>}
        {specialOpeningHours && <span className='text-body-sm'>{specialOpeningHours}</span>}
      </div>
    </div>
  )
}

export default MisterContactMethods
