import { FunctionComponent } from 'react'

import { productTitle } from '../../utils/product.util'
import { useProductCard } from './ProductCard.context'

const MetaTags: FunctionComponent = () => {
  const { product } = useProductCard('Product card meta tags')

  const { title, category } = productTitle(product.productTitle, true)
  const linkTitle = category ? `${title} - ${category}` : title

  return (
    <>
      <meta itemProp='mpn' content={product.slug.current} />
      <meta itemProp='name' content={linkTitle} />
      <meta itemProp='brand' content='MR MARVIS' />
      <meta itemProp='description' content={[linkTitle, 'MR MARVIS', category].filter(Boolean).join(' - ')} />
    </>
  )
}

export default MetaTags
