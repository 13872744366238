import { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'

type ParamConfig = { type: 'string'; default: string } | { type: 'number'; default: number } | { type: 'boolean'; default: boolean } | { type: 'array'; default: string[] }

type ParamSchema = {
  [key: string]: ParamConfig
}

// First, create helper types to extract the actual types from the schema
type InferParamType<T extends ParamConfig> = T extends { type: 'string' }
  ? string
  : T extends { type: 'number' }
    ? number
    : T extends { type: 'boolean' }
      ? boolean
      : T extends { type: 'array' }
        ? string[]
        : never

// Type to convert schema to params object type
type SchemaToParams<T extends ParamSchema> = {
  [K in keyof T]: InferParamType<T[K]>
}

const parseQueryValue = (value: string | string[] | undefined, type: ParamConfig['type'], defaultValue: unknown) => {
  if (value === undefined || value === '') {
    return defaultValue
  }

  switch (type) {
    case 'number': {
      const num = Number(value)
      return isNaN(num) ? defaultValue : num
    }

    case 'boolean':
      if (value === 'true') return true
      if (value === 'false') return false
      return defaultValue

    case 'array':
      if (Array.isArray(value)) return value
      return [value]

    case 'string':
    default:
      return String(value)
  }
}

export const useQueryParams = <T extends ParamSchema>(schema: T) => {
  const router = useRouter()

  // is ready check
  const queryParams = useMemo(() => {
    if (!router.isReady) {
      return Object.fromEntries(Object.entries(schema).map(([key, config]) => [key, config.default])) as SchemaToParams<T>
    }

    const params: { [key: string]: unknown } = {}
    for (const [key, config] of Object.entries(schema)) {
      params[key] = parseQueryValue(router.query[key], config.type, config.default)
    }

    return params as SchemaToParams<T>
  }, [router.query, router.isReady, schema])

  // Update query params
  const setQueryParams = useCallback(
    (newParams: Partial<SchemaToParams<T>>) => {
      const updatedQuery = {
        ...router.query,
        ...newParams,
      }

      Object.keys(updatedQuery).forEach((key) => updatedQuery[key] === undefined && delete updatedQuery[key])

      router.push(
        {
          pathname: router.pathname,
          query: updatedQuery,
        },
        undefined,
        { shallow: true, scroll: false },
      )
    },
    [router],
  )

  return [queryParams, setQueryParams] as const
}
