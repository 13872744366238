import { FC } from 'react'
import { useStats } from 'react-instantsearch'

import { cn } from '@ui/lib/utils'

import { useTranslations } from '../../../contexts/Globals.context'
import SearchClearRefinements from './filters/SearchClearRefinements'

type Props = {
  className?: string
}

const SearchStats: FC<Props> = ({ className }) => {
  const translate = useTranslations()
  const { nbHits } = useStats()

  return (
    <div className={cn(className, 'flex flex-row items-center gap-2')}>
      <p className='grow text-heading-5 font-semibold'>
        {nbHits} {translate(nbHits === 1 ? 'searchItem' : 'searchItems', nbHits === 1 ? 'item' : 'items')}
      </p>
      <SearchClearRefinements className='ml-auto' />
    </div>
  )
}

export default SearchStats
