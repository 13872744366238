import { FunctionComponent } from 'react'

import { HighlightData, SectionHighlightsData } from 'data-access/sanity/fragments/sections/sectionHighlights.fragment'

import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import MisterMedia from '../../shared/media/MisterMedia'
import MisterSiteLink from '../../shared/site-link/MisterSiteLink'

type HighlightProps = HighlightData

const HighlightItem: FunctionComponent<HighlightProps> = ({ title, text, image, button }) => {
  const content = (
    <>
      <MisterMedia media={image} className='rounded-md' />
      <div className='absolute inset-0 flex flex-col justify-end gap-4 text-balance rounded-md bg-gradient-to-t from-black/90 to-transparent to-70% p-8 xl:to-50%'>
        <h3 className='line-clamp-2 text-heading-5 text-white'>{title}</h3>
        <p className='line-clamp-6 text-body-lg text-white lg:text-body-xl'>{text}</p>
      </div>
    </>
  )

  return (
    <div className='relative w-3/4 flex-shrink-0 snap-center lg:w-auto'>
      {button?.link ? (
        <MisterSiteLink
          onClick={() => trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_HIGHLIGHT_ITEM, { clickTitle: title, clickDestination: button.link?.page?.slug?.current || '' })}
          link={button.link}
          className='h-full'
        >
          {content}
        </MisterSiteLink>
      ) : (
        <div className='h-full rounded-md'>{content}</div>
      )}
    </div>
  )
}

interface SectionProps {
  data: SectionHighlightsData
}

const SectionHighlights: FunctionComponent<SectionProps> = ({ data: { title, highlights } }) => (
  <section className='mx-auto flex flex-col gap-6'>
    <h2 className='col-span-full px-4 text-heading-5 text-brand-blue lg:px-8 lg:text-heading-3'>{title}</h2>
    <div className='flex snap-x snap-mandatory gap-2.5 overflow-x-auto overflow-y-hidden px-4 scrollbar-hide lg:grid lg:grid-cols-3 lg:gap-3 lg:overflow-visible lg:px-8'>
      {highlights.map((highlight, index) => (
        <HighlightItem key={highlight.title + index} {...highlight} />
      ))}
    </div>
  </section>
)

export default SectionHighlights
