import { FunctionComponent } from 'react'

import { Image } from 'data-access/sanity/fragments/common/getImage.fragment'

interface ConicalGradientCircleProps {
  items: {
    hex: string
  }[]
}

const ConicalGradientCircle: FunctionComponent<ConicalGradientCircleProps> = ({ items }) => {
  const sliceAngle = 360 / items.length

  const gradientStops = items
    .map((item, index) => {
      const start = index * sliceAngle + 0.2
      const end = (index + 1) * sliceAngle - 0.2
      return `${item.hex ? item.hex : 'transparent'} ${start}deg, ${item.hex ? item.hex : 'transparent'} ${end}deg`
    })
    .join(', ')

  return (
    <div
      className='absolute left-0 top-0 h-full w-full'
      style={{
        background: `conic-gradient(${gradientStops})`,
        WebkitMaskImage: 'radial-gradient(circle, white, black)',
        maskImage: 'radial-gradient(circle, white, black)',
      }}
    />
  )
}

const WheelOfDivs: FunctionComponent<{
  items: {
    hex: string
    image?: Image
  }[]
}> = ({ items }) => {
  const sliceAngle = 360 / items.length
  const radius = 84
  const halfPi = Math.PI / 2

  // Pre-calculate the clipPath polygon points
  // For 3 item we do some slightly different math because of something to do with triangles... I'm not really sure why it works
  const clipPath = (angleRad: number) => {
    const x1 = (items.length == 3 ? 90 : 50) + radius * Math.cos(angleRad / 2 - halfPi)
    const y1 = 50 + radius * Math.sin(angleRad / (items.length == 3 ? 3 : 2) - halfPi)
    const x2 = 50 + radius * Math.cos(-angleRad / 2 - halfPi)
    const y2 = 50 + radius * Math.sin(-angleRad / 2 - halfPi)
    return `polygon(50% 50%, ${x1}% ${y1}%, ${x2}% ${y2}%)`
  }

  const commonStyle = {
    transformOrigin: 'center center',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    clipPath: clipPath((sliceAngle * Math.PI) / 180),
  }

  return (
    <>
      {items.map((item, index) => (
        <div
          key={index}
          className='absolute z-[1] size-full bg-cover'
          style={{
            ...commonStyle,
            backgroundImage: item.image ? `url(${item.image.url})` : undefined,
            backgroundColor: item.hex || 'transparent',
            transform: `rotate(${index * sliceAngle}deg)`,
          }}
        />
      ))}
    </>
  )
}

const StaticColorWheel: FunctionComponent<{
  items: {
    hex: string
    image?: Image
  }[]
}> = ({ items }) => {
  const hasImages = items.some((item) => item?.image)

  return <div className='relative size-[10.75rem] overflow-hidden rounded-full'>{hasImages ? <WheelOfDivs items={items} /> : <ConicalGradientCircle items={items} />}</div>
}

export default StaticColorWheel
