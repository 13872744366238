import { FC, SVGProps } from 'react'

import { cn } from '@ui/lib/utils'

type Props = SVGProps<SVGSVGElement> & {
  isActive?: boolean
}

// Make sure the parent has a 'group/filterSort' class to enable the hover-effect from the parent component
const FilterSort: FC<Props> = ({ isActive = false, ...props }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
      {/* LowerLine */}
      <path d='M3 15.5H21' stroke='currentColor' strokeWidth='1.3' strokeLinecap='round' strokeLinejoin='round' />
      {/* UpperLine */}
      <path d='M3 8.5H21' stroke='currentColor' strokeWidth='1.3' strokeLinecap='round' strokeLinejoin='round' />
      {/* LowerThumb */}
      <path
        d='M15.5 13C14.1193 13 13 14.1193 13 15.5C13 16.8807 14.1193 18 15.5 18C16.8807 18 18 16.8807 18 15.5C18 14.1193 16.8807 13 15.5 13Z'
        stroke='currentColor'
        strokeWidth='1.3'
        className={cn(
          isActive ? 'translate-x-[-7px] lg:fill-primary' : 'group-hover/filterSort:translate-x-[-7px] group-active/filterSort:translate-x-[-7px]',
          'transform fill-white transition-transform duration-300',
        )}
      />
      {/* UpperThumb */}
      <path
        d='M8.50004 6C7.1193 6 6 7.11929 6 8.50004C6 9.88071 7.1193 11 8.50004 11C9.88071 11 11 9.88071 11 8.50004C11 7.11929 9.88071 6 8.50004 6Z'
        stroke='currentColor'
        strokeWidth='1.3'
        className={cn(
          isActive ? 'translate-x-[7px] lg:fill-primary' : 'group-hover/filterSort:translate-x-[7px] group-active/filterSort:translate-x-[7px]',
          'transform fill-white transition-transform duration-300',
        )}
      />
    </svg>
  )
}

export default FilterSort
