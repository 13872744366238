interface Option {
  name: string
  value: string
}

export type CombinedOptions = Awaited<ReturnType<typeof combineOptions>>

/**
 * Combines options by name and returns an array of CombinedOption objects.
 * @param options - An array of Option objects.
 * @returns An array of CombinedOption objects.
 */
export const combineOptions = (options: Option[]) => {
  const combinedOptionsMap = options.reduce((map, { name, value }) => {
    const existingValues = map.get(name) || new Set<string>()
    existingValues.add(value)
    return map.set(name, existingValues)
  }, new Map<string, Set<string>>())

  return Array.from(combinedOptionsMap.entries()).map(([name, value]) => ({ name, values: Array.from(value).filter(Boolean) }))
}

/**
 * Converts an array of options to an object.
 * @param options - An array of Option objects.
 * @returns An object with the option names as keys and their values as values.
 */
export const optionsToObject = (options: Option[] | []): Record<string, string> => options.reduce((acc, { name, value }) => ({ [name]: value, ...acc }), {})
