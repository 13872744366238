import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import { SectionOverviewCardData, SectionOverviewCardsCollectionData, SectionOverviewCardsData } from 'data-access/sanity/fragments/sections/sectionOverviewCards.fragment'
import { sort } from 'utilities/array/sortArray'

import MisterContainer from 'src/components/shared/MisterContainer'
import { GlobalsContext } from 'src/contexts/Globals.context'
import { useCollectionPageHistory } from '../../../hooks/utils/useCollectionPageHistory'
import OverviewCards from './overview-cards'
import OverviewPills from './overview-pills'

export interface OverviewCardProps extends SectionOverviewCardData {
  className?: string
}

export interface SectionProps {
  data: SectionOverviewCardsData
}

/**
 * Sorts the collections based on the styles order from Sanity.
 */
const sortCollections = (collection: SectionOverviewCardsCollectionData, stylesOrder: string[]): SectionOverviewCardsCollectionData => {
  return {
    ...collection,
    data: sort([...collection.data], stylesOrder),
  }
}

const SectionOverviewCards: FunctionComponent<SectionProps> = ({ data }) => {
  const {
    query: { collection },
  } = useRouter()
  const { setLastVisitedPcp, lastVisitedPcp } = useCollectionPageHistory()
  const { stylesOrder } = useContext(GlobalsContext)

  /**
   * Process the collection to filter on showInLocale and sort it based on the styles order from Sanity.
   */
  const processCollection = useCallback(
    (selectedCollection: SectionOverviewCardsCollectionData) => {
      const filteredCollection = {
        ...selectedCollection,
        data: selectedCollection?.data.filter((col) => col.showInLocale) || [],
      }

      if (stylesOrder.applyOrderTo?.pcpCollectionCards) {
        return sortCollections(filteredCollection, stylesOrder.styles || [])
      }
      return filteredCollection
    },
    [stylesOrder],
  )

  /**
   * Filter and conditionally sort the initial collection and set to state
   */
  const initialCollection = useMemo(() => {
    return data.collections[0] ? processCollection(data.collections[0]) : undefined
  }, [data.collections, processCollection])
  const [dynamicCollection, setDynamicCollection] = useState<SectionOverviewCardsCollectionData | undefined>(initialCollection)

  /**
   * Determine which collection to use based on the last visited primary collection page
   * Display default collection if conditions are not met
   */
  useEffect(() => {
    if (!data.collections[0]) return

    const selectedCollection =
      lastVisitedPcp && data.isPrimaryCollectionPage && data?.collections?.length > 1
        ? data.collections.find((c) => c.slug === lastVisitedPcp) || data.collections[0]
        : data.collections[0]

    setDynamicCollection(processCollection(selectedCollection))
  }, [data.collections, lastVisitedPcp, data.isPrimaryCollectionPage, processCollection])

  /**
   * Update the last visited non-primary collection page
   */
  useEffect(() => {
    if (!data.isPrimaryCollectionPage) {
      setLastVisitedPcp(`${collection}`)
    }
  }, [data.isPrimaryCollectionPage, collection, setLastVisitedPcp])

  // Don't render if there's less than 2 items in the collection
  if (!dynamicCollection || dynamicCollection?.data?.length < 2) {
    return null
  }

  return (
    <MisterContainer type='fluid' dataLabel={data.isPrimaryCollectionPage ? 'section-overview-pills' : 'section-overview-cards'} data-id={data._id} padding={false}>
      {data.isPrimaryCollectionPage ? (
        <OverviewPills data={{ ...data, collections: [dynamicCollection] }} />
      ) : (
        <OverviewCards data={{ ...data, collections: [dynamicCollection] }} />
      )}
    </MisterContainer>
  )
}

export default SectionOverviewCards
