import { useState } from 'react'
import { useRouter } from 'next/router'

import { getErrorMessage } from 'utilities/errors/errorMessage'

import { post } from '../../utils/request.util'
import multipass from './multipass'
import useCustomer from './useCustomer'

interface State {
  response: null | Record<string, any>
  loading: boolean
  error: null | string
}

export const useLoginCustomer = (redirectIfFound = true) => {
  const { push, locale } = useRouter()
  const [state, setState] = useState<State>({ response: null, loading: false, error: null })

  // If customer is already logged in, redirect to account
  const { mutateCustomer } = useCustomer('/account', redirectIfFound)

  const loginCustomer = async (email: string, password: string, isPasswordValidation = false, checkoutUrlFromParams?: string): Promise<boolean> => {
    if (!email || !password) {
      setState({ response: null, loading: false, error: 'Email or password is missing' })

      return false
    }

    setState({ response: null, loading: true, error: null })

    try {
      const response = await mutateCustomer(
        post(
          '/api/shopify/account/login',
          {
            email,
            password,
            locale,
          },
          {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Content-Type',
          },
        ),
      )

      if (checkoutUrlFromParams && !isPasswordValidation) {
        const url = await multipass(email, checkoutUrlFromParams, locale)

        push(url)
      }

      setState({ response, loading: false, error: null })

      return true
    } catch (error) {
      console.error('useLoginCustomer failed: ', getErrorMessage(error))

      setState({
        response: null,
        loading: false,
        error: getErrorMessage(error) || 'Login email or password is incorrect',
      })

      return false
    }
  }

  return { loginCustomer, state, setState }
}

export default useLoginCustomer
