import { FunctionComponent } from 'react'

import { Upsell } from 'data-access/sanity/fragments/components/upsell.fragment'

import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import MisterImage from '../../shared/image/MisterImage'
import MisterSiteLink from '../../shared/site-link/MisterSiteLink'

export interface Props {
  upsellBanner: Upsell
  handleCartClose: () => void
}

const MisterCartUpsell: FunctionComponent<Props> = ({ upsellBanner, handleCartClose }) => {
  const { upsellCollection, upsellCollectionImage, upsellCollectionMessage, upsellLinkText } = upsellBanner

  if (!(upsellCollection && upsellCollectionImage && upsellCollectionMessage && upsellLinkText)) {
    return null
  }

  const handleClick = () => {
    trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_UPSELL_BANNER, {
      collection: upsellCollection.slug.current,
    })
    handleCartClose()
  }

  return (
    <MisterSiteLink data-label='upsell' link={{ page: upsellCollection }} onClick={handleClick}>
      <div className='flex w-full border-b border-brand-grey'>
        <MisterImage
          className='inset-0 w-auto object-cover object-center'
          mobile={upsellCollectionImage}
          priority={false}
          desktopSizes='4rem'
          mobileSizes='4rem'
          alt={upsellLinkText}
        />
        <div className='flex flex-col py-4 pl-4 pr-20 text-body-md sm:py-6 md:pl-6 2xl:pl-8'>
          <p className='mb-2'>{upsellCollectionMessage}</p>
          <span className='text-body-md-bold underline'>{upsellLinkText}</span>
        </div>
      </div>
    </MisterSiteLink>
  )
}
export default MisterCartUpsell
