import { FunctionComponent } from 'react'
import { useToggle } from 'react-use'

import { Link } from 'data-access/sanity/fragments/components/link.fragment'
import { cn } from 'ui/lib/utils'

import { MisterSurvey } from 'src/components/shared/posthog/survey'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { useTranslations } from 'src/contexts/Globals.context'

const year: string = String(new Date().getFullYear())

interface Props {
  className?: string
  extraMenuItems?: Link[]
  copyRight?: string
}

export const MisterExtraMenuItems: FunctionComponent<Props> = ({ className, extraMenuItems, copyRight }) => {
  const [showSurvey, setShowSurvey] = useToggle(false)
  const translate = useTranslations()

  if (!extraMenuItems) {
    return null
  }

  return (
    <div className={cn('flex flex-wrap items-center justify-start gap-6 text-body-sm opacity-70 md:flex-row-reverse md:justify-end md:gap-4', className)}>
      <ul className='flex flex-wrap gap-4'>
        {extraMenuItems?.map((item, i) => (
          <li key={i}>
            <MisterSiteLink key={i} link={item} className='hover:underline'>
              {item.linkText}
            </MisterSiteLink>
          </li>
        ))}
        <li key='feedback-link'>
          <button onClick={setShowSurvey} className='hover:underline'>
            {translate('feedback', 'Feedback')}
          </button>
        </li>
      </ul>
      {showSurvey && <MisterSurvey isOpen={showSurvey} onClose={() => setShowSurvey(false)} />}
      {copyRight && <span className='mr-2'>{(copyRight || '').replace('{year}', year)}</span>}
    </div>
  )
}
