// In a file like utils/portableTextUtils.ts
import { PortableTextBlock } from '@portabletext/types'

/**
 * Extracts plain text from Portable Text blocks
 * @param blocks - The Portable Text blocks to extract text from
 * @param maxLength - Optional maximum length of returned text
 * @returns Plain text extracted from Portable Text
 */
export function portableTextToText(blocks: PortableTextBlock[] | undefined, maxLength?: number): string {
  if (!blocks || !Array.isArray(blocks)) {
    return ''
  }

  const text = blocks
    .filter((block) => block._type === 'block')
    .map(
      (block) =>
        block.children
          ?.filter((child) => child._type === 'span')
          .map((span) => span.text || '')
          .join('') || '',
    )
    .join('\n\n')

  if (maxLength && text.length > maxLength) {
    return text.substring(0, maxLength) + '...'
  }

  return text
}
