import { FunctionComponent, ReactNode } from 'react'
import { useToggle } from 'react-use'

import { ProductCardProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'
import { cn } from '@ui/lib/utils'

import MisterTouchHandler from 'src/components/shared/effects/MisterTouchHandler'
import ProductCardButton from './action/ProductCardButton'
import ProductCardLink from './action/ProductCardLink'
import Badge from './Badge'
import HoverProductCardImage from './image/HoverProductCardImage'
import SingleProductCardImage from './image/SingleProductCardImage'
import MetaTags from './MetaTags'
import { ProductCardContext } from './ProductCard.context'
import ProductInfo from './ProductInfo'
import QuickAddToCart from './quick-add-to-cart/QuickAddToCart'
import QuickAddToCartDrawer from './quick-add-to-cart/QuickAddToCartDrawer'
import WishListButton from './WishListButton'

export interface ProductCardProps {
  index: number
  product: ProductCardProduct
  image?: ReactNode
  wishList?: ReactNode
  quickAddToCart?: ReactNode
  QuickAddToCartDrawer?: ReactNode
  badge?: ReactNode
  info?: ReactNode
  action?: ReactNode
  variantSku?: string
  className?: string
  liParams?: {
    [key: string]: unknown
  }
}

interface ProductCardComponent extends FunctionComponent<ProductCardProps> {
  WishListButton: typeof WishListButton
  QuickAddToCart: typeof QuickAddToCart
  QuickAddToCartDrawer: typeof QuickAddToCartDrawer
  Badge: typeof Badge
  Info: typeof ProductInfo
  SingleImage: typeof SingleProductCardImage
  HoverImage: typeof HoverProductCardImage
  Link: typeof ProductCardLink
  Button: typeof ProductCardButton
}

const ProductCard: ProductCardComponent = ({ index = 0, image, action, wishList, quickAddToCart, badge, product, info, className, variantSku, liParams }) => {
  const [isBeingDragged, setIsBeingDragged] = useToggle(false)

  if (!product) return null

  return (
    <ProductCardContext.Provider value={{ index, product, variantSku, isBeingDragged }}>
      <li
        className={cn('group/product relative h-full list-none overflow-hidden @container/product-card [container-type:inline-size]', className)}
        itemType='https://schema.org/Product'
        itemScope
        data-testid='product-card'
        {...liParams}
      >
        <MetaTags />
        <MisterTouchHandler onDragStart={() => setIsBeingDragged(true)} onDragEnd={() => setIsBeingDragged(false)}>
          {action}
        </MisterTouchHandler>
        <div className='relative w-full'>
          <div className='relative overflow-hidden rounded-md bg-brand-grey'>
            {image}
            {wishList}
            {quickAddToCart}
          </div>
          {badge}
        </div>
        {info}
      </li>
    </ProductCardContext.Provider>
  )
}

ProductCard.WishListButton = WishListButton
ProductCard.QuickAddToCart = QuickAddToCart
ProductCard.QuickAddToCartDrawer = QuickAddToCartDrawer
ProductCard.Badge = Badge
ProductCard.Info = ProductInfo
ProductCard.SingleImage = SingleProductCardImage
ProductCard.HoverImage = HoverProductCardImage
ProductCard.Link = ProductCardLink
ProductCard.Button = ProductCardButton

export default ProductCard
