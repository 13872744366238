import { FunctionComponent } from 'react'
import { ArrowRight } from 'lucide-react'
import { Highlight } from 'react-instantsearch'

import { AlgoliaFAQHit, AlgoliaPageHit } from 'data-access/algolia/types'

import MisterSiteLink from '../site-link/MisterSiteLink'

type Props = {
  item: AlgoliaFAQHit | AlgoliaPageHit
  onClick?: () => void
}

export const MisterContentResult: FunctionComponent<Props> = ({ item, onClick }) => {
  return (
    <li
      data-insights-object-id={item.objectID ?? undefined} // Algolia data attr
      data-insights-position={item.__position ?? undefined} // Algolia data attr
      data-insights-query-id={item.__queryID ?? undefined} // Algolia data attr
    >
      <MisterSiteLink
        onClick={onClick}
        href={item.url}
        title={item._type === 'faqQuestion' ? item.question : item.title}
        className='group flex h-full flex-col gap-y-4 rounded-md border border-transparent p-4 shadow-floating-menu transition-all hover:border-brand-grey hover:shadow-none active:border-brand-grey-dark'
      >
        <div className='flex items-start gap-2'>
          <h3 className='grow text-body-lg-bold text-brand-blue'>
            <Highlight attribute='title' hit={item} />
          </h3>
          <ArrowRight className='ml-auto shrink-0 text-brand-blue transition-all group-hover:translate-x-1 group-active:translate-x-1' />
        </div>

        <span className='line-clamp-5 text-body-md'>
          {/* @ts-expect-error - Somehow Algolia types are not correct here */}
          <Highlight attribute={item._type === 'faqQuestion' ? 'answer' : 'content'} hit={item} />
        </span>
      </MisterSiteLink>
    </li>
  )
}
