import { useState } from 'react'
import { useRouter } from 'next/router'

import { ShopifyAddress, ShopifyCustomerAccessToken } from 'data-access/shopify/types'
import { getErrorMessage } from 'utilities/errors/errorMessage'

import { post } from '../../utils/request.util'
import useRouterRefresh from '../utils/useRouterRefresh'
import useCustomer from './useCustomer'

export const useCustomerAddress = () => {
  const [state, setState] = useState<{ response: string | null; loading: boolean; error: string | null }>({
    response: null,
    loading: false,
    error: null,
  })

  const { locale } = useRouter()
  const update = useRouterRefresh()
  const { mutateCustomer } = useCustomer()

  const updateAddress = async ({ customerAccessToken, id, address }: { customerAccessToken: ShopifyCustomerAccessToken; id?: string; address: ShopifyAddress }) => {
    if (customerAccessToken && address) {
      setState({
        response: null,
        loading: true,
        error: null,
      })
      try {
        await mutateCustomer(
          post('/api/shopify/account/update-address', {
            customerAccessToken,
            address,
            id,
            action: id ? 'UPDATE_ADDRESS' : 'CREATE_ADDRESS',
            locale,
          }).then((res) => {
            setState({
              response: res as string,
              loading: false,
              error: null,
            })
          }),
          await update(), // re-fetch props without refreshing the page
        )
      } catch (error) {
        console.error('useCustomerAddress failed: ', getErrorMessage(error))

        setState({
          response: null,
          loading: false,
          error: getErrorMessage(error) || 'Update address failed',
        })
      }
    } else {
      setState({
        response: null,
        loading: false,
        error: 'Missing customerAccessToken, address id, or address',
      })
    }
  }

  return { updateAddress, state }
}

export default useCustomerAddress
