import React, { FunctionComponent } from 'react'
import Image from 'next/image'

import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/lib/utils'

import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { useTranslations } from 'src/contexts/Globals.context'
import { useUI } from 'src/contexts/UI.context'
import { imageQuality } from 'src/utils/image.util'

export const AccountButton: FunctionComponent = () => {
  const translate = useTranslations()
  const { displayMobileMenu } = useUI()

  return (
    <Button
      asChild
      variant='ghost'
      size='icon'
      className={cn(
        'hidden transition-[transform,opacity] duration-500 ease-out lg:block',
        displayMobileMenu && '-translate-x-10 translate-y-5 opacity-0 lg:translate-x-0 lg:translate-y-0 lg:fine-pointer:opacity-100',
      )}
    >
      <MisterSiteLink
        e2eSelector='account-link'
        className='items-center lg:flex'
        link={{ type: 'internal', page: { slug: { current: 'account' } } }}
        title={translate('account', 'Account')}
      >
        <Image src='/img/my-account.svg' alt={translate('myAccount', 'My account')} priority={false} quality={imageQuality} width={24} height={24} />
      </MisterSiteLink>
    </Button>
  )
}
