import { createContext, FunctionComponent, PropsWithChildren, useContext, useMemo } from 'react'

import { contextualizedValue, SectionLayoutData } from 'data-access/sanity/fragments/sections/sectionLayout.fragment'

export const SectionLayoutContext = createContext<SectionLayoutData | null>(null)

export const SectionLayoutContextProvider: FunctionComponent<PropsWithChildren<{ data: SectionLayoutData }>> = ({ data, children }) => (
  <SectionLayoutContext.Provider value={data}>{children}</SectionLayoutContext.Provider>
)

type BreakpointName = 'base' | 'md' | 'lg'
type ResponsiveValue = Record<BreakpointName, number>
const BREAKPOINTS = ['base', 'md', 'lg'] as const
const SCREEN_WIDTHS = { base: 480, md: 768, lg: 1024 }

type SectionWithOrder = {
  columnSpan: number
  rowSpan: number
  order?: number
  originalIndex: number
}

type GridCell = {
  occupied: boolean
  sectionIndex: number
}

export const useSectionLayout = (sectionId: string) => {
  const data = useContext(SectionLayoutContext)

  return useMemo(() => {
    if (!data) return null

    const section = data.sections?.find((section) => section._id === sectionId)
    if (!section) return null

    const responsiveValues = (array: contextualizedValue[]): ResponsiveValue => {
      return BREAKPOINTS.reduce(
        (acc, size, i) => ({
          ...acc,
          [size]: array.find(({ name }) => name === size)?.value ?? (i === 0 ? 1 : (acc[BREAKPOINTS[i - 1] as BreakpointName] ?? 1)),
        }),
        { base: 1, md: 1, lg: 1 } as ResponsiveValue,
      )
    }

    const isInFirstColumn = (targetIndex?: number, totalColumns = 4, items?: SectionWithOrder[]): boolean => {
      if (!items || targetIndex === undefined) return false

      // Sort items by order, keeping original order for items without an order value
      const sortedItems = [...items].sort((a, b) => {
        if (a.order === undefined && b.order === undefined) {
          return a.originalIndex - b.originalIndex
        }
        if (a.order === undefined) return 1
        if (b.order === undefined) return -1
        return a.order - b.order
      })

      // Initialize grid with enough rows to handle all items
      const maxRows = sortedItems.reduce((sum, item) => sum + item.rowSpan, 0)
      const grid: GridCell[][] = Array(maxRows)
        .fill(null)
        .map(() =>
          Array(totalColumns)
            .fill(null)
            .map(() => ({ occupied: false, sectionIndex: -1 })),
        )

      // Find where our target section ended up in the sorted order
      const sortedTargetIndex = sortedItems.findIndex((item) => item.originalIndex === targetIndex)
      if (sortedTargetIndex === -1) return false

      // Process all sections before our target
      for (let itemIndex = 0; itemIndex < sortedItems.length; itemIndex++) {
        const item = sortedItems[itemIndex]
        if (!item) continue
        const itemSpan = item.columnSpan
        const itemRowSpan = item.rowSpan

        // Find next available position for this item
        let placed = false
        for (let row = 0; row < grid.length && !placed; row++) {
          for (let col = 0; col < totalColumns && !placed; col++) {
            // Check if we can place the item here
            let canPlace = true

            // Check if the section fits horizontally
            if (col + itemSpan > totalColumns) {
              continue
            }

            // Check if all required cells are available
            for (let r = row; r < row + itemRowSpan && canPlace; r++) {
              for (let c = col; c < col + itemSpan; c++) {
                if (grid[r]?.[c]?.occupied) {
                  canPlace = false
                  break
                }
              }
            }

            if (canPlace) {
              // Place the item
              for (let r = row; r < row + itemRowSpan; r++) {
                for (let c = col; c < col + itemSpan; c++) {
                  // @ts-ignore
                  grid[r][c] = { occupied: true, sectionIndex: itemIndex }
                }
              }
              placed = true

              // If this is our target section, check if it's in first column
              if (itemIndex === sortedTargetIndex) {
                return col === 0
              }
            }
          }
        }
      }

      return false
    }

    const calculateSizes = (columns: ResponsiveValue, colSpan: ResponsiveValue): string =>
      Object.entries(SCREEN_WIDTHS)
        .map(([size, width]) => `(min-width: ${width}px) ${Math.floor((width / columns[size as BreakpointName]) * colSpan[size as BreakpointName] * 1.5)}px`)
        .concat('100vw')
        .join(', ')

    const sectionIndex = data.sections?.indexOf(section)
    const layoutColumns = responsiveValues(data.columns)

    const layoutDataForSection = {
      columns: layoutColumns,
      sectionSizes: {
        colSpan: responsiveValues(section.colSpan),
        rowSpan: responsiveValues(section.rowSpan),
      },
      isInFirstColumn: BREAKPOINTS.reduce(
        (acc, breakpoint) => ({
          ...acc,
          [breakpoint]: isInFirstColumn(
            sectionIndex,
            layoutColumns[breakpoint],
            data.sections?.map((section, index) => ({
              columnSpan: responsiveValues(section.colSpan)[breakpoint],
              rowSpan: responsiveValues(section.rowSpan)[breakpoint],
              order: section.orderOverride?.find((o) => o.name === breakpoint)?.value,
              originalIndex: index,
            })),
          ),
        }),
        {} as Record<BreakpointName, boolean>,
      ),
    }

    const sizes = calculateSizes(layoutDataForSection.columns, layoutDataForSection.sectionSizes.colSpan)

    return {
      layoutDataForSection,
      sizes,
    }
  }, [data, sectionId])
}
