import { Measurement, Size, Unit } from 'data-access'

import { convertToUnit } from '../../number/format/distanceConversions'
import { formatRangeValue } from './rangeValue'
import { toCamelCase } from './stringFormat'

/**
 * Formats a measurement value to a string with the unit specified.
 * @param size - The size to get the measurement value from.
 * @param measurement - The measurement to get the value from.
 * @param unit - The unit to format the measurement value in.
 * @returns The formatted measurement value.
 */
export const getMeasurementValue = (size: Size, measurement: Measurement, unit: Unit): string => {
  const value = size[toCamelCase(measurement.name)]

  if (!value) {
    return ''
  }

  if (measurement.rangeValue && typeof value === 'object' && ('fromValue' in value || 'toValue' in value)) {
    return formatRangeValue(value, unit)
  }

  return `${convertToUnit(Number(value), unit)}`
}
