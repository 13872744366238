import { FunctionComponent } from 'react'
import { ChevronRight } from 'lucide-react'
import { useRouter } from 'next/router'

import { MainMenuItem } from 'data-access/sanity/fragments/components/mainMenuItem.fragment'
import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/lib/utils'
import { convertSlug } from 'utilities/string/url'

import MisterSiteLink, { makeHref } from 'src/components/shared/site-link/MisterSiteLink'
import { useTranslations } from 'src/contexts/Globals.context'
import { useMobileMenu } from 'src/contexts/MobileMenu.context'
import { NAVIGATION_LINK_VARIANTS, TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import MenuCard from '../MenuCard'
import StylesMenu from './StylesMenu'

const CategoryMenu: FunctionComponent = () => {
  const { locale } = useRouter()
  const translation = useTranslations()
  const { handleMobileMenuClose, handleMenuChange, data, currentMainMenuItem, setCurrentCategory, activeVariant } = useMobileMenu()
  const menuItem = data?.mainMenuItems?.[currentMainMenuItem || 0] as MainMenuItem
  const { productCategories, productCategoriesV1, featuredStyles, mainMenuCards, shopBySeasonList, shopByTypeList, mainMenuItemLink } = menuItem || {}

  const isControl = activeVariant === 'control'
  const isVariant1Or2 = activeVariant && typeof activeVariant === 'string' && ['variant-1', 'variant-2'].includes(activeVariant)

  return (
    <div className={cn('flex h-full flex-col gap-10 px-4', isVariant1Or2 ? 'pt-7' : 'pt-10')}>
      <div className='flex h-full flex-col gap-10 px-0'>
        {activeVariant === 'control' && (
          <ul className='flex flex-col'>
            {productCategories?.map((productCategory, i) => (
              <li key={i}>
                <Button
                  variant='ghost'
                  className='flex items-center gap-1 px-2 py-3 text-btn-xl'
                  data-testid='mobile-menu-category-link'
                  onClick={() => {
                    trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                      clickDestination: makeHref({ _type: 'link', type: 'internal', page: { slug: productCategory.productCategory.slug, _type: 'collection' } }, locale),
                      navigationLevel: 2,
                      variant: NAVIGATION_LINK_VARIANTS.TRIGGER,
                    })
                    handleMenuChange({ type: 'styles', component: <StylesMenu /> }, 'forward'), setCurrentCategory(i)
                  }}
                >
                  <div className='flex items-center gap-2'>{productCategory.productCategoryMenuTitle}</div>
                  <ChevronRight className='size-5 stroke-[1.5px] text-brand-grey-dark' />
                </Button>
              </li>
            ))}
          </ul>
        )}
        {activeVariant === 'variant-1' && (
          <div className='flex flex-col gap-10 pb-10'>
            {productCategoriesV1?.map((category, index) => (
              <div key={index}>
                <h3 className='text-body-sm uppercase text-[#6E7482]'>{category.productCategoryMenuTitle}</h3>

                <ul className='mt-1.5 flex flex-col'>
                  <li>
                    <MisterSiteLink
                      onClick={() => {
                        const href = makeHref({ _type: 'link', type: 'internal', page: { slug: category.productCategory.slug, _type: 'collection' } }, locale)
                        trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                          clickDestination: href,
                          navigationLevel: 2,
                          variant: NAVIGATION_LINK_VARIANTS.LINK,
                        })
                        handleMobileMenuClose()
                      }}
                      link={convertSlug(category.productCategory.slug, 'collection')}
                      className='flex flex-col gap-1 rounded-md py-1.5 transition-colors'
                    >
                      <span className='text-body-lg'>{category.productCategory.title}</span>
                    </MisterSiteLink>
                  </li>

                  {category.styles?.map((style, styleIndex) => (
                    <li key={`category-link-${styleIndex}`}>
                      <MisterSiteLink
                        onClick={() => {
                          const href = makeHref({ _type: 'link', type: 'internal', page: { slug: style.slug, _type: 'collection' } }, locale)
                          trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                            clickDestination: href,
                            navigationLevel: 2,
                            variant: NAVIGATION_LINK_VARIANTS.LINK,
                          })
                          handleMobileMenuClose()
                        }}
                        link={convertSlug(style.slug, 'collection')}
                        className='flex flex-col gap-1 rounded-md py-1.5 transition-colors'
                      >
                        <span className='text-body-lg'>{style.title}</span>
                        <span className='text-body-md text-[#6E7482]'>{style.collectionDescription}</span>
                      </MisterSiteLink>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
        {activeVariant === 'variant-2' && (
          <div className='flex flex-col gap-10 pb-10'>
            {/* Shop by Type */}
            {shopByTypeList && (
              <div>
                <h3 className='text-body-sm uppercase text-[#6E7482]'>{shopByTypeList?.title}</h3>
                <ul className='mt-1.5 flex flex-col gap-2.5'>
                  {shopByTypeList?.links.map((link, i) => (
                    <MisterSiteLink
                      onClick={() => {
                        const href =
                          link._type === 'collection'
                            ? makeHref({ _type: 'link', type: 'internal', page: { slug: link.slug, _type: 'collection' } }, locale)
                            : makeHref(link, locale)
                        trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                          clickDestination: href,
                          navigationLevel: 2,
                          variant: NAVIGATION_LINK_VARIANTS.LINK,
                        })
                        handleMobileMenuClose()
                      }}
                      key={`type-${i}`}
                      link={link._type === 'collection' ? convertSlug(link.slug, 'collection') : link}
                      className='flex flex-col gap-1 rounded-md py-1.5 transition-colors'
                    >
                      <span className='text-body-lg'>{link._type === 'collection' ? link.title : link.linkText}</span>
                    </MisterSiteLink>
                  ))}
                </ul>
              </div>
            )}

            {/* Featured Styles */}
            {featuredStyles && featuredStyles.length > 0 && (
              <div>
                <h3 className='text-body-sm uppercase text-[#6E7482]'>Featured Styles</h3>
                <ul className='mt-1.5 flex flex-col'>
                  {featuredStyles.map((style, styleIndex) => (
                    <MisterSiteLink
                      onClick={() => {
                        const href = makeHref({ _type: 'link', type: 'internal', page: { slug: style.slug, _type: 'collection' } }, locale)
                        trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                          clickDestination: href,
                          navigationLevel: 2,
                          variant: NAVIGATION_LINK_VARIANTS.LINK,
                        })
                        handleMobileMenuClose()
                      }}
                      key={styleIndex}
                      link={convertSlug(style.slug, 'collection')}
                      className='flex flex-col gap-1 rounded-md py-1.5 transition-colors'
                    >
                      <span className='text-body-lg'>{style.title}</span>
                      <span className='text-body-md text-[#6E7482]'>{style.collectionDescription}</span>
                    </MisterSiteLink>
                  ))}
                </ul>
                <MisterSiteLink
                  className='relative mt-5 inline-block whitespace-normal !text-body-md underline'
                  onClick={() => {
                    const href = makeHref(mainMenuItemLink, locale)
                    trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                      clickDestination: href,
                      navigationLevel: 2,
                      variant: NAVIGATION_LINK_VARIANTS.LINK,
                    })
                    handleMobileMenuClose()
                  }}
                  link={mainMenuItemLink}
                >
                  {translation('viewAllStyles', 'View all styles')}
                </MisterSiteLink>
              </div>
            )}

            {/* Shop by Season */}
            {shopBySeasonList && (
              <div>
                <h3 className='text-body-sm uppercase text-[#6E7482]'>{shopBySeasonList?.title}</h3>
                <ul className='mt-1.5 flex flex-col'>
                  {shopBySeasonList?.links.map((link, i) => (
                    <MisterSiteLink
                      onClick={() => {
                        const href =
                          link._type === 'collection'
                            ? makeHref({ _type: 'link', type: 'internal', page: { slug: link.slug, _type: 'collection' } }, locale)
                            : makeHref(link, locale)
                        trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                          clickDestination: href,
                          navigationLevel: 2,
                          variant: NAVIGATION_LINK_VARIANTS.LINK,
                        })
                        handleMobileMenuClose()
                      }}
                      key={`season-${i}`}
                      link={link._type === 'collection' ? convertSlug(link.slug, 'collection') : link}
                      className='flex flex-col gap-1 rounded-md py-1.5 transition-colors'
                    >
                      <span className='text-body-lg'>{link._type === 'collection' ? link.title : link.linkText}</span>
                    </MisterSiteLink>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
      {isControl && (
        <div className='mt-auto flex gap-2 pb-8'>
          {mainMenuCards?.map(({ image, pageLink }, i) => (
            <MenuCard
              key={i}
              image={image}
              link={pageLink}
              text={pageLink.linkText}
              onClick={() => {
                trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                  clickDestination: makeHref(pageLink, locale),
                  navigationLevel: 2,
                  variant: NAVIGATION_LINK_VARIANTS.CARD,
                })
                handleMobileMenuClose()
              }}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default CategoryMenu
