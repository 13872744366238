import { Dispatch, FunctionComponent, SetStateAction, useId } from 'react'

import { cn } from '@ui/lib/utils'

import { useTranslations } from 'src/contexts/Globals.context'
import { ProductWithEnrichedAvailability } from 'src/domain/productCard.domain'

interface ScrollableListOptionSelectProps {
  product: ProductWithEnrichedAvailability
  selectedVariant: ProductWithEnrichedAvailability['variants'][0] | null
  setSelectedVariant: Dispatch<SetStateAction<ProductWithEnrichedAvailability['variants'][0] | null>>
}

const ScrollableListOptionSelect: FunctionComponent<ScrollableListOptionSelectProps> = ({ product, selectedVariant, setSelectedVariant }) => {
  const translate = useTranslations()
  const id = useId()

  return (
    <>
      {product.variants.map((variant) => {
        const title = variant.title || variant.options.map((option) => option.value).join(' / ')
        return (
          <div
            key={variant.shopifyVariantId}
            className={cn('flex items-center overflow-hidden rounded-md px-2 has-[:checked]:bg-brand-beige-light', !variant.isAvailable ? '' : 'hover:bg-brand-beige-light')}
            data-testid='size-select'
          >
            <input
              onChange={() => setSelectedVariant(variant)}
              className='peer hidden'
              checked={selectedVariant?.shopifyVariantId === variant.shopifyVariantId}
              disabled={!variant.isAvailable}
              type='radio'
              value={variant.shopifyVariantId}
              id={`${variant.shopifyVariantId}-${id}`}
            />
            <label
              className='m-0 flex w-full cursor-pointer justify-between py-2 text-body-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 peer-checked:font-bold peer-disabled:cursor-not-allowed peer-disabled:text-brand-grey-dark peer-disabled:opacity-50'
              tabIndex={0}
              htmlFor={`${variant.shopifyVariantId}-${id}`}
            >
              {title}
              {variant.inventory.quantity <= 5 && variant.inventory.quantity > 0 && (
                <span className='pointer-events-none whitespace-nowrap text-body-sm'>
                  {translate('onlyQuantityLeftText', 'Only {quantity} left').replace('{quantity}', variant.inventory.quantity)}
                </span>
              )}
            </label>
            {/* TODO: Once we have a design for the notify me functionality in the product card we are removing it */}
            {/* {!product.discontinued && !variant.isAvailable && (
              <Button tabIndex={atcOpen ? 0 : -1} asChild variant='link' className='whitespace-nowrap text-btn-sm'>
                <MisterSiteLink link={convertSlug(product.slug, 'product')}>{translate('notifyMeTextQuickATC', 'Notify me')}</MisterSiteLink>
              </Button>
            )} */}
          </div>
        )
      })}
    </>
  )
}

export default ScrollableListOptionSelect
