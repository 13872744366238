import { FunctionComponent } from 'react'

import { Section } from 'data-access/sanity/fragments/pages/sections.fragment'
import { SectionHalfAndHalfData } from 'data-access/sanity/fragments/sections/sectionHalfAndHalf.fragment'
import { cn } from '@ui/lib/utils'

import MisterContainer from 'src/components/shared/MisterContainer'
import MisterHalfAndHalf from 'src/components/shared/MisterHalfAndHalf'
import MisterSectionLoop from 'src/components/shared/sections/MisterSectionLoop'

interface Props {
  data: SectionHalfAndHalfData
  index: number
}

const SectionHalfAndHalf: FunctionComponent<Props> = ({ data }) => {
  const { leftSections, rightSections } = data

  const hasSectionAftership = (sections: Section[]) => {
    return sections?.some(({ _type }) => _type === 'sectionAftership')
  }

  const hasSectionReturnForm = (sections: Section[]) => {
    return sections?.some(({ _type }) => _type === 'sectionReturnForm')
  }

  return (
    <MisterContainer type='fluid' padding={false}>
      <MisterHalfAndHalf
        leftHalf={<MisterSectionLoop sections={leftSections} as='article' />}
        rightHalf={<MisterSectionLoop sections={rightSections} as='article' />}
        leftHalfClassName={cn(hasSectionAftership(leftSections) && 'bg-brand-beige-light', !hasSectionReturnForm(leftSections) ? 'px-4' : 'px-0')}
        rightHalfClassName={cn(hasSectionAftership(rightSections) && 'bg-brand-beige-light', !hasSectionReturnForm(rightSections) ? 'px-4' : 'px-0')}
        distribution={data.distribution}
      />
    </MisterContainer>
  )
}

export default SectionHalfAndHalf
