import { FunctionComponent, PropsWithChildren } from 'react'
import { ToastContainer } from 'react-toastify'

// import MisterRedirectionNotice from './MisterRedirectionNotice'

import ShopTheLookDrawer from '../../features/shop-the-look/ShopTheLookDrawer'
import MisterElevar from '../scripts/MisterElevar'
import MisterScriptsBodyStart from '../scripts/MisterScriptsBodyStart'
import MisterAnnouncementBar from './announcement/MisterAnnouncementBar'
import Header from './header/Header'
import ScrollHeaderWrapper from './header/ScrollHeaderWrapper'

import 'react-toastify/dist/ReactToastify.css'

import { LayoutProps } from 'src/domain/layoutPropsMapper.domain'
import MisterCookieBanner from '../legal/MisterCookieBanner'
import MisterCart from './cart/MisterCart'
import MisterFooter from './footer/MisterFooter'
import MisterPreview from './preview/MisterPreview'

const MisterLayout: FunctionComponent<PropsWithChildren<LayoutProps>> = ({
  navigation,
  footer,
  preview,
  defaultAnnouncementBar,
  cart,
  children,
  cookieBanner,
  averageRating,
  showFooterRecommendations = true,
}) => (
  <>
    <MisterScriptsBodyStart />
    <MisterElevar />
    {/* <MisterRedirectionNotice /> */}
    <ScrollHeaderWrapper
      announcementBar={defaultAnnouncementBar && <MisterAnnouncementBar defaultAnnouncementBar={defaultAnnouncementBar} secondaryMenuItems={navigation?.secondaryMenuItems} />}
      header={navigation && <Header data={navigation} preview={preview} />}
    />
    <main>{children}</main>
    {footer && <MisterFooter data={footer} averageRating={averageRating} showFooterRecommendations={showFooterRecommendations} />}
    {cart && <MisterCart data={cart} />}
    <ToastContainer position='bottom-center' hideProgressBar={true} newestOnTop={true} closeButton={false} className='text-body-lg drop-shadow-lg' />
    {cookieBanner && <MisterCookieBanner content={cookieBanner} />}
    {preview && <MisterPreview />}
    <ShopTheLookDrawer />
  </>
)

export default MisterLayout
