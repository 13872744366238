import { FunctionComponent } from 'react'
import Image from 'next/image'

import { sanityImageLoader } from 'data-access/sanity/clients/sanityImageUrlBuilder'
import { SectionProductCardData } from 'data-access/sanity/fragments/sections/sectionProductCard.fragment'
import { cn } from '@ui/lib/utils'

import { useSectionLayout } from 'src/contexts/SectionLayout.context'
import ProductCard from 'src/features/product-card/ProductCard'

interface SectionProductCardProps {
  data: SectionProductCardData
  index: number
}

const SectionProductCard: FunctionComponent<SectionProductCardProps> = ({ data, index }) => {
  const { sizes } = useSectionLayout(data._id) || {}
  return (
    <section className='group grid size-full grid-cols-1 grid-rows-1'>
      <ProductCard
        index={index}
        product={{ ...data.product, _id: `${data.product._id}-${data._id}` }}
        quickAddToCart={<ProductCard.QuickAddToCart />}
        wishList={<ProductCard.WishListButton />}
        image={<ProductCard.SingleImage imageSizes={sizes || '100vw'} className={cn(data.square && 'aspect-square')} />}
        badge={<ProductCard.Badge />}
        info={<ProductCard.Info />}
        action={<ProductCard.Link />}
      />
      {data.hoverImage && (
        <Image
          className='col-span-1 col-start-1 row-span-1 row-start-1 object-cover transition-opacity group-focus-within:opacity-0 group-hover:opacity-0'
          src={data.hoverImage.url}
          sizes={sizes || '100vw'}
          loader={sanityImageLoader}
          fill
          alt=''
        />
      )}
    </section>
  )
}

export default SectionProductCard
