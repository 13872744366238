import React, { FunctionComponent } from 'react'
import dynamic from 'next/dynamic'
import { useToggle } from 'react-use'

import { SectionCookieSettingsData } from 'data-access/sanity/fragments/sections/sectionCookieSettings.fragment'
import { Button } from '@ui/components/ui/button'

import { useCookieConsentContext } from '../../../hooks/context/useCookieConsentContext'
import MisterContainer from '../../shared/MisterContainer'
import MisterSlideOverPanel from '../../shared/panel/MisterSlideOverPanel'

const MisterCookieList = dynamic(() => import('../../legal/MisterCookieList'))

interface Props {
  data: SectionCookieSettingsData
}

const SectionCookieSettings: FunctionComponent<Props> = ({ data }) => {
  const title = data?.title || 'Cookie Policy'
  const customiseLabel = data?.customiseLabel || 'Customise cookies'
  const viewCookieListLabel = data?.viewCookieListLabel || 'View cookie list'
  const cookieListTitle = data?.cookieListTitle || 'Cookie list'
  const [viewCookieList, setViewCookieList] = useToggle(false)
  const { startCustomise } = useCookieConsentContext()

  return (
    <MisterContainer
      dataLabel='section-cookie-settings'
      as='header'
      data-id={data._id}
      type='small'
      className='relative flex flex-col gap-4 space-y-2 pb-14 pt-8 text-left 2xl:space-y-8 2xl:pb-20 2xl:pt-14'
    >
      <h1 className='text-heading-3 font-bold'>{title}</h1>
      <div className='grid max-w-lg grid-cols-2 items-center gap-4 lg:w-4/5'>
        <Button onClick={startCustomise}>{customiseLabel}</Button>
        <Button variant='outline' onClick={() => setViewCookieList(true)}>
          {viewCookieListLabel}
        </Button>
      </div>
      <MisterSlideOverPanel title={cookieListTitle} visible={viewCookieList} onClose={() => setViewCookieList(false)} size='large'>
        <div className='space-y-4 overflow-y-scroll text-body-xs md:space-y-6 lg:space-y-8 lg:text-body-sm 2xl:space-y-14'>
          <MisterCookieList
            cookieTypes={data?.cookieTypes || []}
            labelTableHeaderName={data?.labelTableHeaderName || 'Name'}
            labelTableHeaderProvider={data?.labelTableHeaderProvider || 'Provider / domain'}
            labelTableHeaderCookies={data?.labelTableHeaderCookies || 'Cookies'}
          />
        </div>
      </MisterSlideOverPanel>
    </MisterContainer>
  )
}

export default SectionCookieSettings
