import { Dispatch, FunctionComponent, ReactElement, SetStateAction, useContext, useEffect, useState } from 'react'
import { ChevronRight } from 'lucide-react'
import { useRouter } from 'next/router'

import { LinkWithShowInLocales } from 'data-access/sanity/fragments/components/link.fragment'
import { MainMenuItem, StyleMenu } from 'data-access/sanity/fragments/components/mainMenuItem.fragment'
import { Badge } from '@ui/components/ui/badge'
import { Button } from '@ui/components/ui/button'
import { NavigationMenuContent, NavigationMenuLink } from '@ui/components/ui/navigation-menu'
import { cn } from '@ui/lib/utils'
import { sort } from 'utilities/array/sortArray'
import { convertSlug } from 'utilities/string/url'

import MisterHoverDelay from 'src/components/shared/effects/MisterHoverDelay'
import MisterSiteLink, { makeHref } from 'src/components/shared/site-link/MisterSiteLink'
import { GlobalsContext, useTranslations } from 'src/contexts/Globals.context'
import { getDiscountCampaignByCollectionId } from 'src/utils/discountCampaign.util'
import { NAVIGATION_LINK_VARIANTS, TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import { DiscountBadge } from '../../announcement/DiscountBadge'
import { ActiveVariant } from '../Header'
import MegaMenuLevelOne from './MegaMenuLevelOne'
import { MegaMenuLevelThree } from './MegaMenuLevelThree'
import { MegaMenuLevelTwo } from './MegaMenuLevelTwo'

interface DesktopMenuContentProps {
  mainMenuItem: MainMenuItem
  activeStyle: number | null
  setActiveStyle: Dispatch<SetStateAction<number | null>>
  capturedNavigationMenuState: string
  activeVariant: ActiveVariant
}

export const DesktopMenuContent: FunctionComponent<DesktopMenuContentProps> = ({ mainMenuItem, activeStyle, setActiveStyle, capturedNavigationMenuState, activeVariant }) => {
  const { locale, push } = useRouter()
  const translation = useTranslations()
  const { stylesOrder, discountCampaigns } = useContext(GlobalsContext)
  const [localActiveProductCategory, setLocalActiveProductCategory] = useState<number>(0)
  const [hoveredStyle, setHoveredStyle] = useState<StyleMenu | null>(null)
  const [hoveredStyleId, setHoveredStyleId] = useState<string | null>(null)

  const levelOneMenu = <MegaMenuLevelOne key='first' activeVariant={activeVariant} mainMenuCards={mainMenuItem.mainMenuCards} />
  const levelTwoMenu = (i: number) =>
    mainMenuItem.productCategories && mainMenuItem.productCategories[i] ? (
      <MegaMenuLevelTwo key={i} activeVariant={activeVariant} productCategory={mainMenuItem.productCategories[i]} />
    ) : null
  const levelThreeMenu = (style: StyleMenu) => (style ? <MegaMenuLevelThree activeVariant={activeVariant} key={style.slug.current} style={style} /> : null)

  const [NextSection, setNextSection] = useState<ReactElement<any, any> | null>(null)
  const [CurrentSection, setCurrentSection] = useState(levelOneMenu)

  const totalStyles = mainMenuItem?.productCategoriesV1?.every((category) => (category?.styles?.length ?? 0) <= 2)

  let hoverTimeout: NodeJS.Timeout | null = null

  const resetSections = () => {
    if (NextSection) {
      setCurrentSection(NextSection)
      setNextSection(null)
    }
  }

  useEffect(() => {
    setLocalActiveProductCategory(0)
    setCurrentSection(levelOneMenu)
    setNextSection(null)
  }, [capturedNavigationMenuState])

  const handleLevelTwoHover = (i: number, keepCards = false) => {
    setLocalActiveProductCategory(i)
    setActiveStyle(null)
    if ((mainMenuItem.productCategories ?? [])[i] && CurrentSection?.key !== `${i}`) {
      if (CurrentSection.key === levelOneMenu.key && keepCards) {
        setCurrentSection(levelOneMenu)
      } else {
        setNextSection(keepCards ? levelOneMenu : levelTwoMenu(i))
      }
    }
  }

  const handleStyleHover = (style: StyleMenu, uniqueId: string) => {
    setHoveredStyle(style)
    setHoveredStyleId(uniqueId)
    setNextSection(levelThreeMenu(style))
  }

  const resetStyleHover = () => {
    setHoveredStyle(null)
    setHoveredStyleId(null)
    setNextSection(null)
  }
  const renderMenuContent = () => {
    if (activeVariant === 'variant-1' && (mainMenuItem.productCategoriesV1?.length ?? 0) >= 1) {
      return (
        <>
          <div
            className={cn(
              'col-span-full',
              mainMenuItem.productCategoriesV1?.length === 1 && 'columns-1',
              mainMenuItem.productCategoriesV1?.length === 2 && totalStyles ? 'columns-1' : 'columns-2',
              (mainMenuItem.productCategoriesV1?.length ?? 0) > 2 && 'columns-3',
              (mainMenuItem.productCategoriesV1?.length ?? 0) > 4 && 'columns-4',
            )}
          >
            {mainMenuItem.productCategoriesV1?.map((productCategory, i) => (
              <div className='flex break-inside-avoid flex-col pb-10' key={`product-category-${productCategory.productCategoryMenuTitle}`}>
                <MisterHoverDelay delay={50}>
                  <NavigationMenuLink>
                    <MisterSiteLink
                      onClick={() => {
                        const href = makeHref(
                          {
                            _type: 'link',
                            type: 'internal',
                            page: {
                              slug: productCategory.productCategory.slug,
                              _type: 'collection',
                            },
                          },
                          locale,
                        )

                        trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                          clickDestination: href,
                          navigationLevel: 2,
                          variant: NAVIGATION_LINK_VARIANTS.LINK,
                        })
                      }}
                      onMouseEnter={() => handleLevelTwoHover(i, true)}
                      link={convertSlug(productCategory.productCategory.slug, 'collection')}
                      className='text-body-lg-bold'
                    >
                      {productCategory.productCategoryMenuTitle}
                    </MisterSiteLink>
                  </NavigationMenuLink>
                </MisterHoverDelay>
                <ul className='mt-2.5 flex flex-col'>
                  {productCategory.styles?.map((style) => (
                    <li key={`${productCategory.productCategoryMenuTitle}-${style.slug.current}`} className='flex gap-2 py-1'>
                      <MisterHoverDelay delay={50}>
                        <Button
                          onMouseEnter={() => {
                            if (hoverTimeout) {
                              clearTimeout(hoverTimeout)
                              hoverTimeout = null
                            }
                            handleStyleHover(style, `${productCategory.productCategoryMenuTitle}-${style.slug.current}`)
                          }}
                          onMouseLeave={() => {
                            hoverTimeout = setTimeout(() => {
                              resetStyleHover()
                              handleLevelTwoHover(i, true)
                            }, 150)
                          }}
                          variant='link'
                          hover='underline'
                          data-testid='desktop-menu-category-link'
                          className={cn(
                            'inline whitespace-normal',
                            hoveredStyle?.slug.current === style.slug.current &&
                              hoveredStyleId === `${productCategory.productCategoryMenuTitle}-${style.slug.current}` &&
                              'bg-[length:100%_0.05rem]',
                          )}
                          asChild
                        >
                          <NavigationMenuLink
                            href={makeHref({
                              _type: 'link',
                              type: 'internal',
                              page: {
                                slug: style.slug,
                                _type: 'collection',
                              },
                            })}
                            onClick={(e) => {
                              e.preventDefault()
                              const href = makeHref(
                                {
                                  _type: 'link',
                                  type: 'internal',
                                  page: {
                                    slug: style.slug,
                                    _type: 'collection',
                                  },
                                },
                                locale,
                              )

                              trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                                clickDestination: href,
                                navigationLevel: 2,
                                variant: NAVIGATION_LINK_VARIANTS.LINK,
                              })
                              push(href)
                            }}
                            className='relative !text-body-md'
                          >
                            {style.title}
                          </NavigationMenuLink>
                        </Button>
                      </MisterHoverDelay>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </>
      )
    }

    if (activeVariant === 'variant-2') {
      // TODO: This is a temporary fix to filter out links that are not shown in the current locale.
      const filteredShopByTypeList = mainMenuItem?.shopByTypeList?.links?.filter((link) => (link as LinkWithShowInLocales)?.page?.showInLocales ?? true)
      const filteredShopBySeasonList = mainMenuItem?.shopBySeasonList?.links?.filter((link) => (link as LinkWithShowInLocales)?.page?.showInLocales ?? true)

      return (
        <>
          <div className='col-span-2'>
            {/* Shop by Type List */}
            {mainMenuItem?.shopByTypeList && filteredShopByTypeList && filteredShopByTypeList?.length > 0 && (
              <div className='flex flex-col pb-10'>
                <h3 className='text-body-sm uppercase text-brand-grey-dark'>{mainMenuItem.shopByTypeList.title}</h3>
                <ul className='mt-2.5 flex flex-col'>
                  {filteredShopByTypeList.map((link, i) => (
                    <li key={`type-${i}`} className='gap-2 py-1'>
                      <MisterHoverDelay delay={50}>
                        <Button onMouseEnter={() => handleLevelTwoHover(i, true)} variant='link' hover='underline' className='inline whitespace-normal' asChild>
                          <NavigationMenuLink
                            href={
                              link._type === 'collection'
                                ? makeHref({ _type: 'link', type: 'internal', page: { slug: link.slug, _type: 'collection' } }, locale)
                                : makeHref(link, locale)
                            }
                            onClick={() => {
                              const href =
                                link._type === 'collection'
                                  ? makeHref({ _type: 'link', type: 'internal', page: { slug: link.slug, _type: 'collection' } }, locale)
                                  : makeHref(link, locale)

                              trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                                clickDestination: href,
                                navigationLevel: 2,
                                variant: NAVIGATION_LINK_VARIANTS.LINK,
                              })
                            }}
                            className='relative !text-body-md'
                          >
                            {link._type === 'collection' ? link.title : link.linkText}
                          </NavigationMenuLink>
                        </Button>
                      </MisterHoverDelay>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Shop by Season List */}
            {mainMenuItem?.shopBySeasonList && filteredShopBySeasonList && filteredShopBySeasonList?.length > 0 && (
              <div className='flex flex-col pb-10'>
                <h3 className='text-body-sm uppercase text-brand-grey-dark'>{mainMenuItem.shopBySeasonList.title}</h3>
                <ul className='mt-2.5 flex flex-col'>
                  {filteredShopBySeasonList.map((link, i) => (
                    <li key={`shop-by-season-${i}`} className='py-1'>
                      <MisterHoverDelay delay={50}>
                        <Button onMouseEnter={() => resetSections()} variant='link' hover='underline' className='inline whitespace-normal' asChild>
                          <MisterSiteLink
                            onClick={() => {
                              const href =
                                link._type === 'collection'
                                  ? makeHref({ _type: 'link', type: 'internal', page: { slug: link.slug, _type: 'collection' } }, locale)
                                  : makeHref(link, locale)

                              trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                                clickDestination: href,
                                navigationLevel: 2,
                                variant: NAVIGATION_LINK_VARIANTS.LINK,
                              })
                            }}
                            link={link._type === 'collection' ? convertSlug(link.slug, 'collection') : link}
                            className='relative !text-body-md'
                          >
                            {link._type === 'collection' ? link.title : link.linkText}
                          </MisterSiteLink>
                        </Button>
                      </MisterHoverDelay>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* Right Column - Featured Styles */}
          {mainMenuItem?.featuredStyles && mainMenuItem?.featuredStyles?.length > 0 && (
            <div className='col-span-2 col-start-4 flex flex-col pb-10'>
              <h3 className='text-body-sm uppercase text-brand-grey-dark'>{translation('featuredStyles', 'Featured Styles')}</h3>
              <ul className='mt-2.5 flex flex-col'>
                {mainMenuItem.featuredStyles.map((style, i) => (
                  <li key={`style-${i}`} className='flex gap-2 py-1'>
                    <MisterHoverDelay delay={50}>
                      <Button
                        onMouseEnter={() => {
                          if (hoverTimeout) {
                            clearTimeout(hoverTimeout)
                            hoverTimeout = null
                          }
                          handleStyleHover(style, `featured-style-${i}`)
                        }}
                        onMouseLeave={() => {
                          hoverTimeout = setTimeout(() => {
                            resetStyleHover()
                            handleLevelTwoHover(0, true)
                          }, 150)
                        }}
                        variant='link'
                        hover='underline'
                        data-testid='desktop-menu-featured-style-link'
                        className={cn(
                          'inline whitespace-normal',
                          hoveredStyle?.slug.current === style.slug.current && hoveredStyleId === `featured-style-${i}` && 'bg-[length:100%_0.05rem]',
                        )}
                        asChild
                      >
                        <NavigationMenuLink
                          href={makeHref(
                            {
                              _type: 'link',
                              type: 'internal',
                              page: {
                                slug: style.slug,
                                _type: 'collection',
                              },
                            },
                            locale,
                          )}
                          onClick={(e) => {
                            e.preventDefault()

                            const href = makeHref(
                              {
                                _type: 'link',
                                type: 'internal',
                                page: {
                                  slug: style.slug,
                                  _type: 'collection',
                                },
                              },
                              locale,
                            )

                            trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                              clickDestination: href,
                              navigationLevel: 3,
                              variant: NAVIGATION_LINK_VARIANTS.LINK,
                            })
                            push(href)
                          }}
                          className='relative !text-body-md'
                        >
                          {style.title}
                        </NavigationMenuLink>
                      </Button>
                    </MisterHoverDelay>
                  </li>
                ))}
              </ul>

              {/* View All Styles Link */}
              <MisterHoverDelay delay={50}>
                <NavigationMenuLink>
                  <MisterSiteLink
                    onClick={() => {
                      const href = makeHref(mainMenuItem.mainMenuItemLink, locale)

                      trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, { clickDestination: href })
                    }}
                    onMouseEnter={() => handleLevelTwoHover(0, true)}
                    className='relative mt-2.5 inline-block whitespace-normal !text-body-md underline'
                    link={mainMenuItem.mainMenuItemLink}
                  >
                    {translation('viewAllStyles', 'View all styles')}
                  </MisterSiteLink>
                </NavigationMenuLink>
              </MisterHoverDelay>
            </div>
          )}
        </>
      )
    }

    if (activeVariant === 'control') {
      return (
        <>
          <div className='col-span-1 mt-10 flex flex-col gap-4' />
          {(mainMenuItem.productCategories?.length ?? 0) > 1 ? (
            <ul className='col-span-2 flex flex-col gap-4'>
              <h3 className='text-body-sm uppercase text-brand-grey-dark'>{translation('shopByType', 'Shop by type')}</h3>
              {mainMenuItem.productCategories?.map((productCategory, i) => (
                <li key={i} className='flex gap-2'>
                  <MisterHoverDelay delay={50}>
                    <Button
                      onMouseEnter={() => handleLevelTwoHover(i, true)}
                      variant='link'
                      hover='underline'
                      data-testid='desktop-menu-category-link'
                      className={cn('inline whitespace-normal', localActiveProductCategory === i && 'bg-[length:100%_0.05rem]')}
                      asChild
                    >
                      <NavigationMenuLink
                        href={makeHref({ _type: 'link', type: 'internal', page: { slug: productCategory?.productCategory?.slug, _type: 'collection' } }, locale)}
                        onClick={(e) => {
                          e.preventDefault()
                          const href = makeHref({ _type: 'link', type: 'internal', page: { slug: productCategory.productCategory.slug, _type: 'collection' } }, locale)

                          trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                            clickDestination: href,
                            navigationLevel: 2,
                            variant: NAVIGATION_LINK_VARIANTS.LINK,
                          })
                          push(href)
                        }}
                        className='relative'
                      >
                        {productCategory.productCategoryMenuTitle}
                      </NavigationMenuLink>
                    </Button>
                  </MisterHoverDelay>
                  <Button variant='ghost' onClick={() => handleLevelTwoHover(i, true)} className='flex w-0 overflow-hidden !bg-none p-0 focus:w-4'>
                    <ChevronRight className='block size-4' />
                  </Button>
                </li>
              ))}
            </ul>
          ) : null}
          {localActiveProductCategory !== null && mainMenuItem?.productCategories?.[localActiveProductCategory] && (
            <ul className='col-span-3 flex flex-col gap-4'>
              {mainMenuItem.productCategories?.length > 1 && <h3 className='text-body-sm uppercase text-brand-grey-dark'>{translation('shopByStyle', 'Shop by style')}</h3>}
              {(mainMenuItem?.productCategories?.[localActiveProductCategory]?.styles?.length ?? 0) > 1 && (
                <li>
                  <MisterHoverDelay delay={50}>
                    <NavigationMenuLink asChild>
                      <Button
                        onMouseEnter={() => handleLevelTwoHover(localActiveProductCategory)}
                        variant='link'
                        hover='underline'
                        data-testid='desktop-menu-style-link'
                        className={cn('inline whitespace-normal hover:bg-[length:100%_0.05rem]')}
                        asChild
                      >
                        <MisterSiteLink
                          onClick={() => {
                            const href = makeHref(
                              {
                                _type: 'link',
                                type: 'internal',
                                page: { slug: mainMenuItem?.productCategories?.[localActiveProductCategory]?.productCategory.slug, _type: 'collection' },
                              },
                              locale,
                            )

                            trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                              clickDestination: href,
                              navigationLevel: 3,
                              variant: NAVIGATION_LINK_VARIANTS.LINK,
                            })
                          }}
                          link={convertSlug(mainMenuItem?.productCategories?.[localActiveProductCategory]?.productCategory?.slug, 'collection')}
                          className='relative'
                        >
                          {mainMenuItem?.productCategories?.[localActiveProductCategory]?.productCategory?.title}
                        </MisterSiteLink>
                      </Button>
                    </NavigationMenuLink>
                  </MisterHoverDelay>
                </li>
              )}
              {(stylesOrder.applyOrderTo?.header
                ? sort(mainMenuItem.productCategories[localActiveProductCategory]?.styles ?? [], stylesOrder.styles || [])
                : mainMenuItem.productCategories[localActiveProductCategory]?.styles
              )?.map((style, i) => {
                const discountCampaign = getDiscountCampaignByCollectionId(discountCampaigns, style._id)

                return (
                  <li className='flex items-start gap-3' key={i}>
                    <MisterHoverDelay delay={50}>
                      <NavigationMenuLink asChild>
                        <Button
                          onMouseEnter={() => handleStyleHover(style, `control-style-${localActiveProductCategory}-${i}`)}
                          variant='link'
                          hover='underline'
                          data-testid='desktop-menu-style-link'
                          className={cn(
                            'inline whitespace-normal',
                            hoveredStyle?.slug?.current === style?.slug?.current &&
                              hoveredStyleId === `control-style-${localActiveProductCategory}-${i}` &&
                              'bg-[length:100%_0.05rem]',
                          )}
                          asChild
                        >
                          <MisterSiteLink
                            onClick={() => {
                              const href = makeHref({ _type: 'link', type: 'internal', page: { slug: style.slug, _type: 'collection' } }, locale)
                              trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                                clickDestination: href,
                                navigationLevel: 3,
                                variant: NAVIGATION_LINK_VARIANTS.LINK,
                              })
                            }}
                            link={convertSlug(style.slug, 'collection')}
                            className='relative'
                          >
                            {style.title}
                          </MisterSiteLink>
                        </Button>
                      </NavigationMenuLink>
                    </MisterHoverDelay>
                    {style.new ? (
                      <Badge size='sm' className='mt-1 h-5 bg-brand-blue'>
                        {translation('new', 'New')}
                      </Badge>
                    ) : discountCampaign && discountCampaign?.showInNavigation ? (
                      <DiscountBadge discount={discountCampaign?.discount} backgroundColor={discountCampaign?.discountPillBackgroundColor} />
                    ) : null}
                  </li>
                )
              })}
            </ul>
          )}
        </>
      )
    }
  }

  return (
    <NavigationMenuContent className='group/menu-content'>
      <div className='mx-auto grid max-h-screen min-h-[30.5625rem] max-w-[90rem] grid-cols-12 gap-3 overflow-y-scroll py-8 scrollbar-hide lg:px-8 xl:px-[4.5rem] 2xl:min-h-[32rem]'>
        <div
          className={cn(
            activeVariant === 'control' && 'col-span-6 grid-cols-6',
            activeVariant === 'variant-1' && 'col-span-6 grid-cols-6',
            activeVariant === 'variant-1' && mainMenuItem.productCategoriesV1?.length === 1 && 'col-span-4 col-start-4',
            activeVariant === 'variant-1' && mainMenuItem.productCategoriesV1?.length === 2 ? (totalStyles ? 'col-span-3 col-start-4' : 'col-span-4 col-start-3') : null,
            activeVariant === 'variant-1' && (mainMenuItem.productCategoriesV1?.length ?? 0) > 2 && 'col-span-6 col-start-2',
            activeVariant === 'variant-1' && (mainMenuItem.productCategoriesV1?.length ?? 0) > 4 && 'col-span-7 col-start-2',
            activeVariant === 'variant-2' && 'col-span-4 col-start-3',
            'grid gap-2 ease-in-out animate-in fade-in-0 slide-in-from-bottom-5 fill-mode-both [animation-duration:500ms] group-data-[motion]/menu-content:animate-none group-data-[state=closed]/menu:animate-out group-data-[state=closed]/menu:fade-out-5 group-data-[state=closed]/menu:slide-out-to-top-5 group-data-[state=open]/menu:[animation-delay:300ms]',
          )}
        >
          {renderMenuContent()}
        </div>

        <div
          data-level={localActiveProductCategory}
          className={cn(
            activeVariant === 'variant-1' && 'col-span-3',
            activeVariant === 'variant-1' && mainMenuItem.productCategoriesV1?.length === 2 ? (totalStyles ? 'col-span-3 col-start-7' : 'col-span-3 col-start-8') : null,
            activeVariant === 'variant-1' && (mainMenuItem.productCategoriesV1?.length ?? 0) > 2 && 'col-span-3 col-start-9',
            activeVariant === 'variant-1' && (mainMenuItem.productCategoriesV1?.length ?? 0) > 4 && 'col-span-3 col-start-10',
            activeVariant === 'variant-2' && 'col-span-3 col-start-8',
            activeVariant === 'control' && 'col-span-6',
            'relative gap-3 ease-in-out animate-in fade-in-0 slide-in-from-top-5 fill-mode-both [animation-duration:500ms] group-data-[motion]/menu-content:animate-none group-data-[state=closed]/menu:animate-out group-data-[state=closed]/menu:fade-out-5 group-data-[state=closed]/menu:slide-out-to-bottom-5 group-data-[state=open]/menu:[animation-delay:300ms]',
          )}
        >
          <div
            data-motion={NextSection ? 'exit' : null}
            className='absolute inset-0 h-full gap-10 data-[motion=enter]:duration-300 data-[motion=exit]:duration-300 data-[motion=enter]:animate-in data-[motion=exit]:animate-out data-[motion=enter]:fade-in data-[motion=exit]:fade-out data-[motion=enter]:fill-mode-forwards data-[motion=exit]:fill-mode-forwards'
            onAnimationEnd={resetSections}
          >
            {CurrentSection}
          </div>
          {NextSection && (
            <div
              data-motion='enter'
              className='absolute inset-0 h-full gap-10 data-[motion=enter]:duration-300 data-[motion=exit]:duration-300 data-[motion=enter]:animate-in data-[motion=exit]:animate-out data-[motion=enter]:fade-in data-[motion=exit]:fade-out data-[motion=enter]:fill-mode-forwards data-[motion=exit]:fill-mode-forwards'
              onAnimationEnd={resetSections}
            >
              {NextSection}
            </div>
          )}
        </div>
      </div>
    </NavigationMenuContent>
  )
}
