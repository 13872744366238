import { FunctionComponent } from 'react'

import { SectionType } from 'data-access/sanity/fragments/common/sectionTypes.fragment'

import Sections from '../../sections/sections'

interface Props<S = SectionType> {
  index: number
  section: S
  // rest
  [key: string]: any
}

interface SectionLocalizerProps<S = any> {
  data: Props<S>['section']
  index: number
  // rest
  [key: string]: any
}

const sectionLocalizer: FunctionComponent<SectionLocalizerProps> = ({ data, index }) => (data.section ? <Sections index={index} section={data.section} /> : null)

export default sectionLocalizer
