import { FunctionComponent } from 'react'

import { Button } from '@ui/components/ui/button'

type Props = {
  term: string
  onClick: (searchTerm: string) => void
}

export const MisterSearchTerm: FunctionComponent<Props> = ({ term, onClick }) => {
  return (
    <li className='relative text-body-md'>
      <Button
        title={term}
        onClick={() => {
          onClick(term)
        }}
        className='w-full max-w-[200px] rounded-full border-0 bg-brand-blue-pale px-5 py-3 text-left text-body-md capitalize text-brand-blue'
      >
        <span className='block truncate'>{term}</span>
      </Button>
    </li>
  )
}
