export const getContrastTextColor = (hexBackground: string) => {
  const hex = hexBackground.replace('#', '')
  const r = parseInt(hex.substr(0, 2), 16)
  const g = parseInt(hex.substr(2, 2), 16)
  const b = parseInt(hex.substr(4, 2), 16)

  const yiq = (r * 299 + g * 587 + b * 114) / 1000

  const brandBlue = '#071933'
  const white = '#ffffff'

  return yiq >= 128 ? brandBlue : white
}
