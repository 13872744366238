import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'

import { SectionStoriesData } from 'data-access/sanity/fragments/sections/sectionStories.fragment'
import { cn } from 'ui/lib/utils'

import MisterContainer from '../../shared/MisterContainer'
import MisterStoryCard from '../../shared/MisterStoryCard'

interface Props {
  data: SectionStoriesData
}

const SectionStories: FunctionComponent<Props> = ({ data }) => {
  const { width } = useWindowSize()
  const sliderRef = useRef<HTMLOListElement>(null)
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [scrollableWidth, setsScrollableWidth] = useState<number>(0)

  useEffect(() => {
    const cleanUpRef = sliderRef?.current
    setsScrollableWidth((sliderRef?.current?.scrollWidth || 0) - (sliderRef?.current?.clientWidth || 0))

    const handleScroll = () => {
      setScrollPosition(sliderRef?.current?.scrollLeft || 0)
    }

    sliderRef?.current?.addEventListener('scroll', handleScroll)

    return () => {
      cleanUpRef?.removeEventListener('scroll', handleScroll)
    }
  }, [sliderRef, width])

  if (data.items.length === 0) {
    return null
  }

  return (
    <MisterContainer
      padding={false}
      data-label='section-stories'
      className={cn(
        'relative mx-auto w-full py-14 lg:px-14',
        scrollPosition === 0 || !sliderRef?.current?.clientWidth
          ? 'before:hidden'
          : 'before:pointer-events-none before:absolute before:bottom-0 before:left-0 before:top-0 before:z-10 before:w-8 before:bg-gradient-to-r before:from-white before:opacity-75',
        scrollPosition >= scrollableWidth - 10
          ? 'after:hidden'
          : 'after:pointer-events-none after:absolute after:bottom-0 after:right-0 after:top-0 after:z-10 after:w-8 after:bg-gradient-to-l after:from-white after:opacity-75',
      )}
      data-id={data._id}
    >
      <h2 className='mb-8 text-center text-heading-5 lg:text-left lg:text-heading-4'>{data?.title || 'Our stories'}</h2>
      <ol ref={sliderRef} className='relative flex max-w-[100vw] snap-x snap-mandatory gap-x-6 overflow-x-scroll pb-6 scrollbar-hide'>
        {data?.items?.map((story, index) => {
          return <MisterStoryCard story={story} key={index} />
        })}
      </ol>
    </MisterContainer>
  )
}

export default SectionStories
