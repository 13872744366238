import { FunctionComponent } from 'react'

import { gtmId, gtmNsUrl } from '../../utils/gtm.util'

const MisterScriptsBodyStart: FunctionComponent = () => {
  return (
    <>
      {gtmId && (
        <noscript>
          <iframe title='GTM' src={`${gtmNsUrl}?id=${gtmId}`} height='0' width='0' style={{ display: 'none', visibility: 'hidden' }} />
        </noscript>
      )}
    </>
  )
}

export default MisterScriptsBodyStart
