// TODO: Uncomment or remove the autocomplete-related code when it's decided to use it or not

import { FormEvent, FunctionComponent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import { useSearchBox, UseSearchBoxProps } from 'react-instantsearch'
import { useDebounce } from 'react-use'

import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/lib/utils'

import { useAlgoliaSearch } from 'src/contexts/AlgoliaSearch.context'
import { useTranslations } from 'src/contexts/Globals.context'
import { useUI } from 'src/contexts/UI.context'
import MisterIcon from '../MisterIcon'

export type MisterCustomSearchBoxProps = UseSearchBoxProps & {
  className?: string
}

const MisterCustomSearchBox: FunctionComponent<MisterCustomSearchBoxProps> = ({ className }) => {
  const { resetLimits, manuallyAppliedValue, setManuallyAppliedValue, setSearchResultsFilter } = useAlgoliaSearch()
  const { closeMobileMenu, displayMobileMenu, displaySearchUI, hideSearchUI } = useUI()
  const { query, refine, clear } = useSearchBox()
  const translate = useTranslations()
  // const { indices, refine: refineAutoComplete } = useAutocomplete({})

  const [inputValue, setInputValue] = useState(query)
  const [isFocused, setIsFocused] = useState(false)
  // const [focusedIndex, setFocusedIndex] = useState<number | null>(null)

  // Avoid unnecessary re-renders for focus and autocomplete by using a ref
  const inputRef = useRef<HTMLInputElement>(null)
  // const autocompleteVisible = useRef(false)

  // Calculate total hits with memoization to prevent recalculations on every render
  // const totalHits = useMemo(() => indices.reduce((acc, index) => acc + Math.min(index.hits?.length ?? 0, MAX_AUTOCOMPLETE_SUGGESTIONS), 0), [indices])

  /**
   * Handle search input and reset search if value is empty
   */
  const handleSearch = useCallback((searchValue: string) => {
    if (!searchValue) {
      handleResetSearch()
    } else {
      resetLimits()
      refine(searchValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Reset the search and clear results
   */
  const handleResetSearch = useCallback(() => {
    // autocompleteVisible.current = false
    resetLimits()
    setSearchResultsFilter('')
    setManuallyAppliedValue('')
    setInputValue('')
    clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Handle suggestion selection and apply search
   */
  const handleSuggestionClick = useCallback(
    (title: string) => {
      setManuallyAppliedValue(title)
      // setFocusedIndex(null)
      // autocompleteVisible.current = false
      setInputValue(title)
      handleSearch(title)
      inputRef.current?.blur()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  /**
   * Handle the cancel click to reset and close the search interface
   */
  const handleCancelClick = useCallback(() => {
    if (displayMobileMenu) {
      handleResetSearch()
      closeMobileMenu()
    } else if (displaySearchUI) {
      handleResetSearch()
      hideSearchUI()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayMobileMenu, displaySearchUI])

  /**
   * Debounced search and autocomplete refinement
   */
  useDebounce(
    () => {
      handleSearch(inputValue)
      // if (inputValue.length >= 2) {
      //   autocompleteVisible.current = true
      //   setTimeout(() => refineAutoComplete(inputValue), 0)
      // } else {
      //   autocompleteVisible.current = false
      // }
    },
    300,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue],
  )

  /**
   * Handle form submit
   */
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    // autocompleteVisible.current = false
    handleSearch(inputValue)
    inputRef.current?.blur()
  }

  /**
   * Handle reset and refocus input
   */
  const handleReset = (event: FormEvent) => {
    event.preventDefault()
    handleResetSearch()
    inputRef.current?.focus()
  }

  /**
   * Handle keyboard navigation for autocomplete
   */
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault()
        // if (autocompleteVisible.current && focusedIndex !== null) {
        //   const focusedSuggestion = indices.flatMap((index) => index.hits || []).find((_, index) => index === focusedIndex)
        //   if (focusedSuggestion) {
        //     handleSuggestionClick(focusedSuggestion.title)
        //   }
        // } else if (inputValue.trim()) {
        handleSubmit(event as unknown as FormEvent)
        // }
        break
      case 'Escape':
        event.preventDefault()
        // if (autocompleteVisible.current) {
        // autocompleteVisible.current = false
        // setFocusedIndex(-1)
        // } else {
        handleCancelClick()
        // }
        break
      // case 'ArrowDown':
      // case 'ArrowUp':
      //   if (autocompleteVisible.current) {
      //     event.preventDefault()
      //     const direction = event.key === 'ArrowDown' ? 1 : -1
      //     setFocusedIndex((prevIndex) => {
      //       if (prevIndex === null) return direction > 0 ? 0 : totalHits - 1
      //       return (prevIndex + direction + totalHits) % totalHits
      //     })
      //   }
      //   break
    }
  }

  /**
   * Focus and blur handlers to control state efficiently
   */
  const handleFocus = () => {
    setIsFocused(true)
    // if (inputValue.length >= 2) {
    //   autocompleteVisible.current = true
    // }
  }

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false)
      // autocompleteVisible.current = false
    }, 200)
  }

  /**
   * Sync input value with manually applied value from Algolia context
   */
  useEffect(() => {
    if (manuallyAppliedValue) {
      setInputValue(manuallyAppliedValue)
    }
  }, [manuallyAppliedValue])

  useEffect(() => {
    if (!displaySearchUI) {
      handleResetSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displaySearchUI])

  return (
    <div className={cn('relative', className)}>
      <form className='relative h-13 lg:h-10' role='search' onSubmit={handleSubmit} onReset={handleReset}>
        <input
          ref={inputRef}
          className='relative h-13 w-full appearance-none rounded-md border-brand-blue-light py-3.5 pl-12 pr-8 text-body-xl outline-none max-lg:border-[0.09375rem] lg:h-10 lg:rounded-none lg:border-b lg:border-b-secondary-disabled lg:pl-8 lg:pr-24 [&::-webkit-search-cancel-button]:hidden'
          autoComplete='off'
          autoCorrect='off'
          autoCapitalize='off'
          placeholder={translate('searchPlaceholder', 'Search for a product or FAQ...')}
          spellCheck={false}
          maxLength={512}
          type='search'
          value={inputValue}
          onChange={(e) => setInputValue(e.currentTarget.value)}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          aria-autocomplete='list'
          aria-controls='search-suggestions'
          data-record-posthog='true'
        />
        <div className='absolute right-4 top-1/2 flex -translate-y-1/2 flex-row gap-x-4'>
          <Button
            onClick={handleResetSearch}
            variant='ghost'
            size='icon'
            className={cn('my-auto size-5 text-body-xs-bold', inputValue.length === 0 ? 'hidden' : 'flex')}
            type='reset'
            tabIndex={displayMobileMenu || displaySearchUI ? 0 : -1}
            title={translate('clear', 'Clear')}
            aria-label={translate('clear', 'Clear')}
          >
            <span className='lg:hidden'>{translate('clear', 'Clear')}</span>
            <MisterIcon type='close' className='hidden size-4 rounded-full bg-brand-blue text-white lg:flex' />
          </Button>
          <Button
            onClick={handleCancelClick}
            variant='ghost'
            size='icon'
            tabIndex={displayMobileMenu || displaySearchUI ? 0 : -1}
            className={cn('hidden px-3 py-1 text-body-xs-bold uppercase lg:flex')}
            title={translate('cancel', 'Cancel')}
            aria-label={translate('cancel', 'Cancel')}
          >
            {translate('cancel', 'Cancel')}
          </Button>
        </div>
        <Image className='absolute left-4 top-1/2 size-6 -translate-y-1/2 lg:left-0' src='/img/search.svg' alt='' width={24} height={24} />
      </form>
      {/* <MisterSearchAutocomplete
        isVisible={autocompleteVisible.current}
        isFocused={isFocused}
        totalHits={totalHits}
        indices={indices}
        focusedIndex={focusedIndex}
        setFocusedIndex={setFocusedIndex}
        onSuggestionClick={handleSuggestionClick}
      /> */}
    </div>
  )
}

export default MisterCustomSearchBox
