import { FunctionComponent } from 'react'
import { PlusIcon } from 'lucide-react'
import { useHits } from 'react-instantsearch'

import { AlgoliaFAQHit } from 'data-access/algolia/types'
import { Button } from '@ui/components/ui/button'

import { useAlgoliaSearch } from 'src/contexts/AlgoliaSearch.context'
import { useTranslations } from 'src/contexts/Globals.context'
import { useUI } from 'src/contexts/UI.context'
import { MisterContentResult } from './MisterContentResult'
import { ResultListTitle } from './ResultListTitle'

type Props = {
  items: AlgoliaFAQHit[]
}

export const FAQResultsList: FunctionComponent<Props> = ({ items }) => {
  const { limitFAQsBy, setLimitFAQsBy } = useAlgoliaSearch()
  const { sendEvent } = useHits<AlgoliaFAQHit>()
  const translate = useTranslations()
  const FAQCount = items.length
  const { hideSearchUI } = useUI()

  if (FAQCount === 0) {
    return null
  }

  const hasMoreFAQs = FAQCount > limitFAQsBy

  const handleShowMore = () => {
    setLimitFAQsBy(limitFAQsBy * 2)
  }

  return (
    <div className='flex flex-col gap-y-6'>
      <ResultListTitle title={translate('searchFaqResultsTitle', 'FAQs')} count={FAQCount} />
      <ol className={'grid w-full grid-cols-1 gap-x-3 gap-y-6 md:grid-cols-2 lg:grid-cols-4 lg:gap-3'}>
        {items.slice(0, limitFAQsBy).map((item) => (
          <MisterContentResult
            onClick={() => {
              sendEvent('click', item, 'Search FAQ Clicked')
              hideSearchUI()
            }}
            key={`faq-result-${item._id}`}
            item={item}
          />
        ))}
      </ol>
      {hasMoreFAQs && (
        <div className='col-span-2 mx-auto w-fit'>
          <Button hover='expandIcon' Icon={PlusIcon} variant='outline' onClick={handleShowMore}>
            {translate('showMore', 'Show more')}
          </Button>
        </div>
      )}
    </div>
  )
}
