import { FunctionComponent } from 'react'

import { Unit } from 'data-access'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@ui/components/ui/table'
import { getMeasurementValue } from 'utilities/string/format/measurementValue'

import { useTranslations } from 'src/contexts/Globals.context'
import { SizeChart } from 'src/domain/sizeGuide.domain'

interface MisterSizeChartProps {
  name?: string
  sizeChart: SizeChart
  unit: Unit
}

const MisterSizeChart: FunctionComponent<MisterSizeChartProps> = ({ name, sizeChart, unit = 'cm' }) => {
  const translate = useTranslations()

  if (!sizeChart.measurements || !sizeChart.data) {
    return null
  }

  return (
    <div className='overflow-hidden'>
      <div className='overflow-x-auto overflow-y-hidden rounded-md border border-brand-beige-light text-center scrollbar-hide'>
        <Table className='relative [&:has(td:nth-child(2):hover)_td:nth-child(2)]:bg-brand-blue-pale [&:has(td:nth-child(2):hover)_th:nth-child(2)]:bg-brand-blue [&:has(td:nth-child(2):hover)_th:nth-child(2)]:text-white [&:has(td:nth-child(3):hover)_td:nth-child(3)]:bg-brand-blue-pale [&:has(td:nth-child(3):hover)_th:nth-child(3)]:bg-brand-blue [&:has(td:nth-child(3):hover)_th:nth-child(3)]:text-white [&:has(td:nth-child(4):hover)_td:nth-child(4)]:bg-brand-blue-pale [&:has(td:nth-child(4):hover)_th:nth-child(4)]:bg-brand-blue [&:has(td:nth-child(4):hover)_th:nth-child(4)]:text-white [&:has(td:nth-child(5):hover)_td:nth-child(5)]:bg-brand-blue-pale [&:has(td:nth-child(5):hover)_th:nth-child(5)]:bg-brand-blue [&:has(td:nth-child(5):hover)_th:nth-child(5)]:text-white [&:has(td:nth-child(6):hover)_td:nth-child(6)]:bg-brand-blue-pale [&:has(td:nth-child(6):hover)_th:nth-child(6)]:bg-brand-blue [&:has(td:nth-child(6):hover)_th:nth-child(6)]:text-white [&:has(td:nth-child(7):hover)_td:nth-child(7)]:bg-brand-blue-pale [&:has(td:nth-child(7):hover)_th:nth-child(7)]:bg-brand-blue [&:has(td:nth-child(7):hover)_th:nth-child(7)]:text-white [&:has(td:nth-child(8):hover)_td:nth-child(8)]:bg-brand-blue-pale [&:has(td:nth-child(8):hover)_th:nth-child(8)]:bg-brand-blue [&:has(td:nth-child(8):hover)_th:nth-child(8)]:text-white [&:has(td:nth-child(9):hover)_td:nth-child(9)]:bg-brand-blue-pale [&:has(td:nth-child(9):hover)_th:nth-child(9)]:bg-brand-blue [&:has(td:nth-child(9):hover)_th:nth-child(9)]:text-white'>
          <TableHeader>
            <TableRow>
              <TableHead
                data-col-index='0'
                className='sticky left-0 h-16 max-w-[120px] content-center border-none transition-all after:absolute after:inset-y-0 after:right-0 after:w-[1px] after:bg-brand-beige'
              >
                {name}
              </TableHead>
              {sizeChart.measurements.map(({ title }, i) => (
                <TableHead key={i} className='h-16 transition-all'>
                  {translate(title, title)}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>

          <TableBody>
            {sizeChart.data.map((size, i) => (
              <TableRow key={i} className='group/row max-w-12 odd:bg-white even:bg-brand-beige-light'>
                <TableCell className='sticky left-0 border-none transition-all after:absolute after:inset-y-0 after:right-0 after:w-[1px] after:bg-brand-beige group-odd/row:bg-white group-even/row:bg-brand-beige-light group-hover/row:bg-brand-blue group-hover/row:text-white'>
                  {size.title}
                </TableCell>
                {sizeChart.measurements.map((measurement, j) => {
                  const value = getMeasurementValue(size, measurement, unit)
                  return (
                    <TableCell key={i + j} className='transition-all hover:font-bold hover:text-white group-hover/row:bg-brand-blue-pale group-hover/row:hover:!bg-brand-blue'>
                      <div
                        data-content={value}
                        className='min-w-16 text-center after:invisible after:block after:h-0 after:min-w-16 after:text-center after:font-bold after:content-[attr(data-content)]'
                      >
                        {value}
                      </div>
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default MisterSizeChart
