import { FunctionComponent } from 'react'
import dynamic from 'next/dynamic'

import { SectionImageTextListData } from 'data-access/sanity/fragments/sections/sectionImageTextList.fragment'
import { cn } from 'ui/lib/utils'

import MisterContainer from '../../shared/MisterContainer'

const ImageTextListItem = dynamic(() => import('./image-text-list-item'))

interface SectionImageTextListProps {
  data: SectionImageTextListData
}

const SectionImageTextList: FunctionComponent<SectionImageTextListProps> = ({
  data: { _id, backgroundColorExtended, backgroundColor, imageTextListItems, title, direction } = {},
}) => {
  return (
    <MisterContainer
      backgroundColor={backgroundColor?.hex}
      dataLabel='section-image-text-list'
      type='fluid'
      className='relative flex flex-col items-center'
      padding={false}
      data-id={_id}
    >
      <MisterContainer type={direction === 'row' ? 'default' : 'small'}>
        {backgroundColorExtended && (
          <div
            aria-hidden={true}
            style={{ backgroundColor: backgroundColor?.hex }}
            className='absolute left-0 right-0 top-0 z-imageTextListBg h-[14rem] w-full -translate-y-full lg:h-[16.7rem]'
          />
        )}
        <div className={cn(direction === 'row' ? 'items-center md:flex-row md:justify-between lg:p-14' : 'md:p-10', 'flex flex-col justify-start py-6')}>
          {title && direction === 'column' && <p className='text-body-md-bold'>{title}</p>}
          {imageTextListItems?.map((imageTextListItem, index: number) =>
            (direction === 'row' && index < 3) || direction === 'column' ? (
              <ImageTextListItem key={`${imageTextListItem?.title}${index}`} imageTextListItem={imageTextListItem} direction={direction} />
            ) : null,
          )}
        </div>
      </MisterContainer>
    </MisterContainer>
  )
}

export default SectionImageTextList
