import { useState } from 'react'
import { useRouter } from 'next/router'

import { ShopifyCustomerUpdatePasswordOptions, ShopifyMinimalCustomer } from 'data-access/shopify/types'
import { getErrorMessage } from 'utilities/errors/errorMessage'

import { post } from '../../utils/request.util'
import useCustomer from './useCustomer'

interface State {
  response: ShopifyMinimalCustomer | null
  loading: boolean
  error: null | string
}

export const useUpdateCustomerPassword = (passwordMatchingError: string) => {
  const [state, setState] = useState<State>({
    response: null,
    loading: false,
    error: null,
  })

  const { locale } = useRouter()
  const { mutateCustomer } = useCustomer()

  const updateCustomerPassword = async (customer: ShopifyCustomerUpdatePasswordOptions, confirmPassword: string, currentPassword: string) => {
    setState({ response: null, loading: true, error: null })

    if (customer?.password !== confirmPassword) {
      setState({
        response: null,
        loading: false,
        error: passwordMatchingError,
      })
      return
    }

    try {
      await mutateCustomer(
        post('/api/shopify/account/update-customer-password', {
          customer,
          locale,
          currentPassword,
        }).then((res) => {
          setState({
            response: res as ShopifyMinimalCustomer | null,
            loading: false,
            error: null,
          })
        }),
      )
    } catch (error) {
      setState({
        response: null,
        loading: false,
        error: getErrorMessage(error) || 'Update account info failed.',
      })
    }
  }

  return { updateCustomerPassword, state }
}

export default useUpdateCustomerPassword
