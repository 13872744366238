import { forwardRef, ReactNode, useState } from 'react'
import { useToggle } from 'react-use'
import { FreeMode, Mousewheel, Scrollbar } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper/types'

import { cn } from '@ui/lib/utils'

import MisterChevronButtonV2 from './button/MisterChevronButtonV2'

type MisterSwiperProps = {
  children: ReactNode[]
  slideClassName?: string
  containerClassName?: string
  showNavigation?: boolean
}

const MisterSwiper = forwardRef<HTMLDivElement | HTMLUListElement, MisterSwiperProps>(({ children, slideClassName, containerClassName, showNavigation = true }, ref) => {
  const [swiper, setSwiper] = useState<SwiperType | null>(null)
  const [currentSwiperIndex, setCurrentSwiperIndex] = useState<number | undefined>(swiper?.activeIndex)
  const [swiperIsEnd, setSwiperIsEnd] = useToggle(false)

  const showLeftButton = currentSwiperIndex && currentSwiperIndex > 0
  const showRightButton = !swiperIsEnd

  return (
    <div className='relative' ref={ref as React.RefObject<HTMLDivElement>}>
      {/* Desktop Swiper Version */}
      <div className='hidden fine-pointer:block'>
        {showNavigation && (
          <>
            <MisterChevronButtonV2
              orientation='left'
              onClick={() => {
                swiper?.slidePrev()
              }}
              className={cn('absolute left-8 top-[40%] z-sliderArrow hidden -translate-y-[40%] border-none', showLeftButton ? 'md:flex' : 'md:hidden')}
            />
            <MisterChevronButtonV2
              orientation='right'
              onClick={() => {
                swiper?.slideNext()
              }}
              className={cn('absolute right-8 top-[40%] z-sliderArrow hidden -translate-y-[40%] border-none', showRightButton ? 'md:flex' : 'md:hidden')}
            />
          </>
        )}
        <Swiper
          modules={[FreeMode, Scrollbar, Mousewheel]}
          slidesPerView='auto'
          freeMode={{
            enabled: true,
            momentum: true,
            momentumRatio: 0.25,
            momentumVelocityRatio: 0.5,
          }}
          mousewheel={{
            forceToAxis: true,
            sensitivity: 1,
          }}
          scrollbar={{
            draggable: true,
            hide: false,
            snapOnRelease: true,
          }}
          grabCursor={true}
          onSwiper={setSwiper}
          wrapperTag='ul'
          onSlideChange={(s) => {
            setCurrentSwiperIndex(s.activeIndex)
            setSwiperIsEnd(s.isEnd)
          }}
          onReachBeginning={() => setSwiperIsEnd(false)}
          onReachEnd={() => setSwiperIsEnd(true)}
          className={cn('relative flex list-none [&>.swiper-wrapper]:space-x-2', containerClassName)}
        >
          {children.map((child, index) => (
            <SwiperSlide tag='li' key={index} className={cn('!w-auto', slideClassName)}>
              {child}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Mobile Touch Scroll Version */}
      <ul
        ref={ref as React.RefObject<HTMLUListElement>}
        className={cn('flex snap-x snap-mandatory gap-x-2 overflow-x-auto overflow-y-hidden scrollbar-hide fine-pointer:hidden', containerClassName)}
      >
        {children.map((child, index) => (
          <li key={index} className={cn('shrink-0 snap-center', slideClassName)}>
            {child}
          </li>
        ))}
      </ul>
    </div>
  )
})

MisterSwiper.displayName = 'MisterSwiper'

export default MisterSwiper
