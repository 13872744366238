import { forwardRef } from 'react'

import { Image } from 'data-access/sanity/fragments/common/getImage.fragment'
import { Link } from 'data-access/sanity/fragments/components/link.fragment'
import { cn } from '@ui/lib/utils'

import MisterImage from 'src/components/shared/image/MisterImage'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'

interface MenuCardProps {
  link: Link
  image: Image
  onClick?: () => void
  text?: string
  className?: string
}

const MenuCard = forwardRef<HTMLButtonElement | HTMLAnchorElement, MenuCardProps>(({ link, image, text, onClick, className }, ref) => {
  return (
    <MisterSiteLink ref={ref} onClick={onClick} link={link} className={cn('relative')} title={link.linkText}>
      <div
        className={cn('relative overflow-hidden rounded-md after:absolute after:inset-0 after:bg-gradient-to-t after:from-black/30 after:to-transparent after:to-50%', className)}
      >
        <MisterImage mobile={image} className='object-cover object-center mix-blend-darken' priority={true} />
      </div>
      <div className='absolute inset-4 flex lg:inset-6'>{text && <span className='mt-auto text-btn-xl text-white lg:text-heading-5'>{text}</span>}</div>
    </MisterSiteLink>
  )
})
MenuCard.displayName = 'MenuCard'

export default MenuCard
