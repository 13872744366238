import { FunctionComponent } from 'react'

import { cn } from 'ui/lib/utils'

import MisterIcon from '../MisterIcon'

export interface MisterChevronButtonProps {
  orientation: 'left' | 'right'
  onClick?: (e?: any) => void | Promise<void> | null
  className?: string
  variant?: 'white' | 'off-white'
}

const MisterChevronButton: FunctionComponent<MisterChevronButtonProps> = ({ orientation, onClick, className, variant = 'white' }) => {
  return (
    <button
      aria-label={`chevron-${orientation}`}
      onClick={onClick}
      className={cn('group flex size-10 items-center justify-center rounded-full', variant == 'white' ? 'bg-white' : 'bg-brand-beige-light', className)}
    >
      <MisterIcon type={`chevron-${orientation}`} className='size-4 transition-transform duration-100 ease-in-out md:group-hover:scale-125' />
    </button>
  )
}

export default MisterChevronButton
