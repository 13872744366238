import * as React from 'react'
import { Slot, Slottable } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@ui/lib/utils'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md border-[1.5px] text-btn-lg ring-offset-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-transparent focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'border-primary bg-primary text-primary-foreground',
        primary: 'border-primary bg-primary text-primary-foreground',
        outline: 'border-primary bg-transparent',
        secondary: 'border-secondary bg-secondary text-secondary-foreground',
        link: 'relative border-none bg-gradient-to-r from-current to-current bg-[length:100%_0.05rem] bg-[left_bottom_0rem] bg-no-repeat text-primary underline-offset-4',
        pill: 'select-none gap-2 rounded-full border-brand-beige-light bg-brand-beige-light text-primary hover:border-brand-beige hover:bg-brand-beige',
        ghost: 'border-none bg-transparent text-primary',
      },
      hover: {
        default: '',
        expandIcon: 'group relative',
        scaleEffect:
          'relative z-[1] bg-transparent duration-100 after:absolute after:-z-[1] after:size-10 after:scale-90 after:rounded-md after:bg-primary after:opacity-50 after:transition-[opacity,transform] after:duration-100 hover:after:scale-100 hover:after:opacity-100',
        underline: 'transition-[background-size,color] duration-150 ease-out hover:bg-[length:100%_0.05rem] active:bg-[length:100%_0.05rem]',
      },
      size: {
        sm: 'h-9 px-3 text-btn-sm',
        md: 'h-9 px-3 text-btn-md',
        default: 'px-12 py-4 text-btn-lg',
        xl: 'px-12 py-4 text-btn-xl',
        icon: 'size-10',
      },
    },
    compoundVariants: [
      {
        variant: 'link',
        size: ['sm', 'md', 'default', 'xl', 'icon'],
        className: 'h-auto rounded-none p-0 pb-1',
      },
      {
        variant: 'link',
        hover: 'underline',
        className: 'bg-[length:0%_0.05rem]',
      },
      {
        variant: 'default',
        hover: 'scaleEffect',
        className: 'after:bg-primary',
      },
      {
        variant: 'secondary',
        hover: 'scaleEffect',
        className: 'after:bg-secondary',
      },
      {
        variant: 'pill',
        size: ['default', 'sm', 'icon'],
        className: 'h-auto p-2 pr-4 text-btn-md',
      },
    ],

    defaultVariants: {
      variant: 'primary',
      hover: 'default',
      size: 'default',
    },
  },
)

interface IconProps {
  Icon: React.ElementType
}

interface IconRefProps {
  Icon?: never
}

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean
  [key: string]: any // for passing undefined props to the button
}

export type ButtonIconProps = IconProps | IconRefProps

const ExpandIconWrapper: React.FunctionComponent<React.PropsWithChildren<{ Icon: React.ElementType }>> = ({ Icon, children }) => {
  return (
    <>
      <div className='absolute grid grid-cols-[0rem,1fr] items-center transition-[grid-template-columns] duration-150 ease-out group-hover:grid-cols-[1rem,1fr] group-hover:gap-2'>
        <Icon className='size-4 opacity-0 transition-opacity duration-150 ease-out group-hover:opacity-100' />
        {children}
      </div>
      <span data-placeholder={children} className='after:invisible after:inline-block after:h-0 after:font-bold after:content-[attr(data-placeholder)]' />
    </>
  )
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps & ButtonIconProps>(({ className, variant, hover, size, asChild = false, Icon, e2eSelector, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button'

  return (
    <Comp className={cn(buttonVariants({ variant, hover, size, className }))} ref={ref} {...props}>
      {Icon && hover === 'expandIcon' ? <ExpandIconWrapper Icon={Icon}>{props.children}</ExpandIconWrapper> : <Slottable>{props.children}</Slottable>}
    </Comp>
  )
})
Button.displayName = 'Button'

export { Button, buttonVariants }
