import { AlgoliaProductHit } from 'data-access/algolia/types'
import { ProductCardProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'

/**
 * Map an Algolia Result to the props of a ProductCard
 * @param hit
 */
export const mapAlgoliaHitToProductCard = (hit: AlgoliaProductHit): ProductCardProduct & { sku: string } => ({
  _id: hit._id,
  discontinued: hit.discontinued,
  slug: hit.slug,
  badges: hit.badges,
  images:
    hit.images
      ?.filter(({ asset }) => !!asset)
      .map(({ asset }) => {
        return {
          _type: 'image',
          url: asset.url,
          originalFilename: asset.originalFileName,
          lqip: '',
          width: asset.width,
          height: asset.height,
        }
      }) || [],
  productTitle: hit.title,
  primaryCollection: {
    _id: hit.title,
    productType: hit.primaryCollection?.productType,
  },
  colorId: hit.color,
  shopifyProductId: hit.shopifyProductId,
  sku: hit.sku,
  isAvailable: hit.isAvailable,
  price: hit.price,
  variants: hit.variants,
})
