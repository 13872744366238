import { FunctionComponent } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { Media } from 'data-access/sanity/fragments/components/media.fragment'
import { SectionMediaData } from 'data-access/sanity/fragments/sections/sectionMedia.fragment'
import { SectionTextData } from 'data-access/sanity/fragments/sections/sectionText.fragment'
import { Slug } from 'data-access/sanity/types'
import { formatDateInternational } from 'utilities/date/format/formatDate'
import { getSiteUrl } from 'utilities/string/url'

import { useTranslations } from '../../../contexts/Globals.context'
import { imageQuality } from '../../../utils/image.util'
import MisterContainer from '../../shared/MisterContainer'
import MisterSectionLoop from '../../shared/sections/MisterSectionLoop'
import MisterSiteLink from '../../shared/site-link/MisterSiteLink'

interface ArticleContentProps {
  slug?: Slug
  dateCreated?: string
  sections?: (SectionMediaData | SectionTextData)[]
  articleMainImage?: Media
}

const ArticleContent: FunctionComponent<ArticleContentProps> = ({ slug, dateCreated, sections, articleMainImage }) => {
  const translate = useTranslations()
  const { locale } = useRouter()
  const socialsList = translate('shareSocials')?.split(',')
  const articleDate = formatDateInternational(dateCreated || new Date(), locale)

  const socialShareMessage = encodeURIComponent(translate('shareSocialsMessage'))

  const shareBaseUrl = getSiteUrl(`${locale !== 'en' ? `/${locale}/` : '/'}blog/${slug?.current}`)

  const socialLinks = {
    pinterest: `https://pinterest.com/pin/create/button/?url=${shareBaseUrl}&media=${articleMainImage?.type === 'image' && articleMainImage?.mobile?.url}&description=${socialShareMessage}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${shareBaseUrl}&quote=${socialShareMessage}`,
    twitter: `https://twitter.com/intent/tweet?text=${socialShareMessage}&url=${shareBaseUrl}`,
  }

  return (
    <MisterContainer type='small'>
      <div className='mb-4'>
        <time className='text-body-sm'>{articleDate}</time>
      </div>

      <MisterSectionLoop sections={sections} className='gap-6' />

      <footer className='my-14 lg:my-28'>
        <p className='mb-4 text-center text-btn-md font-semibold lg:px-72'>{translate('shareThisArticle')}</p>
        <ul className='flex items-center justify-center'>
          {socialsList?.map((social: string) => {
            return (
              <li key={social} className='mr-4 flex items-center justify-center last:mr-0'>
                <MisterSiteLink
                  link={{ type: 'external', externalLink: socialLinks[social as keyof typeof socialLinks] }}
                  className='flex items-center justify-center text-brand-blue'
                >
                  <Image
                    src={`/img/social/${social === 'facebook' ? `${social}-black` : social}.svg`}
                    width={32}
                    height={32}
                    alt={social}
                    priority={false}
                    quality={imageQuality}
                  />
                </MisterSiteLink>
              </li>
            )
          })}
        </ul>
      </footer>
    </MisterContainer>
  )
}

export default ArticleContent
