import { Fragment, FunctionComponent } from 'react'
import { PortableTextBlock } from '@portabletext/types'
import { Accessibility, ChevronLeft, MapPin, Phone } from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'

import { urlForImage } from 'data-access'
import { SectionStoreData } from 'data-access/sanity/fragments/sections/sectionStore.fragment'
import { cn } from 'ui/lib/utils'
import { days } from 'utilities/date/format/formatDate'
import { portableTextToText } from 'utilities/string/format/portabletextToText'

import useSpecialDays from 'src/hooks/utils/useSpecialDays'
import { useTranslations } from '../../../contexts/Globals.context'
import MisterMedia from '../../shared/media/MisterMedia'
import RichText from '../../shared/rich-text/rich-text'

interface SectionStoreProps {
  data: SectionStoreData
}

const SectionStore: FunctionComponent<SectionStoreProps> = ({ data }) => {
  const {
    storeMedia,
    description,
    recruitmentMessage,
    store: { googleMapsLink, telephoneNumber, defaultOpeningTimes, specialOpeningTimes, country, city, street, postalCode, isOpen, accessibility },
  } = data

  const translate = useTranslations()
  const { dayOfTheWeek } = useSpecialDays(defaultOpeningTimes, specialOpeningTimes)

  return (
    <section data-label='section-store' className='relative grid w-full lg:grid-cols-2' data-id={data._id}>
      <div className='col-span-1 grid w-full lg:sticky lg:top-0 lg:col-start-2 lg:h-[100dvh]'>
        {!isOpen && (
          <div className='z-10 col-span-1 col-start-1 row-span-1 row-start-1 place-content-center bg-black/40'>
            <p className='text-center text-heading-2 text-white'>{translate('openingSoon')}</p>
          </div>
        )}
        <MisterMedia
          quality='100'
          media={storeMedia}
          className='col-span-1 col-start-1 row-span-1 row-start-1 aspect-video w-full object-cover object-center lg:h-[100dvh]'
          mobileSizes='100vw'
          desktopSizes='50vw'
        />
      </div>

      <div className='flex flex-col gap-9 px-4 pb-24 pt-6 lg:col-span-1 lg:col-start-1 lg:row-start-1 lg:gap-12 lg:px-9'>
        <div>
          <Link href={'/stores'} className='flex gap-4'>
            <ChevronLeft className='size-4' />
            <span className='text-body-md-bold'>{translate('viewAllStores', 'View all stores')}</span>
          </Link>
        </div>

        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-1'>
            <span className='text-body-sm text-brand-blue-grey'>{translate(country)}</span>
            <h3 className='text-heading-3'>{city}</h3>
          </div>
          {description && (
            <div className='my-auto max-w-md'>
              <RichText className='text-body-md' data={description} />
            </div>
          )}
        </div>

        <div>
          <p className='mb-3 whitespace-pre-line text-body-lg-bold'>{translate('locationInformation', 'Location Information')}</p>
          <div className='flex flex-col justify-between gap-2 text-body-md'>
            {googleMapsLink && (
              <div className='flex items-center gap-2'>
                <MapPin className='size-6 p-0.5' />
                <a href={googleMapsLink} target='_blank' rel='noopener noreferrer' className='underline'>
                  {`${street}, ${postalCode} ${city}`}
                </a>
              </div>
            )}
            {telephoneNumber && (
              <div className='flex items-center gap-2'>
                <Phone className='size-6 p-0.5' />
                <a href={`tel:${telephoneNumber}`}>{telephoneNumber}</a>
              </div>
            )}
            {accessibility && (
              <div className='flex items-center gap-2'>
                {accessibility.map((accessibility, i) => {
                  const image = urlForImage(accessibility.icon)
                  return (
                    <div key={i} className='flex items-center gap-2'>
                      {image ? <Image {...image} alt={portableTextToText(accessibility.text as PortableTextBlock[])} /> : <Accessibility className='size-6' />}
                      <RichText className='text-body-md' data={accessibility.text} />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
        {isOpen && (
          <div className='flex flex-col gap-9 lg:gap-12'>
            <div className='flex flex-col gap-4 text-body-md'>
              <p className='text-body-lg-bold'>{translate('openingHours')}</p>

              {defaultOpeningTimes && (
                <div className='grid grid-cols-2 gap-x-7 gap-y-2 text-body-md'>
                  {days.map((day) => {
                    const { open, close, closed, message } = defaultOpeningTimes[day]
                    return (
                      <Fragment key={day}>
                        <div className={cn('capitalize', day === dayOfTheWeek && 'text-body-md-bold')}>{translate(`${day}`)}</div>
                        <div className={cn(day === dayOfTheWeek && 'text-body-md-bold')}>{closed ? translate('closed') : `${open} - ${close}`}</div>
                        {message && <p className='col-span-2 mt-2 text-body-md'>*{message}</p>}
                      </Fragment>
                    )
                  })}
                </div>
              )}
            </div>
            {specialOpeningTimes?.length > 0 && (
              <div className='flex flex-col gap-3 text-body-md'>
                <p className='mt-1 text-body-lg-bold'>Special opening hours</p>

                {specialOpeningTimes.map((specialDay, i) => (
                  <Fragment key={i}>
                    {specialDay.occasion && (
                      <div className='grid grid-cols-2 gap-x-7 gap-y-1'>
                        <p>{specialDay.occasion}</p>
                        {specialDay.open && specialDay.close && (
                          <p className=''>
                            {specialDay.open} - {specialDay.close}
                          </p>
                        )}
                        {specialDay.closed && <p>{translate('closed')}</p>}
                        <p>{`${new Date(specialDay.date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}`}</p>
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            )}
          </div>
        )}
        {recruitmentMessage && <RichText className='text-body-md' data={recruitmentMessage} />}
      </div>
    </section>
  )
}

export default SectionStore
