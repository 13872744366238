import { FunctionComponent } from 'react'
import { useHits } from 'react-instantsearch'

import { AlgoliaArticleHit, AlgoliaCollectionHit, AlgoliaFAQHit, AlgoliaPageHit, AlgoliaProductHit, MisterAlgoliaHit } from 'data-access/algolia/types'
import { cn } from '@ui/lib/utils'

import { ArticleResultsList } from './ArticleResultsList'
import { CollectionResultsList } from './CollectionResultsList'
import { FAQResultsList } from './FAQResultsList'
import { MisterNoResults } from './MisterNoResults'
import MisterNoResultsBoundary from './MisterNoResultsBoundary'
import { PageResultsList } from './PageResultsList'
import { ProductResultsList } from './ProductResultsList'

type Props = {
  className?: string
}

const MisterCustomHits: FunctionComponent<Props> = ({ className }) => {
  const { items } = useHits<MisterAlgoliaHit>()

  return (
    <div key={'customHits'} className={cn('relative', className)}>
      <MisterNoResultsBoundary fallback={<MisterNoResults />}>
        <div className='mb-10 mt-4'>
          <div className='flex flex-col gap-y-4 lg:gap-y-8'>
            <ProductResultsList items={(items as AlgoliaProductHit[]).filter((item) => item._type === 'product')} />
            <CollectionResultsList items={(items as AlgoliaCollectionHit[]).filter((item) => item._type === 'collection')} />
            <ArticleResultsList items={(items as AlgoliaArticleHit[]).filter((item) => item._type === 'pageArticle')} />
            <FAQResultsList items={(items as AlgoliaFAQHit[]).filter((item) => item._type === 'faqQuestion')} />
            <PageResultsList items={(items as AlgoliaPageHit[]).filter((item) => item._type === 'page')} />
          </div>
        </div>
      </MisterNoResultsBoundary>
    </div>
  )
}

export default MisterCustomHits
