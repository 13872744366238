import { Unit } from 'data-access'

/**
 * Converts a value from one unit to another.
 * @param value - The value to convert.
 * @param unit - The unit to convert the value to.
 * @returns The converted value.
 */
export const convertToUnit = (value: number, unit: Unit): number => {
  if (unit === 'cm') return value
  return Math.round(value * 0.393701 * 10) / 10
}
