import { FunctionComponent } from 'react'
import { useRouter } from 'next/router'

import { useTranslations } from 'src/contexts/Globals.context'
import { TrackingInfo } from '../section-aftership'

export const Checkpoints: FunctionComponent<{ shipment: TrackingInfo }> = ({ shipment }) => {
  const translate = useTranslations()
  const { locale } = useRouter()

  return (
    <div className='flex flex-col gap-7'>
      <span className='text-body-sm md:text-body-md'>* {translate('timesAreInLocalTime', 'Times are in local time of the checkpoint location.')}</span>
      <div className='flex flex-col'>
        {shipment.checkpoints
          ?.sort((a, b) => new Date(b.checkpoint_time).getTime() - new Date(a.checkpoint_time).getTime())
          .map((checkpoint, index) => (
            <div
              key={index}
              className='grid grid-cols-4 gap-4 border-t border-brand-blue-light py-5 text-body-sm font-semibold opacity-50 first:border-none first:pt-0 first:opacity-100 last:pb-0 md:py-7 md:text-body-lg'
            >
              <div className='col-span-1 flex flex-col gap-1'>
                {new Date(checkpoint.checkpoint_time)
                  .toLocaleDateString(locale, {
                    month: 'short',
                    day: '2-digit',
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true,
                  })
                  .split(',')
                  .map((datePart, i) => (
                    <div key={i}>{datePart}</div>
                  ))}
              </div>
              <div className='col-span-3 flex flex-col'>
                {checkpoint.tag && <span>{translate(checkpoint.tag.charAt(0).toLowerCase() + checkpoint.tag.slice(1), checkpoint.tag)}</span>}
                {checkpoint.message && <span className='opacity-60'>{checkpoint.message}</span>}
                {checkpoint.location && <span className='opacity-60'>{checkpoint.location}</span>}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
