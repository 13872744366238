import { FunctionComponent, MouseEventHandler, useEffect } from 'react'
import { useRouter } from 'next/router'
import { usePostHog } from 'posthog-js/react'
import { useToggle, useUpdateEffect } from 'react-use'

import { ShopifyCartLineItem } from 'data-access/shopify/types'
import { cn } from 'ui/lib/utils'
import { getErrorMessage } from 'utilities/errors/errorMessage'
import { formatPrice } from 'utilities/string/format/price'

import { DefaultPageProps } from 'src/domain/defaultPageProps.domain'
import multipass from 'src/hooks/customer/multipass'
import { captureFeatureView, useExperimentFeatureFlag } from 'src/hooks/posthog/useExperimentFeatureFlag'
import { useTelemetry } from '../../../contexts/Telemetry.context'
import { useUI } from '../../../contexts/UI.context'
import useCustomer from '../../../hooks/customer/useCustomer'
import useCart from '../../../hooks/shop/useCart'
import { ecommerceInfoViewCart } from '../../../utils/elevar.util'
import { get } from '../../../utils/localStorage.util'
import { ELEVAR_DATA_LAYER_EVENTS, TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from '../../../utils/telemetry.util'
import MisterSpinner from '../../shared/loader/MisterSpinner'
import MisterMedia from '../../shared/media/MisterMedia'
import MisterIcon from '../../shared/MisterIcon'
import MisterSlideOverPanel from '../../shared/panel/MisterSlideOverPanel'
import MisterFrequentlyBoughtTogether from '../../shared/recommendations/MisterFrequentlyBoughtTogether'
import MisterTrendingItems from '../../shared/recommendations/MisterTrendingItems'
import MisterCartLineItem from './lineItem/MisterCartLineItem'
import MisterCartUpsell from './MisterCartUpsell'

type MisterCartProps = NonNullable<DefaultPageProps['cart']>

const MisterCart: FunctionComponent<{ data: MisterCartProps }> = ({ data }) => {
  const {
    upsellBanners,
    cartMention,
    closeButtonMessage,
    cartTitle,
    activateCheckout,
    subtotalText,
    checkoutText,
    checkoutTextMaintenance,
    emptyCartMessage,
    recommendedForYou,
    frequentlyBoughtTogether,
  } = data

  const { locale } = useRouter()
  const { customer } = useCustomer()
  const { displayCart, closeCart } = useUI()
  const { userProperties } = useTelemetry()
  const { cart, isEmpty, lineItems } = useCart()
  const [isLoading, setIsLoading] = useToggle(false)
  const productHandlesInCart = lineItems.flatMap((lineItem) => lineItem?.merchandise?.product?.handle)
  const algoliaObjectIDsInCart = lineItems.flatMap((lineItem) => `product-${lineItem?.merchandise?.product?.handle}-${lineItem?.merchandise?.sku}`)
  const postHog = usePostHog()
  const flag = 'cart-cross-sell'
  const abTestVariant = useExperimentFeatureFlag(flag, undefined, false) || 'control'

  useEffect(() => {
    if (displayCart) {
      captureFeatureView(flag, postHog)
    }
  }, [displayCart])

  const upsellBanner = upsellBanners?.filter(
    ({ toInclude, toExclude }) =>
      (toInclude === undefined || toInclude.filter((productHandle) => productHandlesInCart.includes(productHandle)).length !== 0) &&
      (toExclude === undefined || toExclude.filter((productHandle) => productHandlesInCart.includes(productHandle)).length === 0),
  )[0]

  const ContinueShoppingButton: FunctionComponent<{ variant?: 'top' | 'empty-cart' }> = ({ variant = 'top' }) => {
    return (
      <button
        className={cn('flex items-center px-4 pb-4 pt-2 text-body-md-bold leading-none underline', variant === 'top' && 'border-b border-brand-grey')}
        onClick={closeCart}
        type='button'
      >
        <MisterIcon type='chevron-left' className='-ml-1 mr-1 size-4' /> {closeButtonMessage}
      </button>
    )
  }

  useUpdateEffect(() => {
    if (displayCart) {
      trackEvent(ELEVAR_DATA_LAYER_EVENTS.DL_VIEW_CART, {
        user_properties: userProperties,
        cart_total: parseFloat(isEmpty ? '0' : cart?.cost?.subtotalAmount?.amount || '0').toFixed(2),
        ecommerce: ecommerceInfoViewCart(
          lineItems.filter((item): item is ShopifyCartLineItem => item !== undefined),
          get('elevar-list') ?? '',
          locale,
        ),
      })
    }
  }, [displayCart])

  const handleCheckout: MouseEventHandler<HTMLAnchorElement> = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    let url = (e.target as HTMLAnchorElement)?.href

    if (!url) {
      return console.error('Checkout button has no URL.')
    }

    try {
      setIsLoading(true)

      // This is to for the multipass when we have the customer data
      if (customer?.isLoggedIn) {
        url = await multipass(customer.email, url, locale)
      }

      trackEvent(TELEMETRY_DATA_LAYER_EVENTS.START_CHECKOUT)

      // Open Shopify checkout in same window
      document.location.href = url
    } catch (error) {
      console.error(getErrorMessage(error))
    } finally {
      // Safari uses back-forward cache to return to the cart with loader
      // active unless we explicitly unset the loading state (i.e. changing
      // document.location only won't do it)
      setTimeout(() => setIsLoading(false), 2000)
    }
  }

  return (
    <MisterSlideOverPanel
      title={cartTitle || 'Cart'}
      visible={displayCart}
      onClose={closeCart}
      showTitle={false}
      padding={false}
      header={isEmpty ? undefined : <ContinueShoppingButton variant='top' />}
      footer={
        isEmpty ? undefined : (
          <footer data-label='Cart footer' className='w-full shadow-[0_0_.75rem_rgba(72,72,72,0.1);]'>
            {cartMention?.text && (
              <p className={cn('relative mx-5 flex items-center justify-center border-brand-grey px-1 py-3 text-body-md', !cartMention?.image && 'border-b')}>
                {cartMention?.text}
              </p>
            )}
            {cartMention?.image && (
              <div>
                <MisterMedia media={cartMention?.image} />
              </div>
            )}
            {upsellBanner && abTestVariant == 'control' && <MisterCartUpsell upsellBanner={upsellBanner} handleCartClose={closeCart} />}
            {!isEmpty && activateCheckout && (
              <div className='flex flex-col space-y-2 px-5 py-6 sm:space-y-4 sm:px-6 sm:py-8'>
                <div className='flex w-full items-center justify-between text-body-md'>
                  <p>{subtotalText || 'Subtotal'}</p>
                  <span>{formatPrice(cart?.cost?.totalAmount?.amount || '', locale)}</span>
                </div>
                <a
                  data-testid='checkout-button'
                  target='_self'
                  href={cart?.webUrl || ''}
                  className={cn(
                    'relative flex w-full items-center justify-center gap-3 whitespace-nowrap rounded-md bg-brand-blue-action fill-white px-6 py-5 text-btn-lg text-white ring-offset-transparent transition-all duration-100 ease-in-out hover:bg-brand-blue-grey active:bg-brand-blue-light',
                    isLoading && 'cursor-wait',
                  )}
                  onClick={handleCheckout}
                >
                  <MisterSpinner show={isLoading} size='micro' inline={true} />
                  {checkoutText || 'Go to checkout'}
                </a>
              </div>
            )}
            {!isEmpty && !activateCheckout && (
              <div className='flex flex-col space-y-2 px-5 py-6 sm:space-y-4 sm:px-6 sm:py-8'>
                <div className='flex w-full items-center justify-between text-body-md'>
                  <p>{subtotalText || 'Subtotal'}</p>
                  <span>{formatPrice(cart?.cost?.totalAmount?.amount || '', locale)}</span>
                </div>
                <div className='relative flex w-full cursor-not-allowed items-center justify-center gap-3 rounded-md bg-brand-disabled p-8 text-body-md-bold text-white'>
                  {checkoutTextMaintenance || 'Store is currently down for maintenance'}
                </div>
              </div>
            )}
          </footer>
        )
      }
    >
      <>
        <div className='flex h-full flex-col overflow-y-auto overflow-x-hidden'>
          {isEmpty && (
            <div data-label='Cart empty' className={cn('flex flex-1 flex-col justify-center space-y-8', abTestVariant === 'with-cross-sell' && 'lg:mt-20')}>
              <div className='flex flex-col items-center justify-center space-y-8 px-8 text-center'>
                <p className='text-heading-5'>{emptyCartMessage || 'Your cart is empty'}</p>
                <ContinueShoppingButton variant='empty-cart' />
              </div>
              {abTestVariant === 'with-cross-sell' && (
                <div className='flex-shrink-0'>
                  <MisterTrendingItems title={recommendedForYou || 'Recommended for you'} />
                </div>
              )}
            </div>
          )}
          {!isEmpty && (
            <>
              <ul className='flex flex-col divide-y divide-brand-grey px-4 py-2 lg:py-6'>
                {(lineItems || [])?.map((lineItem) => lineItem && <MisterCartLineItem key={lineItem.id} lineItem={lineItem} locale={locale} />)}
              </ul>
              {(abTestVariant === 'with-cross-sell' || abTestVariant == 'with-filtered-cross-sell') && (
                <div className='flex-shrink-0'>
                  <MisterFrequentlyBoughtTogether title={frequentlyBoughtTogether || 'Frequently bought together'} productHandles={algoliaObjectIDsInCart} />
                </div>
              )}
            </>
          )}
        </div>
      </>
    </MisterSlideOverPanel>
  )
}

export default MisterCart
