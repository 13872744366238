import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { cn } from 'ui/lib/utils'

import MisterIcon from '../MisterIcon'

const ratingStars = (rating: Props['rating'], maxRating: Props['max'], size: Props['size']): ReactNode[][] => {
  const result = []
  const ratingRoundedOnHalf = rating > 0 ? Math.round(rating * 2) / 2 : 0
  const filledStars = Math.trunc(ratingRoundedOnHalf)
  const halfStars = ratingRoundedOnHalf - filledStars > 0 ? ((ratingRoundedOnHalf - filledStars) % 0.5 === 0 ? 1 : 0) : 0
  const emptyStars = maxRating - filledStars - halfStars
  const classes = cn('relative inline', size === 'small' && 'size-3', size === 'medium' && 'size-4', size === 'large' && 'size-8')

  if (filledStars > 0) {
    result.push([...Array(filledStars)].map((v, i) => <MisterIcon key={`filled-${i}`} type='rating-star-filled' className={classes} />))
  }

  if (halfStars > 0) {
    result.push([...Array(halfStars)].map((v, i) => <MisterIcon key={`half-${i}`} type='rating-star-half' className={classes} />))
  }

  if (emptyStars > 0) {
    result.push([...Array(emptyStars)].map((v, i) => <MisterIcon key={`empty-${i}`} type='rating-star-filled' className={cn('opacity-30', classes)} />))
  }

  return result
}

interface Props {
  rating: number
  max: number
  count?: number
  size: 'small' | 'medium' | 'large'
  itemProp?: string
  itemType?: string
  setMicrodata?: boolean
  className?: string
}

const MisterRating: FunctionComponent<Props> = ({
  rating,
  max,
  count = 1,
  size = 'small',
  itemProp = 'reviewRating',
  itemType = 'https://schema.org/Rating',
  setMicrodata = true,
  className,
}) => {
  const { locale } = useRouter()
  const [ratingFormatted, setRatingFormatted] = useState<string>('')

  useEffect(() => {
    setRatingFormatted(new Intl.NumberFormat(locale, { maximumSignificantDigits: 2 }).format(rating))
  }, [locale, rating])

  return (
    <div
      className={cn(className, 'block space-x-0.5 whitespace-nowrap leading-[0]')}
      aria-label={`${ratingFormatted}/${max}`}
      title={`${ratingFormatted}/${max}`}
      role='img'
      itemProp={itemProp}
      itemType={itemType}
      itemScope={setMicrodata ? true : undefined}
    >
      {setMicrodata && itemProp && itemType && (
        <>
          <meta itemProp='bestRating' content={String(max)} />
          <meta itemProp='ratingValue' content={String(rating.toFixed(1))} />
          {itemType === 'https://schema.org/AggregateRating' && <meta itemProp='reviewCount' content={String(count)} />}
        </>
      )}
      {ratingStars(rating, max, size)}
    </div>
  )
}

export default MisterRating
