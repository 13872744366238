import { FunctionComponent } from 'react'

import { baseImageBuilder } from 'data-access'
import { Image } from 'data-access/sanity/fragments/common/getImage.fragment'
import { cn } from 'ui/lib/utils'

interface MisterProductCardImageProps {
  image: Image
  sizes: string
  alt: string
  className?: string
  priority?: boolean
  defaultWidth?: number
}

type SingleImage = {
  url: string
  width: number
  height: number
  isDefault: boolean
}

const MisterProductCardImage: FunctionComponent<MisterProductCardImageProps> = ({ image, sizes, alt, className, priority = false, defaultWidth = 1000 }) => {
  if (!image?.url) {
    return null
  }

  const widths = [defaultWidth / 4, defaultWidth / 2, defaultWidth, defaultWidth * 2, defaultWidth * 3]

  const images: SingleImage[] = widths.map((width) => {
    const height = Math.floor((3 * width) / 2)

    return {
      url: baseImageBuilder(image, '100').width(width).height(height).url(),
      width,
      height,
      isDefault: width === defaultWidth,
    }
  })

  const defaultImage = images.find((image) => image.isDefault)

  // Note: do not use Next.js Image component, the sheer amount of
  // images on the PDP causes the page to crash on iOS Safari
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      className={cn('size-full object-contain', className)}
      src={defaultImage?.url}
      alt={alt}
      loading={priority ? 'eager' : 'lazy'}
      itemProp='image'
      width={defaultImage?.width}
      height={defaultImage?.height}
      srcSet={images.map((image) => `${image.url} ${image.width}w`).join(', ')}
      sizes={sizes}
    />
  )
}

export default MisterProductCardImage
