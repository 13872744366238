import { getWishlistProductInformation } from 'data-access'
import { SupportedLocale } from 'data-access/domain/constants'
import { ProductCardProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'

interface GetWishlistProductsParams {
  productIds: string[]
  locale: SupportedLocale
}

export const getWishlistProducts = async ({ productIds, locale }: GetWishlistProductsParams): Promise<ProductCardProduct[]> => {
  try {
    return await getWishlistProductInformation(productIds, locale)
  } catch (error) {
    console.error('Error fetching wishlist products:', error)
    return []
  }
}
