import { FunctionComponent } from 'react'
import Image, { ImageLoader } from 'next/image'

import shopifyImageLoader from '../../../utils/shopifyImageLoader.util'

type ImageProps = {
  [key: string]: string
}

type MisterShopifyImageProps = {
  sizes: string
  image: ImageProps
  alt: string
  priority: boolean
  className: string
  width?: number
  height?: number
  fill?: boolean
}

const imageLoaders: Record<string, ImageLoader> = {
  'cdn.shopify.com': shopifyImageLoader,
}

const MisterShopifyImage: FunctionComponent<MisterShopifyImageProps> = ({ sizes, image, alt, priority, className, width, height, fill }) => {
  const imageSrc = image?.baseUrl || image?.url || image?.transformedSrc
  const loader = imageSrc?.startsWith('https://') ? imageLoaders[new URL(imageSrc).host] || undefined : undefined

  return (
    <div className={className}>
      <Image sizes={sizes} loader={loader} width={width} height={height} src={`${imageSrc}`} fill={fill} alt={alt} priority={priority} unoptimized={true} />
    </div>
  )
}

export default MisterShopifyImage
