import { FunctionComponent, HTMLAttributeAnchorTarget, PropsWithChildren } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { Link } from 'data-access/sanity/fragments/components/link.fragment'

import MisterSpinner from '../loader/MisterSpinner'
import MisterIcon from '../MisterIcon'
import MisterSiteLink from '../site-link/MisterSiteLink'

export interface MisterButtonProps extends ButtonProps {
  type: 'button' | 'submit' | 'reset' | 'a'
  onClick?: (e?: any) => void | Promise<void> | null
  target?: HTMLAttributeAnchorTarget | undefined
  className?: string
  e2eSelector?: string
  icon?: string
  link?: Link
}

interface ButtonProps extends ButtonClasses {
  buttonText: string
}

type ButtonClasses = VariantProps<typeof buttonClasses>
const buttonClasses = cva('relative items-center justify-center whitespace-nowrap ring-offset-transparent transition-all duration-100 ease-in-out', {
  variants: {
    variant: {
      primary: [
        'bg-brand-blue',
        'border',
        'border-brand-blue',
        'hover:bg-brand-blue-grey',
        'hover:border-brand-blue-grey',
        'active:bg-brand-blue-light',
        'active:border-brand-blue-light',
        'disabled:bg-brand-blue-dark-disabled',
        'disabled:border-brand-blue-dark-disabled',
        'fill-white',
        'text-white',
      ],
      warning: ['bg-brand-orange', 'hover:text-brand-orange', 'hover:ring-brand-orange', 'text-white', 'hover:bg-white', 'hover:ring-2', 'hover:ring-inset', 'active:bg-white'],
      secondary: [
        'text-brand-blue',
        'fill-brand-blue',
        'bg-brand-beige-light',
        'disabled:bg-brand-blue-dark-disabled',
        'hover:bg-brand-blue',
        'active:bg-brand-blue-light',
        'hover:fill-white',
        'hover:text-white',
        'active:fill-white',
        'active:text-white',
        'disabled:fill-white',
        'disabled:text-white',
      ],
      tertiary: ['bg-brand-blue', 'hover:bg-brand-blue-grey', 'active:bg-brand-blue-light', 'fill-white', 'text-white'],
      transparent: [
        'text-brand-blue',
        'fill-brand-blue',
        'border-brand-blue',
        'hover:text-brand-blue-grey',
        'hover:fill-brand-blue-grey',
        'hover:border-brand-blue-grey',
        'active:border-brand-blue-light',
        'active:text-brand-blue-light',
        'active:fill-brand-blue-light',
        'border',
        'bg-transparent',
      ],
      secondaryTransparent: [
        'text-brand-blue',
        'fill-brand-blue',
        'border-brand-blue',
        'hover:bg-brand-blue',
        'active:bg-brand-blue-light',
        'border',
        'bg-transparent',
        'hover:fill-white',
        'hover:text-white',
        'active:border-transparent',
        'active:fill-white',
        'active:text-white',
      ],
    },
    size: {
      small: ['text-btn-sm', 'px-4', 'py-1.5'],
      medium: ['text-btn-sm', 'px-6', 'py-3'],
      large: ['text-btn-sm', 'px-6', 'py-4'],
    },
    block: {
      true: 'flex w-full',
      false: 'inline-flex w-fit',
    },
    disabled: {
      true: 'cursor-not-allowed opacity-50',
    },
    loading: {
      true: 'cursor-wait',
    },
    hasIcon: {
      true: 'flex gap-3',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'medium',
  },
})

const MisterButton: FunctionComponent<PropsWithChildren<MisterButtonProps>> = ({
  type,
  onClick,
  buttonText,
  size,
  variant,
  link,
  icon = 'none',
  disabled,
  loading = false,
  block = false,
  className,
  e2eSelector,
  children,
}) => {
  const componentMap: Record<string, any> = { MisterSiteLink }
  // const { buttonText, size, variant, link, icon = 'none' } = button || {}

  // This retrieves the component or tag to use based on if onClick || button type is defined
  const Component = type === 'a' ? 'MisterSiteLink' : 'button'
  const ComponentToRender = componentMap[Component] || 'button'

  return (
    <ComponentToRender
      data-label='MisterButton'
      type={Component === 'button' && !onClick ? type : undefined}
      link={link ? link : null}
      disabled={disabled || loading}
      onClick={onClick}
      data-testid={e2eSelector}
      className={buttonClasses({
        variant,
        size,
        block,
        disabled,
        loading,
        hasIcon: icon !== 'none',
        className,
      })}
    >
      {loading && (
        <span className='mr-3'>
          <MisterSpinner show={loading} size='micro' inline={true} />
        </span>
      )}
      {/* This span stops React losing track of the elements when Google Translate changes the DOM */}
      <span className='contents'>{buttonText}</span>
      {icon !== 'none' && <MisterIcon type={icon} className='size-4' />}
      {children}
    </ComponentToRender>
  )
}

export default MisterButton
