import { FunctionComponent, useEffect } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useCookie } from 'react-use'

import { SupportedLocale } from 'data-access/domain/constants'

import { useTelemetry } from '../../contexts/Telemetry.context'
import useCart from '../../hooks/shop/useCart'
import { useReadyStateEffect } from '../../hooks/utils/useReadyStateEffect'
import { ELEVAR_USER_ID_COOKIE } from '../../middleware'
import { ecommerceInfoUserData, elevarScript, getLocalizedElevarKey } from '../../utils/elevar.util'
import { get } from '../../utils/localStorage.util'
import { ELEVAR_DATA_LAYER_EVENTS, trackEvent } from '../../utils/telemetry.util'
import { isBrowser } from '../../utils/window.util'

const MisterElevar: FunctionComponent = () => {
  const { asPath, isReady, locale } = useRouter()
  const [customUserId] = useCookie(ELEVAR_USER_ID_COOKIE)
  const { cart, isLoading: cartIsLoading } = useCart()
  const { userProperties } = useTelemetry()
  const elevarKey = getLocalizedElevarKey(locale as SupportedLocale)

  // Let Elevar grab our custom user ID
  // @see https://docs.getelevar.com/docs/how-to-pass-my-own-user-identifier
  if (isBrowser) {
    window.ElevarUserIdFn = () => {
      return customUserId
    }
  }

  // Insert Elevar user data layer on route changes
  useReadyStateEffect(() => {
    if (cartIsLoading || !isReady) {
      return
    }

    setTimeout(() => {
      trackEvent(ELEVAR_DATA_LAYER_EVENTS.DL_USER_DATA, {
        cart_total: parseFloat(cart?.cost?.totalAmount?.amount || '').toFixed(2),
        user_properties: userProperties,
        ecommerce: ecommerceInfoUserData(cart, get('elevar-list') ?? ''),
      })
    }, 300)
  }, [asPath, isReady, cartIsLoading])

  // Inform Elevar about route changes
  useEffect(() => {
    if (isReady && window?.ElevarInvalidateContext) {
      window?.ElevarInvalidateContext()
    }
  }, [asPath, isReady])

  // Elevar won't load on localhost
  if (!elevarKey || process.env.NODE_ENV !== 'production') {
    return null
  }

  return <Script id='elevar' strategy='afterInteractive' type='module' dangerouslySetInnerHTML={{ __html: elevarScript(elevarKey) }} />
}

export default MisterElevar
