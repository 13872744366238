import { getErrorMessage } from 'utilities/errors/errorMessage'

export const multipass = async (email: string, webUrl: string, locale: string | undefined | null) => {
  if (!email) {
    return webUrl
  }

  try {
    const response = await fetch('/api/shopify/account/multipass', {
      method: 'POST',
      body: JSON.stringify({
        email,
        webUrl,
        locale,
      }),
    })

    const data = await response.json()

    if (response.ok) {
      return data.url
    }

    console.error('Multipass failed: Check Shopify multipass secret and input')

    // If multipass doesn't work, we send original webUrl
    return webUrl
  } catch (error) {
    console.error('Multipass failed: ', getErrorMessage(error))

    return null
  }
}

export default multipass
