import { createContext, useContext } from 'react'

import { AccountData } from 'data-access/sanity/fragments/globals/account.fragment'

import { ContextCustomer } from '@/types/contextCustomer'

export const AccountContext = createContext<{ customer: ContextCustomer | null; accountData: AccountData | null; storeDomain?: string | null }>({
  customer: null,
  accountData: null,
  storeDomain: null,
})

export const useAccountContext = () => {
  const { customer, accountData, storeDomain } = useContext(AccountContext)
  if (!customer || !accountData || !storeDomain) throw new Error('useAccountContext must be used within AccountContextProvider')
  return { customer, accountData, storeDomain }
}
