import { FunctionComponent } from 'react'

import { SectionTextMediaCtaData } from 'data-access/sanity/fragments/sections/sectionTextMediaCta.fragment'
import { cn } from 'ui/lib/utils'

import MisterCtaButton from 'src/components/shared/MisterCtaButton'
import MisterMedia from '../../shared/media/MisterMedia'
import MisterContainer from '../../shared/MisterContainer'
import RichText from '../../shared/rich-text/rich-text'

interface Props {
  data: SectionTextMediaCtaData
  index: number
}

const SectionTextMediaCta: FunctionComponent<Props> = ({ data, index }) => {
  const { _id, title, text, copyPositionDesktop, cta } = data || {}
  return (
    <MisterContainer
      padding={false}
      type='default'
      dataLabel='section-text-media-cta'
      className={cn('relative flex w-full flex-col-reverse gap-2 px-4 md:px-10 xl:mx-auto', copyPositionDesktop === 'left' ? 'sm:flex-row' : 'sm:flex-row-reverse')}
      data-id={_id}
    >
      <div className={cn('flex flex-col justify-end gap-6 py-7 text-brand-blue sm:w-1/2 sm:py-14', copyPositionDesktop === 'left' ? 'sm:pl-0 sm:pr-10' : 'sm:pl-10')}>
        <div className='flex flex-col gap-6'>
          <h3 className='w-[clamp(10ch,60ch,90%)] text-heading-5 md:text-heading-4'>{title}</h3>
          {text && <RichText data={text} className='w-[clamp(10ch,60ch,80%)]' />}
          {cta && <MisterCtaButton cta={{ ...cta, size: 'large' }} />}
        </div>
      </div>
      <div className='aspect-square w-full overflow-hidden rounded-md sm:w-1/2'>
        <MisterMedia media={data?.media} sectionIndex={index} className='size-full object-cover' />
      </div>
    </MisterContainer>
  )
}

export default SectionTextMediaCta
