import { FunctionComponent, PropsWithChildren } from 'react'
import { FocusTrap } from 'focus-trap-react'

interface FocusTrapWrapperProps {
  escapeDeactivates: boolean
  returnFocusOnDeactivate: boolean
  isFocusTrapEnabled: boolean
  onDeactivate?: () => void
  initialFocus?: (() => void) | undefined
  onActivate?: () => void
}

export const FocusTrapWrapper: FunctionComponent<PropsWithChildren<FocusTrapWrapperProps>> = ({ children, isFocusTrapEnabled, ...rest }) => (
  <FocusTrap focusTrapOptions={rest} active={isFocusTrapEnabled}>
    {children}
  </FocusTrap>
)
