import { FunctionComponent, PropsWithChildren } from 'react'

import { Media } from 'data-access/sanity/fragments/components/media.fragment'

import MisterMedia from '../media/MisterMedia'

interface MisterAuthPageContainerProps {
  image: Media
}

const MisterAuthPageContainer: FunctionComponent<PropsWithChildren<MisterAuthPageContainerProps>> = ({ image, children }) => (
  <section className='flex flex-col lg:grid lg:min-h-[80vh] lg:grid-cols-2'>
    <div className='relative hidden lg:flex'>
      <MisterMedia media={image} className='object-cover' />
    </div>
    <div className='px-4'>
      <div className='mx-auto w-full max-w-sm'>
        <div className='flex flex-col gap-16 pb-20 pt-12 lg:py-24'>{children}</div>
      </div>
    </div>
  </section>
)

export default MisterAuthPageContainer
