import { FunctionComponent } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoaderCircle } from 'lucide-react'
import { useRouter } from 'next/router'
import { useSearchParam } from 'react-use'
import { z } from 'zod'

import { SectionActivateAccountData } from 'data-access/sanity/fragments/sections/sectionActivateAccount.fragment'
import { Button } from '@ui/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormMessage, useForm } from '@ui/components/ui/form'
import { InputFloatingLabel } from '@ui/components/ui/input-floating-label'

import MisterAuthPageContainer from 'src/components/shared/account/MisterAuthPageContainer'
import useActivateCustomer from 'src/hooks/customer/useActivateCustomer'

interface SectionActivateAccountProps {
  data: SectionActivateAccountData
}

const SectionActivateAccount: FunctionComponent<SectionActivateAccountProps> = ({ data }) => {
  const { title, subtitle, backgroundImage, passwordLabel, passwordErrorMessage, confirmPasswordLabel, passwordMatchErrorMessage, submitButtonText } = data || {}

  const [activateCustomer, activateState] = useActivateCustomer()
  const id = useSearchParam('id')
  const modifiedId = `gid://shopify/Customer/${id}`
  const token = useSearchParam('token')

  const { locale, replace } = useRouter()

  const activateFormSchema = z
    .object({
      password: z.string().min(6, { message: passwordErrorMessage }),
      confirmPassword: z.string().min(6, { message: passwordErrorMessage }),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: passwordMatchErrorMessage,
      path: ['confirmPassword'], // Specify the field path for the error message
    })

  const form = useForm<z.infer<typeof activateFormSchema>>({
    mode: 'onBlur',
    resolver: zodResolver(activateFormSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  const onSubmit = async (values: z.infer<typeof activateFormSchema>) => {
    const { password, confirmPassword } = values
    await activateCustomer(password, confirmPassword, modifiedId, token)

    if (activateState.response) {
      await replace(`${locale !== 'en' ? `/${locale}/` : '/'}account`)
    }
  }

  return (
    <MisterAuthPageContainer image={backgroundImage}>
      <div className='flex flex-col gap-9'>
        <div className='flex flex-col gap-2'>
          <h2 className='text-heading-3'>{title}</h2>
          {subtitle && <p className='text-body-md'>{subtitle}</p>}
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className='flex flex-col gap-y-3'>
            <FormField
              control={form.control}
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputFloatingLabel label={passwordLabel} type='password' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='confirmPassword'
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputFloatingLabel label={confirmPasswordLabel} type='password' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className='flex flex-col gap-2'>
              <Button type='submit' variant='primary' className='mt-7'>
                {activateState.loading ? <LoaderCircle className='size-4 animate-spin' /> : submitButtonText}
              </Button>
              {activateState.error && <FormMessage className='text-center'>{activateState.error}</FormMessage>}
            </div>
          </form>
        </Form>
      </div>
    </MisterAuthPageContainer>
  )
}

export default SectionActivateAccount
