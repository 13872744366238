import { CSSProperties, FC } from 'react'

import { Badge } from '@ui/components/ui/badge'
import { cn } from '@ui/lib/utils'

import { getContrastTextColor } from 'src/utils/color.util'

type Props = {
  discount: number
  backgroundColor?: string
  className?: string
}

export const DiscountBadge: FC<Props> = ({ discount, backgroundColor, className }) => {
  const contrastedTextColor = backgroundColor ? getContrastTextColor(backgroundColor) : '#000000'

  if (!discount) return null

  return (
    <Badge
      style={
        {
          '--backgroundColor': backgroundColor,
          '--contrastedTextColor': contrastedTextColor,
        } as CSSProperties
      }
      size='lg'
      className={cn(
        'float-right mx-1 bg-brand-orange text-black',
        backgroundColor ? `bg-[var(--backgroundColor)]` : 'bg-brand-orange',
        contrastedTextColor ? `text-[var(--contrastedTextColor)]` : 'text-black',
        className,
      )}
    >
      {`-${discount}%`}
    </Badge>
  )
}
