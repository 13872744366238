import { FunctionComponent, ReactNode, TouchEvent, useState } from 'react'

interface TouchPosition {
  x: number
  y: number
}

interface TouchHandlerProps {
  children?: ReactNode
  onDragStart?: () => void
  onDragEnd?: () => void
}

const MisterTouchHandler: FunctionComponent<TouchHandlerProps> = ({ children, onDragStart, onDragEnd }) => {
  const [isBeingDragged, setIsBeingDragged] = useState<boolean>(false)
  const [touchStart, setTouchStart] = useState<TouchPosition | null>(null)

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>): void => {
    setTouchStart({
      x: e.touches[0]?.clientX || 0,
      y: e.touches[0]?.clientY || 0,
    })
  }

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>): void => {
    if (!touchStart) return

    const xDiff = touchStart.x - (e.touches[0]?.clientX || 0)
    const yDiff = touchStart.y - (e.touches[0]?.clientY || 0)

    // Only set dragging state if horizontal movement is greater than vertical
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (!isBeingDragged) {
        setIsBeingDragged(true)
        onDragStart?.()
      }
    }
  }

  const handleTouchEnd = (): void => {
    if (isBeingDragged) {
      onDragEnd?.()
    }
    setIsBeingDragged(false)
    setTouchStart(null)
  }

  return (
    <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
      {children}
    </div>
  )
}

export default MisterTouchHandler
