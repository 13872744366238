import { FunctionComponent, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowRight, LoaderCircle } from 'lucide-react'
import { useRouter } from 'next/router'
import { useToggle } from 'react-use'
import { z } from 'zod'

import { Form, FormControl, FormField, FormItem, FormMessage, useForm } from 'ui/components/ui/form'
import { Button } from '@ui/components/ui/button'
import { InputFloatingLabel } from '@ui/components/ui/input-floating-label'

import { useTranslations } from 'src/contexts/Globals.context'
import { useTelemetry } from 'src/contexts/Telemetry.context'
import { ELEVAR_DATA_LAYER_EVENTS, trackEvent } from '../../../utils/telemetry.util'

interface Props {
  emailSectionHeader: string
  emailSectionText: string
  emailSectionPlaceholder: string
}

const MisterFooterEmailCapture: FunctionComponent<Props> = ({ emailSectionHeader, emailSectionText, emailSectionPlaceholder }) => {
  const translate = useTranslations()
  const { storeEmailUnverified } = useTelemetry()
  const [emailSubscribeError, setErrorMessage] = useState<string | null>()
  const [emailSubscribeMessage, setSuccessMessage] = useState<string | null>()
  const [loading, setLoading] = useToggle(false)
  const { locale } = useRouter()

  const newsletterSignUpFormSchema = z.object({
    email: z.string().email({ message: translate('emailHint') }),
  })

  const form = useForm<z.output<typeof newsletterSignUpFormSchema>>({
    resolver: zodResolver(newsletterSignUpFormSchema),
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = async (values: z.infer<typeof newsletterSignUpFormSchema>) => {
    setLoading(true)

    const { email } = values

    const finishSubmission = () => {
      setLoading(false)
      storeEmailUnverified(email)

      setTimeout(() => {
        setErrorMessage(null)
        setSuccessMessage(null)
      }, 5000)

      trackEvent(ELEVAR_DATA_LAYER_EVENTS.DL_SUBSCRIBE, {
        lead_type: 'email',
        user_properties: {
          customer_email: email,
        },
      })
    }

    try {
      const res = await fetch('/api/customer/sign-up-customer-to-email-marketing', {
        method: 'POST',
        body: JSON.stringify({ email, locale }),
      })

      if (!res.ok) {
        setErrorMessage(translate('emailSubscribeError', 'Something went wrong.'))
        throw new Error('Failed to subscribe')
      }

      setSuccessMessage(translate('emailSubscribeSuccess', 'Thank you for subscribing to our mailing list.'))
    } catch (error: unknown) {
      console.error(error)
      setErrorMessage(translate('emailSubscribeError', 'Something went wrong.'))
    }

    return finishSubmission()
  }

  return (
    <div className='mx-auto flex w-fit flex-col items-center gap-6'>
      <div className='flex flex-col gap-4 text-center'>
        <h2 className='text-heading-5 md:text-heading-3'>{emailSectionHeader}</h2>
        <p className='text-body-md'>{emailSectionText}</p>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className='flex w-full max-w-96 flex-col'>
          <FormField
            control={form.control}
            name='email'
            render={({ field }) => (
              <FormItem>
                <div className='relative'>
                  <FormControl>
                    <InputFloatingLabel type='email' label={emailSectionPlaceholder} className='pr-14' {...field} />
                  </FormControl>
                  <Button className='absolute right-2 top-1/2 size-9 -translate-y-1/2' size='icon' title='submit'>
                    {loading ? <LoaderCircle className='size-4 animate-spin' /> : <ArrowRight />}
                  </Button>
                </div>
                <FormMessage className='ml-3 text-body-sm' />
                {!loading && emailSubscribeError && <FormMessage>{emailSubscribeError}</FormMessage>}
                {!loading && emailSubscribeMessage && <FormMessage className='text-brand-blue'>{emailSubscribeMessage}</FormMessage>}{' '}
              </FormItem>
            )}
          />
        </form>
      </Form>
    </div>
  )
}

export default MisterFooterEmailCapture
