import { FunctionComponent, ReactNode, useRef } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useClickAway, useCookie, useToggle } from 'react-use'

import { cn } from 'ui/lib/utils'

import { useTranslations } from '../../contexts/Globals.context'
import useTransitionMount from '../../hooks/utils/useTransitionMount'
import { I18N_COUNTRY_CODE_PREFERENCE } from '../../middleware'
import { defaultCookieConfig } from '../../utils/cookie.util'
import { countiesAndRegions } from '../../utils/i18n.util'
import MisterIcon from './MisterIcon'

interface Props {
  type: 'footerMenu' | 'desktopHeader' | 'mobileMenu'
  pageTop?: boolean
  closeMobileMenu?: () => void
}

export const Flag: FunctionComponent<{ code?: string }> = ({ code }): ReactNode => {
  const correctedCode = code === 'en' ? 'eu' : code
  const country = correctedCode ? countiesAndRegions.find((c) => c.isoAlpha2 === correctedCode.toUpperCase()) : false

  return (
    <span className='flex w-[15px] items-center justify-center empty:hidden' role='img' aria-label={correctedCode} aria-hidden={true}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      {country && <img src={`data:image/png;base64,${country?.flag}`} alt={correctedCode || ''} width={30} height={20} />}
    </span>
  )
}

const CountryPicker: FunctionComponent<Props> = ({ type, closeMobileMenu = null, pageTop = false }) => {
  const translate = useTranslations()
  const { locale, locales, reload } = useRouter()
  const [showDropdown, setShowDropdown] = useToggle(false)
  const dropdownHasTransitionedIn = useTransitionMount(showDropdown, 150)
  const selectRef = useRef<HTMLDivElement>(null)
  const [value, updateCookie] = useCookie(I18N_COUNTRY_CODE_PREFERENCE)
  const [, setCPCookie] = useCookie('usedCountryPicker')

  const localesForDropdown = (locales || []).filter(
    (country) =>
      // Do not add current locale to dropdown
      country !== locale &&
      // Only add values to dropdown with a label/translation
      translate(`${country}CountryPicker`),
  )

  const handleClick = async (e: any, country: string) => {
    e.preventDefault()

    if (value !== country) {
      setCPCookie('true', defaultCookieConfig)
      updateCookie(country, defaultCookieConfig)
    }

    reload()

    if (type === 'desktopHeader') {
      return setShowDropdown(false)
    } else if (type === 'mobileMenu' && closeMobileMenu) {
      return closeMobileMenu()
    }
  }

  useClickAway(selectRef, () => {
    if (showDropdown) {
      setShowDropdown(false)
    }
  })

  return (
    <div
      data-testid='country-picker'
      ref={selectRef}
      data-current-locale={locale || '?'}
      onMouseLeave={type === 'desktopHeader' ? () => setShowDropdown(false) : undefined}
      onClick={type === 'mobileMenu' || type === 'footerMenu' ? () => setShowDropdown(!showDropdown) : undefined}
      className={cn(
        type === 'desktopHeader' && 'hidden group-hover:text-brand-blue xl:flex',
        type === 'desktopHeader' && pageTop && 'text-white',
        type === 'mobileMenu' && 'flex items-center justify-between gap-4 px-4 py-5 text-brand-blue',
        type === 'footerMenu' && 'flex items-center gap-4 border border-white px-2 py-3 text-white',
        'group/dropdown relative cursor-pointer items-center text-body-md-bold transition-colors duration-150 ease-linear',
      )}
    >
      {type === 'desktopHeader' && (
        <div className='py-5 xl:flex' onMouseEnter={type === 'desktopHeader' ? () => setShowDropdown(true) : undefined}>
          <Flag code={locale} />
          <MisterIcon type='chevron-down' className='size-5 group-hover/dropdown:rotate-180' />
        </div>
      )}
      {type === 'mobileMenu' && (
        <>
          <Flag code={locale} />
          <span className='text-body-sm-bold'>{translate(`${locale}CountryPicker`)}</span>
          <MisterIcon type='chevron-down' className={cn(!showDropdown && 'rotate-180', 'w-4')} />
        </>
      )}
      {type === 'footerMenu' && (
        <>
          <span className='pl-1'>
            <Flag code={locale} />
          </span>
          <span className='text-body-sm-bold'>{translate(`${locale}CountryPicker`)}</span>
          <MisterIcon type='chevron-down' className={cn(!showDropdown && 'rotate-180', 'ml-auto w-4')} />
        </>
      )}
      {(showDropdown || dropdownHasTransitionedIn) && (
        <div
          className={cn(
            'absolute flex flex-col gap-1 whitespace-nowrap pt-1.5 opacity-0 transition-opacity duration-150',
            type === 'desktopHeader' && '-left-4 top-[3.75rem] w-min min-w-[155px] bg-white pb-3.5 shadow-xl',
            showDropdown && dropdownHasTransitionedIn ? 'opacity-100' : '',
            type === 'mobileMenu' && 'bottom-14 left-0 right-0 w-[320px] bg-white shadow-[0_-10px_10px_rgb(0_0_0_/10%)]',
            type === 'footerMenu' && 'bottom-10 left-[-1px] right-[-1px] border border-white bg-brand-blue',
          )}
        >
          {localesForDropdown.map((country: string) => (
            <Link
              key={`country-${country}`}
              onClick={(e) => handleClick(e, country)}
              href='/'
              locale={country}
              className='relative flex items-center gap-3 whitespace-nowrap px-4 py-2 font-normal leading-none hover:bg-brand-beige-light hover:font-bold hover:text-brand-blue'
            >
              <Flag code={country} />
              <span
                data-testid={`country-picker-${country}`}
                data-placeholder={translate(`${country}CountryPicker`)}
                className='after:invisible after:block after:h-0 after:font-bold after:content-[attr(data-placeholder)]'
              >
                {translate(`${country}CountryPicker`)}
              </span>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

export default CountryPicker
