import { getAvailableCountries as getAvailableCountriesDataAccess } from 'data-access'
import { SupportedLocale } from 'data-access/domain/constants'
import { AvailableCountriesDto } from 'data-access/shopify/fragments/customer/availableCountriesQuery.fragment'

export const getAvailableCountries = async (locale: SupportedLocale): Promise<AvailableCountriesDto['data']['localization']> => {
  try {
    return await getAvailableCountriesDataAccess(locale)
  } catch (error) {
    console.error('Error fetching available countries:', error)
    // Fallback to default countries if API fails
    return {
      availableCountries: [
        { name: 'Belgium', isoCode: 'BE' },
        { name: 'Denmark', isoCode: 'DK' },
        { name: 'France', isoCode: 'FR' },
        { name: 'Germany', isoCode: 'DE' },
        { name: 'Italy', isoCode: 'IT' },
        { name: 'Netherlands', isoCode: 'NL' },
        { name: 'Spain', isoCode: 'ES' },
        { name: 'United Kingdom', isoCode: 'GB' },
        { name: 'United States', isoCode: 'US' },
      ],
      country: { name: '', isoCode: '' },
    }
  }
}
