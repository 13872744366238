import { FunctionComponent, PropsWithChildren } from 'react'

import { cn } from '@ui/lib/utils'

type Motion = 'from-start' | 'to-start' | 'from-end' | 'to-end' | null

interface TransitionContainerProps {
  motion?: Motion
  onAnimationEnd?: () => void
  className?: string
}

const TransitionContainer: FunctionComponent<PropsWithChildren<TransitionContainerProps>> = ({ children, motion, onAnimationEnd, className }) => {
  return (
    <div
      data-motion={motion}
      className={cn(
        'absolute inset-0 flex h-full flex-col gap-10 data-[motion=from-end]:duration-300 data-[motion=from-start]:duration-300 data-[motion=to-end]:duration-300 data-[motion=to-start]:duration-300 data-[motion=from-end]:animate-in data-[motion=from-start]:animate-in data-[motion=to-end]:animate-out data-[motion=to-start]:animate-out data-[motion=from-end]:fade-in data-[motion=from-start]:fade-in data-[motion=to-end]:fade-out data-[motion=to-start]:fade-out data-[motion=from-end]:slide-in-from-right-12 data-[motion=from-start]:slide-in-from-left-12 data-[motion=to-end]:slide-out-to-right-12 data-[motion=to-start]:slide-out-to-left-12 data-[motion=from-end]:fill-mode-forwards data-[motion=from-start]:fill-mode-forwards data-[motion=to-end]:fill-mode-forwards data-[motion=to-start]:fill-mode-forwards',
        className,
      )}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </div>
  )
}

export default TransitionContainer
