import { FC } from 'react'

import { cn } from '@ui/lib/utils'

import MisterSkeletonLoader from './MisterSkeletonLoader'

type Props = {
  className?: string
}

const SearchResultsSkeleton: FC<Props> = ({ className }) => {
  return (
    <ol className={cn('grid list-none grid-cols-2 grid-rows-[auto] gap-2.5 gap-y-5 @3xl/product-grid:grid-cols-3 @7xl/product-grid:grid-cols-4 lg:gap-x-3 lg:gap-y-6', className)}>
      {[...Array(10)].map((_, i) => (
        <li key={`search-results-skeleton-${i}`} className='flex flex-col'>
          <MisterSkeletonLoader className='aspect-[2/3] w-full' />
          <div className='flex flex-col gap-2'>
            <div className='flex w-full flex-row gap-2 pr-2 pt-3'>
              <MisterSkeletonLoader className='h-4 w-2/5' />
              {/* Hardcoded to match the height of the actual product card */}
              <MisterSkeletonLoader className='ml-auto h-[17.5px] w-10' />
            </div>

            <MisterSkeletonLoader className='h-4 w-2/3' />
          </div>
        </li>
      ))}
    </ol>
  )
}

export default SearchResultsSkeleton
