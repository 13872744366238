import { getLocalizedAnnouncementBar } from 'data-access'
import { SupportedLocale } from 'data-access/domain/constants'
import { AnnouncementBar } from 'data-access/sanity/fragments/components/announcementBar.fragment'

export interface GetAnnouncementBarParams {
  countryCode: string
  locale: SupportedLocale
  defaultAnnouncementBar?: AnnouncementBar
}

export const getAnnouncementBar = async ({ countryCode, locale, defaultAnnouncementBar }: GetAnnouncementBarParams) => {
  try {
    const data = await getLocalizedAnnouncementBar(countryCode.toLowerCase(), locale)
    return data || defaultAnnouncementBar
  } catch (error) {
    console.error('Error fetching announcement bar:', error)
    return defaultAnnouncementBar
  }
}
