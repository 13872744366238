import { ElementType, FunctionComponent } from 'react'

import { SectionBannerCtaData } from 'data-access/sanity/fragments/sections/sectionBannerCta.fragment'
import { cn } from 'ui/lib/utils'

import MisterCtaButton from 'src/components/shared/MisterCtaButton'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import MisterMedia from '../../shared/media/MisterMedia'
import MisterContainer from '../../shared/MisterContainer'

// TODO: Removing logic in favour of templated design

interface Props {
  data: SectionBannerCtaData
  index: number
  container?: ElementType
  priority?: boolean
  fixedAspectRatio?: boolean
}

const SectionBannerCta: FunctionComponent<Props> = ({
  data: {
    _id,
    copyPosition = 'middle',
    copyPositionDesktop = 'middle',
    copyPositionVertical,
    copyPositionVerticalDesktop,
    ctaToBottom,
    cta,
    ctaType,
    media,
    padding,
    subtitle,
    title,
    titleCase,
    titleFont,
    textShadowTitle,
    textShadowSubtitle,
    hideTitle,
    darkTitle,
    darkSubtitle,
  },
  index,
  container,
  priority,
  fixedAspectRatio = false,
}) => {
  const TitleTag = index === 0 ? 'h1' : 'p'
  const Element = index === 0 && container === undefined ? 'header' : container || 'section'

  const isEasiesBanner = titleFont === 'easies'
  const isSS25Banner = titleFont === 'ss25'

  return (
    <MisterContainer type='fluid' as={Element} dataLabel='section-banner-cta' padding={false} className={cn('relative bg-brand-blue', padding && 'm-6 md:m-12')} data-id={_id}>
      <div className='relative after:absolute after:inset-0 after:bg-black/10'>
        <MisterMedia
          quality='80'
          media={media}
          sectionIndex={index}
          priority={priority}
          className={cn('object-cover object-center', fixedAspectRatio && 'aspect-square md:aspect-[3/1]')}
          mobileSizes={padding ? '95vw' : '100vw'}
          desktopSizes={padding ? '95vw' : '100vw'}
        />
      </div>
      <div
        className={cn(
          'absolute bottom-2 left-1/2 top-2 grid w-full max-w-[1330px] -translate-x-1/2 grid-cols-12 gap-3',
          padding && 'md:px-12',
          copyPosition === 'farLeft' && 'left-0 -translate-x-0',
          copyPositionDesktop === 'farLeft' && 'left-0 -translate-x-0',
          copyPositionVertical === 'bottom' ? 'bottom-[10%] content-end' : 'bottom-0 content-center',
          copyPositionVertical === 'farBottom' ? 'bottom-4 content-end' : 'bottom-0 content-center',
          copyPositionVerticalDesktop === 'bottom' ? 'md:bottom-[20%] md:content-end' : 'md:bottom-0 md:content-center',
          copyPositionVerticalDesktop === 'farBottom' ? 'md:bottom-12 md:content-end' : 'md:bottom-0 md:content-center',
        )}
      >
        <div
          className={cn(
            copyPosition === 'farLeft' && 'col-span-10 col-start-1 ml-3 items-start',
            copyPosition === 'left' && 'col-span-10 col-start-2 items-start',
            copyPosition === 'middle' && 'col-span-10 col-start-2 items-center',
            copyPosition === 'right' && 'col-span-10 col-start-2 items-end',
            copyPositionDesktop === 'farLeft' && 'md:col-span-10 md:col-start-1 md:ml-7 md:items-start',
            copyPositionDesktop === 'left' && 'md:col-span-10 md:col-start-2 md:items-start',
            copyPositionDesktop === 'middle' && 'md:col-span-10 md:col-start-2 md:items-center',
            copyPositionDesktop === 'right' && 'md:col-span-10 md:col-start-2 md:items-end',
            'isolate flex flex-col gap-8',
          )}
        >
          <div
            className={cn(
              copyPosition === 'farLeft' && 'items-start',
              copyPosition === 'left' && 'items-start',
              copyPosition === 'middle' && 'items-center text-center',
              copyPosition === 'right' && 'items-end text-right',
              copyPositionDesktop === 'farLeft' && 'md:items-start md:text-left',
              copyPositionDesktop === 'left' && 'md:items-start md:text-left',
              copyPositionDesktop === 'middle' && 'md:items-center md:text-center',
              copyPositionDesktop === 'right' && 'md:items-end md:text-right',
              'flex max-w-[80%] flex-col gap-2 md:max-w-[100%] lg:max-w-[80%] xl:max-w-[60%]',
            )}
          >
            <TitleTag
              className={cn(
                'font-bold',
                isSS25Banner && `font-ss25 text-heading-3-ss25 uppercase [text-shadow:2.484px_2.484px_0px_#C0172A] md:text-heading-1-ss25 md:[text-shadow:6px_6px_0px_#C0172A]`,
                isEasiesBanner && `font-easies text-heading-3-easies font-normal md:text-heading-1-easies`,
                !isEasiesBanner && !isSS25Banner && 'text-heading-3 md:text-heading-1',
                textShadowTitle === 'large' && 'drop-shadow-brand-lg md:drop-shadow-brand-lg',
                textShadowTitle === 'default' && 'drop-shadow-brand-md md:drop-shadow-brand-md',
                darkTitle?.mobile ? 'text-brand-blue !drop-shadow-none' : 'text-white',
                darkTitle?.desktop ? 'md:text-brand-blue md:!drop-shadow-none' : 'md:text-white',
                hideTitle && 'sr-only',
              )}
            >
              {title}
            </TitleTag>
            {subtitle && (
              <p
                className={cn(
                  'text-body-lg-bold md:text-body-xl-bold',
                  !darkSubtitle?.mobile && textShadowSubtitle === 'large' && 'drop-shadow-brand-lg',
                  !darkSubtitle?.mobile && textShadowSubtitle === 'default' && 'drop-shadow-brand-md',
                  !darkSubtitle?.desktop && textShadowSubtitle === 'large'
                    ? 'md:drop-shadow-brand-lg'
                    : !darkSubtitle?.desktop && textShadowSubtitle === 'default'
                      ? 'md:drop-shadow-brand-md'
                      : 'md:drop-shadow-none',
                  darkSubtitle?.mobile ? 'text-brand-blue' : 'text-white',
                  darkSubtitle?.desktop ? 'md:text-brand-blue' : 'md:text-white',
                )}
              >
                {subtitle}
              </p>
            )}
          </div>
          {cta && (
            <div className={cn(ctaToBottom?.mobile && 'absolute bottom-[10%]', ctaToBottom?.desktop ? 'md:absolute md:bottom-[20%]' : 'md:static md:bottom-[unset]')}>
              {ctaType === 'button' ? (
                <MisterCtaButton cta={{ ...cta, size: 'large' }} />
              ) : (
                <MisterSiteLink link={cta.link} className='flex w-full flex-col gap-2 text-body-md-bold text-white underline'>
                  {cta.buttonText}
                </MisterSiteLink>
              )}
            </div>
          )}
        </div>
      </div>
    </MisterContainer>
  )
}

export default SectionBannerCta
