import { FunctionComponent } from 'react'

import { SectionImageLinkData } from 'data-access/sanity/fragments/sections/sectionImageLink.fragment'
import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/lib/utils'

import MisterImage from 'src/components/shared/image/MisterImage'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { useSectionLayout } from 'src/contexts/SectionLayout.context'

interface SectionImageLinkProps {
  data: SectionImageLinkData
}

const SectionImageLink: FunctionComponent<SectionImageLinkProps> = ({ data }) => {
  const layoutData = useSectionLayout(data._id)

  const { sizes } = layoutData || {}

  return (
    <section className='size-full @container-[inline-size]'>
      <MisterSiteLink link={data.link} className={cn('grid size-full w-full grid-cols-1 grid-rows-1', !layoutData && 'px-4 @3xl:px-10')}>
        <div
          className={cn(
            'relative col-span-1 col-start-1 row-span-1 row-start-1 overflow-hidden rounded-md after:absolute after:inset-0 after:bg-gradient-to-t after:from-black/40 after:to-transparent',
            !layoutData && '@5xl:aspect-video',
          )}
        >
          <MisterImage mobile={data.image} mobileSizes={sizes || '100vw'} className='size-full object-cover' />
        </div>
        <div className={cn('relative col-span-1 col-start-1 row-span-1 row-start-1 self-end p-6', !layoutData && '@3xl:p-10', data.asButton && 'place-self-end')}>
          <h5 className='text-body-xl-bold text-white @xl:text-heading-5'>{data.title}</h5>
          {data.link.linkText && (
            <Button variant={data.asButton ? 'secondary' : 'link'} className={!data.asButton && 'text-white'} size={!data.asButton ? 'sm' : 'default'}>
              {data.link.linkText}
            </Button>
          )}
        </div>
      </MisterSiteLink>
    </section>
  )
}

export default SectionImageLink
