import { FunctionComponent } from 'react'
import { X } from 'lucide-react'
import { useToggle } from 'react-use'

import { ShopifyCartLineItem } from 'data-access/shopify/types'
import { cn } from 'ui/lib/utils'
import { Button } from '@ui/components/ui/button'
import { formatPrice } from 'utilities/string/format/price'
import { convertSlug } from 'utilities/string/url'

import { useTranslations } from '../../../../contexts/Globals.context'
import { useTelemetry } from '../../../../contexts/Telemetry.context'
import { useUI } from '../../../../contexts/UI.context'
import useCart from '../../../../hooks/shop/useCart'
import { ecommerceInfoRemoveFromCart } from '../../../../utils/elevar.util'
import { get } from '../../../../utils/localStorage.util'
import { ELEVAR_DATA_LAYER_EVENTS, trackEvent } from '../../../../utils/telemetry.util'
import MisterShopifyImage from '../../../shared/image/MisterShopifyImage'
import MisterSpinner from '../../../shared/loader/MisterSpinner'
import MisterSiteLink from '../../../shared/site-link/MisterSiteLink'
import MisterQuantityButton from './MisterQuantityButton'

interface Props {
  lineItem: ShopifyCartLineItem
  locale?: string
}

const MisterCartLineItem: FunctionComponent<Props> = ({ lineItem, locale }) => {
  const translate = useTranslations()
  const { removeItems } = useCart()
  const { closeCart } = useUI()
  const { userProperties } = useTelemetry()
  const [isLoading, setIsLoading] = useToggle(false)
  const productHandle = lineItem?.merchandise?.product?.handle
  const isGiftCard = !!lineItem?.merchandise?.product?.tags.some((tag) => tag === 'gift-card')
  const algoliaProductHandle = `product-${productHandle}-${lineItem?.merchandise?.sku}`

  // As we only show the unit price regardless of the quantity the discount shown on line items must be per product
  const discount =
    lineItem?.discountAllocations
      ?.map((discount) => discount?.discountedAmount?.amount)
      ?.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue)
      }, 0) / lineItem?.quantity

  const Element = discount !== 0 ? 'del' : 'span'

  const handleRemoveFromCart = async () => {
    setIsLoading(true)
    await removeItems({ lineIds: [lineItem?.id], productHandle: algoliaProductHandle })
    setIsLoading(false)

    trackEvent(ELEVAR_DATA_LAYER_EVENTS.DL_REMOVE_FROM_CART, {
      user_properties: userProperties,
      ecommerce: ecommerceInfoRemoveFromCart(lineItem, get('elevar-list') ?? '', true, locale),
    })
  }

  return (
    <li className={cn('relative flex justify-between py-4', isLoading ? 'pointer-events-none opacity-60' : 'pointer-events-auto opacity-100')}>
      {isLoading && (
        <div className='absolute left-1/2 top-1/2'>
          <MisterSpinner show={isLoading} size='small' />
        </div>
      )}
      <div className='flex gap-4'>
        <MisterShopifyImage
          className='flex h-24 w-12 items-center justify-center bg-brand-grey md:w-16 2xl:h-32 2xl:w-24'
          image={lineItem?.merchandise?.image}
          width={64}
          height={96}
          alt={lineItem?.merchandise?.product?.title}
          sizes='4rem'
          priority={false}
        />
        <div className='flex flex-col gap-2 text-body-md'>
          <span className='min-w-44 text-body-md-bold'>
            {productHandle && (
              <MisterSiteLink className='hover:underline' link={convertSlug({ current: productHandle }, 'product')} onClick={closeCart}>
                {lineItem?.merchandise?.product?.title}
              </MisterSiteLink>
            )}
            {!productHandle && <span>{lineItem?.merchandise?.product?.title}</span>}
          </span>
          <div className='flex flex-col gap-1'>
            <div className='flex w-full items-center gap-9'>
              <span>{isGiftCard ? formatPrice(lineItem?.merchandise?.price?.amount, locale) : `${translate('sizeCartLabel', 'Size')} ${lineItem.merchandise?.title}`}</span>
              <div className='flex items-center justify-center'>
                <MisterQuantityButton lineItem={lineItem} setIsLoading={setIsLoading} type='minus' onRemove={handleRemoveFromCart} />
                <span className='px-2'>{lineItem.quantity}</span>
                <MisterQuantityButton lineItem={lineItem} setIsLoading={setIsLoading} type='plus' onRemove={handleRemoveFromCart} />
              </div>
            </div>
            <div className='flex w-full items-center justify-between'>
              {!isGiftCard && (
                <Element className={cn(discount !== 0 && 'text-brand-blue-dark/40 italic line-through')}>{formatPrice(lineItem?.merchandise?.price?.amount, locale)}</Element>
              )}
              {discount !== 0 && !isGiftCard && <span>{formatPrice(parseInt(lineItem?.merchandise?.price?.amount) - discount, locale)}</span>}
            </div>
          </div>
        </div>
      </div>
      <Button variant='ghost' onClick={handleRemoveFromCart} className='h-fit -translate-y-4 translate-x-4 p-4 hover:text-brand-warning' aria-label={translate('remove', 'Remove')}>
        <X className='size-5 stroke-[1.5]' />
      </Button>
    </li>
  )
}

export default MisterCartLineItem
