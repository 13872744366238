import { FunctionComponent } from 'react'

import { AlgoliaCollectionHit } from 'data-access/algolia/types'

import MisterMedia from '../media/MisterMedia'
import MisterSiteLink from '../site-link/MisterSiteLink'

type Props = {
  item: AlgoliaCollectionHit
  onClick?: () => void
}

export const MisterCollectionResult: FunctionComponent<Props> = ({ item, onClick }) => {
  const hasImage = item.images[0]

  return (
    <li
      data-insights-object-id={item.objectID ?? undefined} // Algolia data attr
      data-insights-position={item.__position ?? undefined} // Algolia data attr
      data-insights-query-id={item.__queryID ?? undefined} // Algolia data attr
    >
      <MisterSiteLink onClick={onClick} title={item.title} href={item.url} className='flex flex-col gap-2'>
        <div className='relative h-[240px] w-full overflow-hidden rounded-md sm:h-[500px] lg:h-[340px] 3xl:h-[500px]'>
          {hasImage ? <MisterMedia media={item.images[0]} className='size-full rounded-md object-cover' /> : <div className='size-full rounded-md bg-brand-grey' />}
        </div>
        <div className='flex h-full flex-col gap-1'>
          <h3 className='text-body-lg-bold'>{item.title}</h3>
          {item.content && <p className='truncate text-body-md'>{item.content}</p>}
        </div>
      </MisterSiteLink>
    </li>
  )
}
