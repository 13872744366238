import { FunctionComponent } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { Configure, InstantSearch, useTrendingItems } from 'react-instantsearch'

import { getProductsForRecommendations } from 'data-access'
import { algoliaLiteClient, getAlgoliaIndexName } from 'data-access/algolia/utils'
import { SupportedLocale } from 'data-access/domain/constants'

import { RecommendationsProvider } from 'src/contexts/Recommendations.context'
import MisterRecommendationsRow from './MisterRecommendationsRow'

type Props = {
  title: string
  limit?: number
}

const AlgoliaTrendingItems: FunctionComponent<Props> = ({ limit = 4, title }) => {
  const { locale } = useRouter()

  const { items } = useTrendingItems({
    limit,
    queryParameters: {
      filters: `isAvailable:true`,
    },
  })

  const { data, isLoading } = useQuery({
    queryKey: ['trendingProducts', items.map((item) => item.objectID), locale],
    queryFn: () =>
      getProductsForRecommendations(
        items.map((item) => item.slug.current),
        locale as SupportedLocale,
        limit,
      ),
    staleTime: 1000 * 60 * 60, // 1h
    enabled: items.length > 0,
    refetchOnWindowFocus: false,
  })

  if (!isLoading && (data || [])?.length === 0) {
    return null
  }

  return (
    <RecommendationsProvider type='trending'>
      <MisterRecommendationsRow title={title} products={data || []} isLoading={isLoading} />
    </RecommendationsProvider>
  )
}

const MisterTrendingItems: FunctionComponent<Props> = ({ limit = 4, title }) => {
  const { locale } = useRouter()
  const indexName = getAlgoliaIndexName(locale as string)

  return (
    <InstantSearch searchClient={algoliaLiteClient} indexName={indexName}>
      <Configure enablePersonalization={true} />
      <AlgoliaTrendingItems limit={limit} title={title} />
    </InstantSearch>
  )
}

export default MisterTrendingItems
