import { RangeValue as RangeValueDto, Unit } from 'data-access'

import { convertToUnit } from '../../number/format/distanceConversions'

/**
 * Formats a range value to a string with the unit specified.
 * @param value - The range value to format.
 * @param unit - The unit to format the range value in.
 * @returns The formatted range value.
 */
export const formatRangeValue = (value: RangeValueDto, unit: Unit): string => {
  const from = value.fromValue ? convertToUnit(value.fromValue, unit) : undefined
  const to = value.toValue ? convertToUnit(value.toValue, unit) : undefined

  if (from && to) {
    return `${from} - ${to}`
  }

  if (!from && to) {
    return `< ${to}`
  }

  if (!to && from) {
    return `> ${from}`
  }

  return ''
}
