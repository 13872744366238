import { FC } from 'react'
import { type SortByItem } from 'instantsearch.js/es/connectors/sort-by/connectSortBy'
import { SortByProps, useSortBy } from 'react-instantsearch'

import { getAlgoliaIndexName } from 'data-access/algolia/utils'
import { Label } from '@ui/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@ui/components/ui/radio-group'
import { cn } from '@ui/lib/utils'

import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import { useTranslations } from '../../../contexts/Globals.context'

type Props = SortByProps & {
  title: string
  locale: string
  className?: string
}

type SortByItemWithTranslateValue = SortByItem & {
  translateValue: string
}

const SearchSorting: FC<Props> = ({ title, items, className, locale }) => {
  const translate = useTranslations()

  const itemsWithSuffix = items.map(({ label, value }) => ({
    label,
    value: getAlgoliaIndexName(locale, value),
    translateValue: value,
  }))

  const { refine, currentRefinement, options } = useSortBy({ items: itemsWithSuffix })

  const handleRefine = (value: string) => {
    trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_SORT, { sortValue: value })
    refine(value)
  }

  // Cast to type with translateValue[] used for translations
  const typedOptions = options as SortByItemWithTranslateValue[]

  if (!typedOptions || typedOptions.length === 0) {
    return null
  }

  return (
    <div className={cn(className, 'flex flex-col gap-6')}>
      <span className='text-body-lg-bold'>{title}</span>
      <RadioGroup onValueChange={handleRefine} className='flex flex-col gap-[22px]'>
        {typedOptions.map(({ label, value, translateValue }, index) => (
          <div key={`search-sorting-${value}-${index}`} className='flex items-center space-x-2'>
            <RadioGroupItem value={value} id={`sort-value-${value}`} checked={currentRefinement === value} />
            <Label className='cursor-pointer' htmlFor={`sort-value-${value}`}>
              {translate(translateValue, label)}
            </Label>
          </div>
        ))}
      </RadioGroup>
    </div>
  )
}

export default SearchSorting
