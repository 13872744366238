import { FunctionComponent } from 'react'

import { CareInstructionSet } from 'data-access/sanity/fragments/components/careInstructions.fragment'
import { CareInstructionSetWithDescription } from 'data-access/sanity/fragments/sections/sectionCare.fragment'
import { cn } from '@ui/lib/utils'

import MisterImage from '../image/MisterImage'

interface MisterCareIconListProps {
  data: CareInstructionSet | CareInstructionSetWithDescription
  className?: string
  size?: 'small' | 'medium'
}

const MisterCareIconList: FunctionComponent<MisterCareIconListProps> = ({ data, className, size = 'medium' }) => {
  return (
    <div className={cn('flex flex-col gap-4', className)}>
      {[data?.washing?.careInstructions, data?.drying?.careInstructions, data?.ironing?.careInstructions, data?.storage?.careInstructions, data?.wearAndTear?.careInstructions].map(
        (careInstructions) =>
          careInstructions?.map((careInstruction, i) => (
            <div key={i} className={cn('flex items-center', size == 'medium' ? 'gap-5 text-body-lg' : 'gap-3 text-body-md')}>
              <MisterImage className={cn(size == 'medium' ? 'size-8' : 'size-6')} mobile={careInstruction.icon} />
              <span>{careInstruction.instruction}</span>
            </div>
          )),
      )}
    </div>
  )
}

export default MisterCareIconList
