import { FunctionComponent } from 'react'
import Link from 'next/link'

import { useTelemetry } from 'src/contexts/Telemetry.context'
import useCart from 'src/hooks/shop/useCart'
import { ecommerceInfoSelectItem } from 'src/utils/elevar.util'
import { productTitle } from 'src/utils/product.util'
import { ELEVAR_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import { useProductCard } from '../ProductCard.context'

const ProductCardLink: FunctionComponent<{ onClick?: () => void }> = ({ onClick }) => {
  const { index, product } = useProductCard('Product card link')
  const { currencyCode } = useCart()
  const { userProperties } = useTelemetry()

  const { title, category: subtitle } = productTitle(product.productTitle, true)
  const linkTitle = subtitle ? `${title} - ${subtitle}` : title

  const onClickProduct = () => {
    onClick?.()

    trackEvent(ELEVAR_DATA_LAYER_EVENTS.DL_SELECT_ITEM, {
      user_properties: userProperties,
      ecommerce: ecommerceInfoSelectItem(product, index, currencyCode),
    })
  }

  return <Link href={`/products/${product.slug.current}`} onClick={onClickProduct} title={linkTitle} itemProp='url' className='absolute inset-0 z-productCardLink cursor-pointer' />
}

export default ProductCardLink
