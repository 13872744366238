import { FunctionComponent } from 'react'

import { cn } from '@ui/lib/utils'

import MisterProductCardImage from 'src/components/shared/productCard/MisterProductCardImage'
import { productTitle } from 'src/utils/product.util'
import { useProductCard } from '../ProductCard.context'
import useProductImage from './useProductImage'

const SingleProductCardImage: FunctionComponent<{ imageSizes?: string; className?: string }> = ({ imageSizes = '100vw', className }) => {
  const { index, product } = useProductCard('Single product card image')
  const [productCardImage] = useProductImage(product)

  const { title, category: subtitle } = productTitle(product.productTitle, true)
  const linkTitle = subtitle ? `${title} - ${subtitle}` : title

  if (!productCardImage) {
    return null
  }

  return (
    <div className={cn('grid grid-cols-1 grid-rows-1', !product.isAvailable && 'opacity-50')}>
      <div className={cn('col-span-1 col-start-1 row-span-1 row-start-1', className)}>
        <MisterProductCardImage image={productCardImage} priority={index === 0} alt={linkTitle} sizes={imageSizes} />
      </div>
    </div>
  )
}

export default SingleProductCardImage
