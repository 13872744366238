import { FunctionComponent } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import { SectionReturnFormData } from 'data-access/sanity/fragments/sections/sectionReturnForm.fragment'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, useForm } from 'ui/components/ui/form'
import { Button } from '@ui/components/ui/button'
import { Input } from '@ui/components/ui/input'

import { rmaPortalUrl } from 'src/utils/rma.util'
import MisterContainer from '../../shared/MisterContainer'
import RichText from '../../shared/rich-text/rich-text'

interface Props {
  data: SectionReturnFormData
}

const SectionReturnForm: FunctionComponent<Props> = ({ data }) => {
  const { title, description, orderNumberLabel, orderNumberPlaceholder, orderNumberHint, postcodeOrEmailLabel, postcodeOrEmailPlaceholder, postcodeOrEmailHint, buttonText } =
    data || {}

  const returnFormSchema = z.object({
    postcodeOrEmail: z.string().min(1, { message: postcodeOrEmailHint }),
    orderNumber: z.string().min(1, { message: orderNumberHint }),
  })

  const form = useForm<z.output<typeof returnFormSchema>>({
    resolver: zodResolver(returnFormSchema),
    defaultValues: {
      postcodeOrEmail: '',
      orderNumber: '',
    },
  })

  const handleSubmit = async (values: z.infer<typeof returnFormSchema>) => {
    const { postcodeOrEmail, orderNumber } = values
    const url = rmaPortalUrl('#' + orderNumber.replace('#', ''), postcodeOrEmail)

    return window.open(url)
  }

  return (
    <MisterContainer dataLabel='section-return-form' className='bg-brand-beige-light' type='fluid' data-id={data._id}>
      <MisterContainer type='small' padding={false} as='div' className='mx-auto flex flex-col items-center gap-8 py-16'>
        {title && <h2 className='text-heading-5'>{title}</h2>}
        {description && <RichText data={description} className='text-center' />}
        <Form {...form}>
          <form className='flex w-full max-w-sm flex-col gap-4' onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name='orderNumber'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{orderNumberLabel}</FormLabel>
                  <FormControl>
                    <Input type='text' placeholder={orderNumberPlaceholder} aria-label={orderNumberLabel} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='postcodeOrEmail'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{postcodeOrEmailLabel}</FormLabel>
                  <FormControl>
                    <Input type='text' autoComplete='email postal-code' placeholder={postcodeOrEmailPlaceholder} aria-label={postcodeOrEmailLabel} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type='submit' className='w-full'>
              {buttonText}
            </Button>
          </form>
        </Form>
      </MisterContainer>
    </MisterContainer>
  )
}

export default SectionReturnForm
