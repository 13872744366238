import { FC, useCallback, useEffect } from 'react'
import { useClearRefinements, useInstantSearch } from 'react-instantsearch'

import { SectionSearchFiltersGridData } from 'data-access/sanity/fragments/sections/sectionSearchFiltersGrid.fragment'
import { Button } from '@ui/components/ui/button'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from '@ui/components/ui/sheet'

import MisterCollectionPageTelemetry from 'src/components/scripts/MisterCollectionPageTelemetry'
import { mapAlgoliaHitToProductCard } from 'src/domain/algoliaProductCard.domain'
import useCart from 'src/hooks/shop/useCart'
import { useTranslations } from '../../../contexts/Globals.context'
import { useUI } from '../../../contexts/UI.context'
import SearchFiltersSkeleton from '../../shared/loader/SearchFiltersSkeleton'
import SearchResultsSkeleton from '../../shared/loader/SearchResultsSkeleton'
import SearchFilters from '../../shared/search/filters/SearchFilters'
import SearchResults from '../../shared/search/SearchResults'

type Props = {
  data: SectionSearchFiltersGridData
  locale: string
  currentIndexName: string
}

/**
 * This short artificial delay time is to ensure the Skeletons show before the PCP results are displayed.
 */
const DELAY_TIME = 300

const SearchResultsGrid: FC<Props> = ({ locale, data: { filterConfiguration, gridCallouts, title, collectionSeason, productType, additionalProducts }, currentIndexName }) => {
  const { status, results } = useInstantSearch()
  const translate = useTranslations()
  const { isLoading: cartIsLoading, currencyCode } = useCart()
  const { refine: clearRefinements, canRefine: canClearRefinements } = useClearRefinements()
  const { displayFilters, toggleFiltersActive, toggleMobileFilters, displayMobileFilters, pcpResultsLoaded, setPcpResultsLoaded } = useUI()
  const mappedResults = useCallback(() => results?.hits.map((hit) => mapAlgoliaHitToProductCard(hit)) || [], [results.queryID])

  // Determine if the skeleton should be displayed:
  // - If the search status is 'loading' or 'stalled'
  // - If there are no results available yet
  // - If the search is in the initial 'idle' state
  const isSearchActive = pcpResultsLoaded && (status === 'loading' || status === 'stalled' || !results)

  useEffect(() => {
    if (!pcpResultsLoaded) {
      const timer = setTimeout(() => {
        setPcpResultsLoaded(true)
      }, DELAY_TIME)

      return () => clearTimeout(timer)
    }
  }, [pcpResultsLoaded, setPcpResultsLoaded])

  useEffect(() => {
    // Sync the Algolia filters active state with local state
    toggleFiltersActive(canClearRefinements)

    return () => {
      toggleFiltersActive(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canClearRefinements])

  return (
    <>
      <div
        data-display-filters={displayFilters}
        data-search-active={isSearchActive}
        data-insights-index={currentIndexName}
        aria-live='polite'
        aria-atomic='true'
        aria-busy={pcpResultsLoaded ? 'false' : 'true'}
        className='group/grid grid w-full lg:transition-all data-[display-filters=false]:lg:grid-cols-0-100 data-[display-filters=true]:lg:grid-cols-25-75'
      >
        <div>
          {!pcpResultsLoaded ? (
            <SearchFiltersSkeleton className='max-lg:hidden' />
          ) : (
            <SearchFilters
              locale={locale}
              className='col-span-full pr-2.5 transition-all max-lg:hidden lg:col-span-3 lg:pr-8 lg:duration-250 lg:group-data-[display-filters=false]/grid:h-0 lg:group-data-[display-filters=false]/grid:-translate-x-[100vw] lg:group-data-[display-filters=true]/grid:translate-x-0'
              config={filterConfiguration}
            />
          )}
        </div>
        <div className='@container/product-grid [container-type:inline-size]'>
          {!pcpResultsLoaded ? (
            <SearchResultsSkeleton />
          ) : (
            <SearchResults
              className='grid list-none grid-cols-2 grid-rows-[auto] gap-2.5 gap-y-5 @3xl/product-grid:grid-cols-3 @7xl/product-grid:grid-cols-4 lg:gap-x-3 lg:gap-y-6'
              gridCallouts={gridCallouts}
              additionalProducts={additionalProducts}
            />
          )}
        </div>
      </div>
      {/* Mobile Filters */}
      <Sheet open={displayMobileFilters} onOpenChange={toggleMobileFilters}>
        <SheetContent fade={true} side='bottom' className='h-[100dvh] pb-0 data-[state=closed]:duration-200 data-[state=open]:duration-300'>
          <div className='relative flex h-full flex-col overflow-scroll scrollbar-hide'>
            <SheetHeader>
              <SheetTitle className='pb-7 text-left'>{translate('filterAndSort', 'Filter & sort')}</SheetTitle>
              <SheetDescription className='sr-only'>{translate('filterAndSort', 'Filter & sort')}</SheetDescription>
            </SheetHeader>
            <SearchFilters locale={locale} config={filterConfiguration} />
            <div className='sticky bottom-0 grid w-full grid-cols-2 gap-2 border-t border-t-border bg-white px-5 pb-8 pt-4 lg:hidden'>
              <Button disabled={!canClearRefinements} className='w-full' type='button' onClick={clearRefinements} variant='outline' title={translate('clearAll', 'Clear all')}>
                {translate('clearAll', 'Clear all')}
              </Button>
              <Button className='w-full' type='button' onClick={toggleMobileFilters} title={translate('applyFilters', 'Apply filters')} variant='primary'>
                {translate('applyFilters', 'Apply filters')}
              </Button>
            </div>
          </div>
        </SheetContent>
      </Sheet>
      {!cartIsLoading && mappedResults()?.length ? (
        <MisterCollectionPageTelemetry currencyCode={currencyCode} products={mappedResults()} category={title} type={productType} season={collectionSeason} />
      ) : null}
    </>
  )
}

export default SearchResultsGrid
