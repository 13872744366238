import { FunctionComponent } from 'react'

import { Cta } from 'data-access/sanity/fragments/components/cta.fragment'
import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/lib/utils'

import MisterSiteLink from './site-link/MisterSiteLink'

interface MisterCtaButtonProps {
  cta: Cta
  className?: string
}

const MisterCtaButton: FunctionComponent<MisterCtaButtonProps> = ({ cta, className }) => {
  // TODO: Delete this component once the cta legacy icon, size, and variant values are removed from Sanity Content
  const { buttonText, variant, link, size = 'medium' } = cta || {}

  const proxiedVariant = variant === 'primary' ? 'primary' : variant === 'secondary' ? 'secondary' : variant === 'secondaryTransparent' ? 'outline' : 'primary'
  const proxiedSize = size === 'small' ? 'sm' : size === 'medium' ? 'md' : size === 'large' ? 'default' : size

  return (
    <Button asChild variant={proxiedVariant} size={proxiedSize || 'md'} className={cn('w-min', className)}>
      <MisterSiteLink link={link}>{buttonText}</MisterSiteLink>
    </Button>
  )
}

export default MisterCtaButton
